
import config from './../../../../../config';

const state = {
  openErrorAlert: false,
  errorText: '',
  openWarningAlert: false,
  warningText: '',
  progress: 0,
  multiProgress: 0,
  ...config
}
const mutations = {
  ['OPEN_ERROR_ALERT'](state, text) {
    state.errorText = text;
    state.openErrorAlert = true;
  },
  ['CLOSE_ERROR_ALERT'](state) {
    state.openErrorAlert = false;
    state.errorText = '';
  },
  ['OPEN_WARNING_ALERT'](state, text) {
    state.warningText = text;
    state.openWarningAlert = true;
  },
  ['CLOSE_WARNING_ALERT'](state) {
    state.openWarningAlert = false;
    state.warningText = '';
  },
  ['SET_PROGRESS'](state, value) {
    state.progress = value;
  },
  ['SET_MULTI_PROGRESS'](state, value) {
    state.multiProgress = value;
  },
}
import * as getters from "./getters";
import * as actions from "./actions";

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

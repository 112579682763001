const assets = process.env.NODE_ENV === 'production' ? '../../../dist/assets/' : '../../../assets/';

export default [{
    title: '避難場所',
    description: '周辺の道路に逃げ道を引くことができるアイコンです。',
    contents: [
      { class: 'font-weight-bold pink--text', text: '周辺の道路に逃げ道を引くことができるアイコンです。' },
      { class: null, text: '【情報】画面から既存の「避難場所」（津波避難ビルを含む、津波防災マップ上の指定緊急避難場所）を表示できますが、本機能では「避難場所」を自前で追加することができます。' },
      { class: null, text: '【ツール】画面から「逃げ道の一括描画」を行うと、複数の「避難場所」「避難目標地点」から一括で逃げ道を引くことができます。' },
      { class: null, text: '<b>出典</b> <a href="http://www.loto-lab.com/ngczweb/" target="new">逃げ地図ウェブ</a>' },
    ],
    menu: {
      color: 'green',
      extraIcon: 'shelter',
    },
    marker: {
      iconUrl: assets + 'img/shelter-emergency-marker.svg',
      iconSize: [35, 45],
      iconAnchor: [17, 42],
      popupAnchor: [1, -32],
      shadowAnchor: [10, 12],
      shadowSize: [36, 16],
      extraClasses: 'ic ic-shelter-emergency',
      icon: 'fa',
      markerColor: 'green',
      shape: 'penta',
      prefix: 'fa',
    },
  },
  {
    disabled: false,
    title: '避難目標地点',
    description: '周辺の道路に逃げ道を引くことができるアイコンです。',
    contents: [
      { class: 'font-weight-bold pink--text', text: '周辺の道路に逃げ道を引くことができるアイコンです。' },
      { class: null, text: '津波から逃れられる「避難目標地点」は、指定緊急避難場所以外にもあります。例えば、津波浸水想定区域と道路の交点や津波から逃れられる高いビルです。' },
      { class: null, text: '【ツール】画面から「避難目標地点の一括設置」を行うと、津波浸水区域と道路の交点を一括して設置できますが、本機能では《それ以外の津波浸水想定区域と階段など通り道との交点》や《指定緊急避難場所に指定されていない津波避難ビル》を自前で追加することができます。' },
      { class: null, text: '<b>出典</b> <a href="http://www.loto-lab.com/ngczweb/" target="new">逃げ地図ウェブ</a>' },
    ],
    menu: {
      color: 'green darken-2',
      icon: 'flag',
    },
    marker: {
      iconUrl: assets + 'img/flag-emergency-marker.svg',
      iconSize: [35, 45],
      iconAnchor: [17, 42],
      popupAnchor: [1, -32],
      shadowAnchor: [10, 12],
      shadowSize: [36, 16],
      icon: 'fa-flag',
      markerColor: 'green-dark',
      shape: 'square',
      prefix: 'fa',
    }
  },
  {
    title: '避難障害地点(印)',
    description: '避難の障害になる場所に黒いX印を付けると、逃げ道の描画を止められます。',
    contents: [
      { class: 'font-weight-bold pink--text', text: '避難の障害になる場所に黒いX印を付けると、逃げ道の描画を止められます。' },
      { class: null, text: '「逃げ道（避難経路）」の中に、地震で老朽ブロック塀が倒壊する道路や津波が俎上する川の橋など、避難の障害になる場所がある場合、その地点に黒いX印を付けることで「逃げ道」の描画を止められます。' },
      { class: null, text: '<b>出典</b> <a href="http://www.loto-lab.com/ngczweb/" target="new">逃げ地図ウェブ</a>' },
    ],
    menu: {
      color: 'blue-grey darken-3',
      icon: 'close',
    },
    marker: {
      iconUrl: assets + 'img/close-marker.svg',
      iconSize: [35, 45],
      iconAnchor: [17, 42],
      popupAnchor: [1, -32],
      shadowAnchor: [10, 12],
      shadowSize: [36, 16],
      icon: 'fa-close',
      markerColor: 'black',
      shape: 'star',
      prefix: 'fa',
    }
  },
  {
    title: '避難目標地点(印)',
    description: '避難目標地点の高台側に赤いO印を付けると、逃げ道の描画を止められます。',
    contents: [
      { class: 'font-weight-bold pink--text', text: '避難目標地点の高台側に赤いO印を付けると、逃げ道の描画を止められます。' },
      { class: null, text: '本システムでは「避難目標地点」の高台側の道路にも「逃げ道」が描画されますので、その高台側の地点に赤いO印を付けることで「逃げ道」の描画を止められます。' },
      { class: null, text: '<b>出典</b> <a href="http://www.loto-lab.com/ngczweb/" target="new">逃げ地図ウェブ</a>' },
    ],
    menu: {
      color: 'red darken-4',
      icon: 'radio_button_unchecked',
    },
    marker: {
      iconUrl: assets + 'img/open-marker.svg',
      iconSize: [35, 45],
      iconAnchor: [17, 42],
      popupAnchor: [1, -32],
      shadowAnchor: [10, 12],
      shadowSize: [36, 16],
      icon: 'fa-circle-o',
      markerColor: 'red',
      shape: 'star',
      prefix: 'fa',
    }
  },
  /*
  {
    title: '注意ポイント',
    description: 'コメントの追加',
    menu: {
      color: 'deep-purple darken-3',
      icon: 'exclamation-triangle',
    },
    marker: {
      icon: 'fa-exclamation-triangle',
      markerColor: 'purple',
      shape: 'star',
      prefix: 'fa',
    }
  },
  {
    title: 'ブックマーク',
    description: 'コメントの追加',
    menu: {
      color: 'grey',
      icon: 'bookmark',
    },
    marker: {
      icon: 'fa-bookmark',
      iconColor: 'black',
      markerColor: 'white',
      shape: 'square',
      prefix: 'fa',
    }
  },
  {
    title: 'スター',
    description: 'コメントの追加',
    menu: {
      color: 'grey',
      icon: 'star',
    },
    marker: {
      icon: 'fa-star',
      iconColor: 'black',
      markerColor: 'white',
      shape: 'square',
      prefix: 'fa',
    }
  },
  {
    title: 'ハート',
    description: 'コメントの追加',
    menu: {
      color: 'grey',
      icon: 'heart',
    },
    marker: {
      icon: 'fa-heart',
      iconColor: 'black',
      markerColor: 'white',
      shape: 'square',
      prefix: 'fa',
    }
  },
  {
    title: 'コメント',
    description: 'コメントの追加',
    menu: {
      color: 'grey',
      icon: 'commenting-o',
    },
    marker: {
      icon: 'fa-commenting-o',
      iconColor: 'black',
      markerColor: 'white',
      shape: 'square',
      prefix: 'fa',
    }
  },
  {
    title: 'クエスチョン',
    description: 'コメントの追加',
    menu: {
      color: 'grey',
      icon: 'question',
    },
    marker: {
      icon: 'fa-question',
      iconColor: 'black',
      markerColor: 'white',
      shape: 'square',
      prefix: 'fa',
    }
  },*/
];

import {
  point, lineString, featureCollection,
  getCoord, getCoords, featureEach, truncate,
  pointToLineDistance,
  nearestPointOnLine,
} from '@turf/turf';

// 汎用・最も近いライン上の点を取得する
export default function (lines, coord, RANGE=129) {
  let result = null;
  if (!coord) return null;
  let directDistance = RANGE;
  featureEach(featureCollection(lines), (feature, index) => {
    const line = lineString(getCoords(feature));
    const coordPoint = point(coord);
    const newDistance = pointToLineDistance(coordPoint, line, {units: 'meters'});
    if (newDistance < RANGE) {
      const nearPoint = nearestPointOnLine(line, coordPoint, { units: 'meters' });
      const newDirectDistance = nearPoint.properties.dist;
      if (newDirectDistance < directDistance) {
        directDistance = newDirectDistance;
        result = {
          coord: getCoord(truncate(nearPoint)),
          index: index,
          line: line,
        };
      }
    }
  });
  return result;
}

<template lang="pug">
v-app.page
  div
    router-view
  v-speed-dial(
    v-model="fab",
    v-show="!masterMode",
    bottom,
    left,
    :direction="fabDirection"
  )
    v-btn(
      v-for="(m, index) in modeList",
      :key="index",
      fab,
      hover,
      v-model="fab",
      @click.native="setMode(index)",
      :class="m.color",
      :slot="activeFab(index)"
    )
      v-icon.white--text {{ m.icon }}
</template>


<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapGetters("map", ["mode", "modeList"]),
    fabDirection() {
      return this.windowHeight > 300 ? "top" : "right";
    },
  },
  methods: {
    ...mapActions("map", ["setMode"]),
    activeFab(_mode) {
      return this.mode === _mode ? "activator" : "default";
    },
    onResize(ev) {
      if (ev) this.windowHeight = ev.currentTarget.innerHeight;
      this.width = document.documentElement.clientWidth;
      this.masterMode = 850 < this.width;
    },
  },
  data() {
    return {
      fab: false,
      windowHeight: window.innerHeight,
      masterMode: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

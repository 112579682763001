<template lang="pug">
div
  v-alert.alert(
    error,
    dismissible,
    v-show="openErrorAlert",
    icon="warning",
    :value="openErrorAlert",
    transition="slide-y-transition",
    @click.native="clearError"
  ) {{ errorText }}
  v-alert.alert.black--text(
    color="warning",
    dismissible,
    icon="priority_high",
    v-show="openWarningAlert",
    :value="openWarningAlert",
    transition="slide-y-transition",
    @click.native="clearWarning"
  ) {{ warningText }}
  v-progress-circular.mapProgress(
    :value="progress",
    :rotate="-90",
    :size="100",
    :width="10",
    color="primary",
    v-show="0<progress",
    @click="setProgress(0)"
  ) {{ parseInt(progress) }}
  v-progress-circular.mapProgress(
    :value="multiProgress",
    :rotate="-90",
    :size="100",
    :width="10",
    color="primary",
    v-show="0<multiProgress",
    @click="setMultiProgress(0)"
  ) {{ parseInt(multiProgress) }}
  //- v-progress-linear.mapProgress(v-model="progress" height="15")
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  components: {},
  computed: {
    ...mapGetters("layout", [
      "openErrorAlert",
      "errorText",
      "openWarningAlert",
      "warningText",
      "progress",
      "multiProgress",
    ]),
  },
  watch: {
    openWarningAlert(open) {
      if (open) {
        const _this = this;
        setTimeout(() => {
          _this.clearWarning();
        }, 1000);
      }
    },
  },
  methods: {
    ...mapActions("layout", [
      "clearError",
      "clearWarning",
      "setProgress",
      "setMultiProgress",
    ]),
  },
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style scoped>
</style>

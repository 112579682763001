import nearestLinePoint from './nearestLinePoint';
import {
  feature, point, lineString, multiLineString, featureCollection,
  getCoord, getCoords, featureEach, coordEach, propEach, booleanEqual,
  // ラインの距離を求める
  length as lineDistance,
  // ラインを図形で分割する
  lineSplit,
  // ラインを距離で切り取る
  lineSliceAlong,
  // 点群から最も近い点を求める
  nearestPoint,
  // 線上で最も近い点を求める
  nearestPointOnLine,
} from '@turf/turf';
// var turfFilter = require('@turf/filter');
// const turfFilter = require('turf-filter');
const METERS = { units: 'meters' };

export default class RouteMap {
  constructor(baseCoords, exCoords, blackCoords, startCoord) {
    // パラメータの設定
    this.nodeid = 0;
    this.nodes = {type: "FeatureCollection", features: []};
    this.tempMap = {};
    this.dijkstramap = {};
    this.dijkstrafeature = {};
    this.blackCoords = blackCoords;
    this.initialize(baseCoords, exCoords, blackCoords, startCoord);
  }
  initialize(baseCoords, exCoords, blackCoords, startCoord) {
    this.lines = baseCoords;
    if (exCoords instanceof Array) {
      const exLines = exCoords.map(this.setExFeature, this);
      this.lines = this.lines.concat(...exLines);
    }
    if (blackCoords instanceof Array) {
      blackCoords.map(bp => {
        if (bp) {
          const nearestInfo = nearestLinePoint(this.lines, bp);
          if (nearestInfo) {
            this.lines.splice(nearestInfo.index, 1);
            const split = lineSplit(nearestInfo.line, point(bp));
            featureEach(split, feature => this.lines.push(getCoords(feature)));
          }
        }
      });
    }
    let sw = false;
    if (startCoord instanceof Array) {
      this.startCoord = startCoord;
      let exCoords = [];
      const nearest = nearestLinePoint(this.lines, startCoord);
      if (nearest) {
        const startCoords = [startCoord, nearest.coord];
        this.startLine = lineString(startCoords);
        this.lines.splice(nearest.index, 1);
        const split = lineSplit(nearest.line, point(nearest.coord));
        featureEach(split, feature => exCoords.push(getCoords(feature)));
        this.lines.push(startCoords);
        this.lines.push(...exCoords);
      } else {
        sw = true;
        // this.startLine = lineString([startCoord]);
        // return 0;
      }
    }
    if (!sw && baseCoords instanceof Array) {
      this.lines.map(coords => this.setBaseFeature(coords), this);
    }
  }
  setBaseFeature(coords, options = {}) {
    const line = lineString(coords);
    const length = options.length || lineDistance(line, METERS);
    const coordsSize = coords.length - 1;
    const sid = options.sid || this.setNode(coords[0][0], coords[0][1], null);
    const eid = options.eid || this.setNode(coords[coordsSize][0], coords[coordsSize][1], {sid: sid, cost: length});
    line.properties.sid = sid;
    line.properties.eid = eid;
    this.setDijkstraMap(sid, eid, length, line);
    this.setDijkstraMap(eid, sid, length, line);
    return { sid: sid, eid: eid, cost: length };
  }
  // 拡張道路をベース道路に足す．ベース道路の分割を含む
  setExFeature(coords) {
    const _this = this;
    let exCoords = [];
    [coords[0], coords[coords.length - 1]].map(cc => {
      let dist = 0.15;
      let target = {index: -1, coord: null };
      _this.lines.map((coord, index) => {
        const nPoint = nearestPointOnLine(lineString(coord), cc, METERS);
        const distance = nPoint.properties.dist;
        if (distance < dist) {
          dist = distance;
          target.coord = coord;
          target.index = index;
        }
      });
      if (target.coord) {
        exCoords.push(coords);
        if (dist !== 0) {
          _this.lines.splice(target.index, 1);
          const split = lineSplit(lineString(target.coord), point(cc));
          featureEach(split, feature => _this.lines.push(getCoords(feature)));
        }
      }
    });
    return exCoords;
  }
  setNode(lng, lat, forceOption) {
    const key = lat + "-" + lng;
    const node = this.nodes.features.find(feature => feature.properties.latlng === key);
    let judgeBlackNode = false;
    this.blackCoords.map(bp => {
      if (booleanEqual(point(bp), point([lng, lat]))) {
        judgeBlackNode = true;
      }
    });
    let id = (node && !judgeBlackNode) ? node.properties.id : this.nodeid++;
    if (!node) this.addNode(lng, lat, id);
    if (forceOption) {
      const cid = id;
      const keys = [forceOption.sid+"-"+cid, cid+"-"+forceOption.sid];
      let tempKey = "";
      if (keys.some(key => {tempKey=key; return 0 < this.tempMap[key];})) {
        if (this.tempMap[tempKey] < forceOption.cost) {
          let preId = this.nodeid;
          this.nodes.features = this.nodes.features.map(feature => {
            if (feature && feature.properties.id === cid) {
              feature.properties.id = preId;
            }
            return feature;
          });
        }
        id = this.nodeid++;
        this.addNode(lng, lat, id);
      } else {
        this.tempMap[keys[0]] = forceOption.cost;
        this.tempMap[keys[1]] = forceOption.cost;
      }
    }
    return id;
  }
  addNode(lng, lat, _id) {
    const key = lat + "-" + lng;
    this.nodes.features.push(point([lng, lat], {
      id: _id,
      latlng: key
    }));
  }
  setDijkstraMap(id1, id2, length, feature) {
    if (!(id1 in this.dijkstramap)) {
      this.dijkstramap[id1] = {};
      this.dijkstrafeature[id1] = {};
    }
    this.dijkstramap[id1][id2] = length;
    this.dijkstrafeature[id1][id2] = feature;
  }
  getStartLine() {
    const sCoord = this.startCoord;
    const key = sCoord[1] + "-" + sCoord[0];
    const node = this.nodes.features.find(feature => feature.properties.latlng === key);
    return node;
    // const nearest = nearestLinePoint(this.lines, sCoord);
    // const eCoord = getCoord(nearest);
    // const newInfo = this.setBaseFeature([sCoord, eCoord], {
    //   length: nearest.properties.dist * 1000,
    // });
    // return this.nodes.features[newInfo.sid];
  }
  getMap() {
    // console.log(this.dijkstramap);
    return this.dijkstramap;
  }
  getFeatures(pathsInfo) {
    // console.log(pathsInfo);
    var {nodes, diff, costs, collision, startNode} = pathsInfo;
    this.diff = diff;
    this.costs = costs;
    var {dijkstrafeature} = this;
    // 探索結果から経路作成
    var response = [];
    for (var colorIndex = 0; colorIndex < nodes.length-1; colorIndex++) {
      var featuresData = [];
      var featuresIndex = 0;
      var judgeData = [];
      var targetNodes = nodes[colorIndex];
      var collisionNodes = collision ? collision[colorIndex] : null;
      for (var sIndex in dijkstrafeature) { if (dijkstrafeature.hasOwnProperty(sIndex)) {
        var djFeatures = dijkstrafeature[sIndex];
        for (var eIndex in djFeatures) { if (djFeatures.hasOwnProperty(eIndex)) {
          // console.log(judgeData)
          if (!judgeData[sIndex+'-'+eIndex] && !judgeData[eIndex+'-'+sIndex]) {
            var djFeature = djFeatures[eIndex];
            var coordinates = [];
            var submit = function (resultLine) {
              if (resultLine) {
                coordinates.push(resultLine);
                judgeData[sIndex + '-' + eIndex] = true;
                judgeData[eIndex + '-' + sIndex] = true;
                featuresData[featuresIndex++] = multiLineString(coordinates);
              }
            };
            if (sIndex in targetNodes && eIndex == targetNodes[sIndex] ) {
              // console.log(colorIndex, "S", sIndex, eIndex, targetNodes[sIndex], targetNodes[eIndex]);
                let coords = getCoords(djFeature);
                submit(this.sliceAlong(coords, sIndex, eIndex, colorIndex, this.diff ? this.diff[colorIndex][parseInt(sIndex)]: null, true));

            } else if (eIndex in targetNodes && sIndex == targetNodes[eIndex]) {
              // console.log(colorIndex, "E", sIndex, eIndex, targetNodes[sIndex], targetNodes[eIndex]);
                let coords = getCoords(dijkstrafeature[eIndex][sIndex]);
                submit(this.sliceAlong(coords, sIndex, eIndex, colorIndex, this.diff ? this.diff[colorIndex][parseInt(eIndex)] : null, true));
            } else if (collisionNodes) {
              if (sIndex in collisionNodes && eIndex in collisionNodes[sIndex]) {
                // console.log("S", sIndex, eIndex)
                let coords = getCoords(djFeature);
                submit(this.sliceAlong(coords, sIndex, eIndex, colorIndex, collisionNodes[sIndex][eIndex]));
              }
              if (eIndex in collisionNodes && sIndex in collisionNodes[eIndex]) {
                // console.log("E", sIndex, eIndex)
                let coords = getCoords(djFeature);
                submit(this.sliceAlong(coords, eIndex, sIndex, colorIndex, collisionNodes[eIndex][sIndex]));
              }
            }
          }
        }}
      }}
      response.push(featureCollection(featuresData));
      judgeData = null;
    }
    return response;
  }
  sliceAlong(coords, sIndex, eIndex, colorIndex, diff, isFixCoords=false) {
    if (diff) {
      let cods = coords;
      const feature = this.nodes.features[sIndex];
      if (!feature) return coords;
      // coordsの向きを揃える（避難所に近い順）
      const sCoord = getCoords(feature);
      // try {
      //   sCoord = getCoords(this.nodes.features[sIndex]);
      // } catch(e) {
      //   console.log(e, coords, sIndex, this.nodes.features[sIndex]);
      // }
      const fCoord = cods[0];
      const judge = sCoord[0] !== fCoord[0] || sCoord[1] !== fCoord[1];
      if (isFixCoords) {
        if (this.costs[sIndex] < this.costs[eIndex]) {
          if (judge) cods = this.reverseArray(cods);
        } else {
          if (!judge) cods = this.reverseArray(cods);
        }
      } else {
        if (judge) cods = this.reverseArray(cods);
      }
      // 判定
      const geojson = lineString(cods);
      if (!geojson) return cods;
      try {
        // diffを使ってラインを修正する
        if (diff.max + diff.min <= 0) return cods;
        const sliceLine = lineSliceAlong(geojson, diff.min, diff.max, METERS);
        return sliceLine ? sliceLine.geometry.coordinates : cods;
      } catch (e) {
        let length = lineDistance(geojson, METERS);
        console.log(e, geojson, diff, length, isFixCoords);
        return cods;
      }
    }
    return coords;
  }
  reverseArray(coords) {
    let res = [];
    coords.map(c => res.unshift(c));
    return res;
  }
}


// 汎用・GeoJSONクラス
class GeoJSON {
  constructor(type, coords) {
    this.properties = {};
    this.type = type;
    this.coordinates = coords;
  }
}

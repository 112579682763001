export default [{
    title: '緑',
    code: '#299768',
  },
  {
    title: '黄緑',
    code: '#73B866',
  },
  {
    title: '黄',
    code: '#E8CA3C',
  },
  {
    title: '橙',
    code: '#EA8F2F',
  },
  {
    title: '赤',
    code: '#DD3E27',
  },
  {
    title: '紫',
    code: '#692B82',
  },
  {
    title: '茶',
    code: '#87593A',
  },
  {
    title: '黒',
    code: '#211612',
  },
  // {
  //   title: '青',
  //   code: '#1A94BF',
  // },
  // {
  //   title: '桃',
  //   code: '#E451D5',
  // },
];
export const colorList = state => state.colorList;
export const markerList = state => state.markerList;
export const tileLayerList = state => state.tileLayerList;
export const prefList = state => state.prefList;
export const printList = state => state.printList;
export const map = state => state.map;
export const scaleControl = state => state.scaleControl;
export const drawControl = state => state.drawControl;
export const mode = state => state.mode;
export const maxGuidePage = state => state.maxGuidePage;
export const guidePage = state => state.guidePage;
export const editMode = state => state.editMode;
export const markerMode = state => state.marker;
export const tile = state => state.tile;
export const delayMinutes = state => state.delayMinutes;
export const distance = state => state.distance;
export const minutes = state => state.minutes;
export const colors = state => state.colors;
export const colorNum = state => state.colorNum;
export const selectedMarker = state => state.selectedMarker;
export const selectedMarkerInfo = state => state.selectedMarkerInfo;
export const selectedMarkerType = state => state.selectedMarkerType;
export const drawLayer = state => state.drawLayer;
export const drawLayerNum = state => state.drawLayerNum;
export const blackData = state => state.blackData;
export const routeData = state => state.routeData;
export const modeList = state => state.modeList;
export const layersInfo = state => state.layersInfo;
export const paramsInfo = state => state.paramsInfo;
export const drawOptions = state => state.drawOptions;
export const baseLayer = state => state.baseLayer;
export const colorsLayer = state => state.colorsLayer;
export const gsiLayer = state => state.gsiLayer;
export const dSheltersLayer = state => state.dSheltersLayer;
export const colorCanvas = state => state.colorCanvas;
export const colorSvg = state => state.colorSvg;
export const legendPosition = state => state.legendPosition;
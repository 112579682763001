"use strict";

// import 'babel-polyfill';
// require("es6-promise/auto");

// import { configRouter } from './route_config';
import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuetify from 'vuetify';
import App from './App';
import store from './vuex/store';
import Main from './components/Main.vue';
import NotFound from './components/not-found';

import 'vuetify/dist/vuetify.min.css';

Vue.use(VueRouter);
Vue.use(Vuetify);

import 'leaflet/dist/leaflet.css';
import 'leaflet-contextmenu/dist/leaflet.contextmenu.css';
// import 'leaflet-easybutton/src/easy-button.css';
import './assets/css/main.scss';


const router = new VueRouter({
  history: true,
  linkActiveClass: 'active',
  saveScrollPosition: true,
  routes: [
    { path: '/', name: 'index', component: Main },
    { path: '*', component: NotFound },
  ]
});

new Vue({
  router,
  store,
  vuetify: new Vuetify(),
  render: h => h(App),
}).$mount('#app');
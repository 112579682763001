export default {
  printTypeList: [{
      name: "現在の地図"
    },
    {
      name: "白地図",
      type: "white"
    }
  ],
  zoomList: [{
      name: "現在のサイズ"
    },
    {
      name: "2000分の1",
      level: "17.847312"
    },
    {
      name: "2500分の1",
      level: "17.525384"
    },
  ],
  sizeList: [{
      name: "表示画面",
      type: "full"
    },
    {
      name: "A0縦",
      type: "a0-portrait"
    },
    {
      name: "A0横",
      type: "a0-landscape"
    },
    {
      name: "A1縦",
      type: "a1-portrait"
    },
    {
      name: "A1横",
      type: "a1-landscape"
    },
    {
      name: "A2縦",
      type: "a2-portrait"
    },
    {
      name: "A2横",
      type: "a2-landscape"
    },
    {
      name: "A3縦",
      type: "a3-portrait"
    },
    {
      name: "A3横",
      type: "a3-landscape"
    },
    {
      name: "A4縦",
      type: "a4-portrait"
    },
    {
      name: "A4横",
      type: "a4-landscape"
    },
  ],
  legendList: [
    { name: "非表示" },
    {
      name: "左上",
      position: "topleft"
    },
    {
      name: "右上",
      position: "topright"
    },
    {
      name: "左下",
      position: "bottomleft"
    },
    {
      name: "右下",
      position: "bottomright"
    },
  ]
};
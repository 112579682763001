<template lang="pug">
.draw-tools
  icon-dialog
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import iconDialog from "./iconDialog.vue";

export default {
  components: {
    "icon-dialog": iconDialog,
  },
  computed: {
    ...mapGetters("map", [
      "map",
      "markerMode",
      "markerList",
      "drawControl",
      "drawLayer",
      "drawOptions",
      "colorCanvas",
      "colorsLayer",
      "editMode",
    ]),
  },
  methods: {
    ...mapActions("map", [
      "setDrawControl",
      "setDrawLayer",
      "refreshDrawLayer",
      "setEditMode",
      "setMarkerMode",
      "addMarker",
      "clearCanvas",
      "addExRoute",
    ]),
    initDrawControl() {
      // const _this = this;
      const { map } = this;
      // Leaflet.Drawの設定
      const controlOptions = this.drawOptions.default;
      // const iconOptions = this.drawOptions.icon;
      // 編集可レイアウト
      this.setDrawLayer(new L.FeatureGroup().addTo(map));
      controlOptions.edit.featureGroup = this.drawLayer;
      this.drawLayer
        .on("layeradd layerremove", (e) => {
          this.refreshDrawLayer();
        })
        .on("layeradd", (e) => {
          // console.log('add', e);
        })
        .on("layerremove", (e) => {
          // console.log('remove', e.layer, _this.drawLayer);
        });
      map.on("zoomend", (ev) => {
        const hasLayer = map.hasLayer(this.drawLayer);
        const z = ev.target.getZoom();
        if (15 <= z) {
          if (!hasLayer) {
            map.addLayer(this.drawLayer);
          }
        } else if (hasLayer) {
          map.removeLayer(this.drawLayer);
        }
      });
      // アイコン
      // const defaultMarker = new L.ExtraMarkers.Icon(iconOptions);
      // controlOptions.draw.marker.icon = defaultMarker;
      const _drawControl = new L.Control.Draw(controlOptions);
      map.addControl(_drawControl);
      this.setDrawControl(_drawControl);

      map
        // 描画開始時の処理
        .on(L.Draw.Event.DRAWSTART, (e) => {
          const { layerType } = e;
          if (layerType === "marker") {
            // マーカの変更
            const iconOptions = this.markerList[this.markerMode].marker;
            iconOptions.markerId = this.markerMode;
            const drawOption = {
              marker: { icon: new L.icon(iconOptions) },
            };
            this.drawControl.setDrawingOptions(drawOption);
          } else if (layerType === "polyline") {
            if (this.map.getZoom() < 17) this.map.setZoom(17);
          }
        })
        // 描画キャンセル時の処理
        .on(L.Draw.Event.DRAWSTOP, (e) => {
          const { layerType } = e;
          if (layerType === "marker") {
            // マーカキャンセル
            this.setMarkerMode(-1);
          } else if (layerType === "polyline") {
            this.setEditMode(0);
          }
        })
        // 描画保存時の処理
        .on(L.Draw.Event.CREATED, (e) => {
          const { layer, layerType } = e;
          if (layerType === "marker") {
            this.drawLayer.addLayer(layer);
            this.addMarker({
              layerId: layer._leaflet_id,
              markerId: layer.options.icon.options.markerId,
            });
          } else if (layerType === "polyline") {
            layer.options.sid = 13;
            const coords = L.GeoJSON.latLngsToCoords(layer.getLatLngs());
            this.addExRoute(coords);
            this.setEditMode(0);
          }
        })
        // 編集保存時の処理
        .on(L.Draw.Event.EDITED, (e) => {
          const { layers } = e;
          const _this = this;
          layers.eachLayer((layer) => {
            if (layer instanceof L.Marker) {
              // キャンバスクリアしてからマーカの置き直し
              _this.clearCanvas(layer._leaflet_id);
              this.drawLayer.addLayer(layer);
              _this.addMarker({
                layerId: layer._leaflet_id,
                markerId: layer.options.icon.options.markerId,
              });
            } else if (layer instanceof L.Polyline) {
              if (_this.drawLayer.hasLayer(layer)) {
                _this.drawLayer.removeLayer(layer);
              }
              const coords = L.GeoJSON.latLngsToCoords(layer.getLatLngs());
              _this.addExRoute(coords);
              _this.setEditMode(0);
            }
          });
        })
        // 削除保存時の処理
        .on(L.Draw.Event.DELETED, (e) => {
          const { layers } = e;
          const _this = this;
          layers.eachLayer((layer) => {
            if (layer instanceof L.Marker) {
              _this.clearCanvas(layer._leaflet_id);
            } else if (layer instanceof L.Polyline) {
              if (_this.drawLayer.hasLayer(layer))
                _this.drawLayer.removeLayer(layer);
              // console.log(layer);
            }
          });
        });
    },
  },
  data() {
    return {};
  },
  mounted() {
    if (this.map) {
      this.initDrawControl();
    }
  },
};
</script>

<style scoped>
</style>

import styleRdcl from '../../../../../../config/style_rdcl.js';
const {minZoom, maxNativeZoom} = styleRdcl.info;

export default class gsiLoader {
  constructor(color, gsiLayer, callback, info = {}) {
    this.color = color;
    this.callback = callback;
    this.gsiLayer = gsiLayer;
    this.baseUrl = location.protocol +
      "//cyberjapandata.gsi.go.jp/xyz/{t}/{z}/{x}/{y}.geojson";
    this.ts = ["experimental_rdcl"];
    this.style = styleRdcl.style;
    this.styles = [];
    this.done = [];
    this.key = "";
    this.maxNativeZoom = maxNativeZoom;
    this.minZoom = minZoom;
  }
  get_tile(url, coords) {
    coords.key = this.key;
    var x = new XMLHttpRequest();
    x.open("GET", url, true);
    x.onreadystatechange = () => {
      // console.log(url + ' ' + x.readyState + ' ' + x.status);
      if (x.readyState != 4 || x.status != 200) {
        if (this.callback) {
          this.callback({ error: 1, position: coords });
        }
        return;
      }
      let geojsonLayer = L.geoJson(JSON.parse(x.responseText), {
        className: coords.t,
        key: coords.x + ":" + coords.y + ":" + coords.z,
        style: function (feature) {
          return styleRdcl.style(feature);
        },
        renderer: L.svg({ pane: "baseRoute" }),
      });
      // const geojson = geojsonLayer.toGeoJSON();
      let layerOptions = {
        className: coords.t,
        key: coords.x + ":" + coords.y + ":" + coords.z,
        style: feature => this.style(feature, this.color),
        styles: this.styles,
        renderer: L.svg({ pane: "baseRoute" }),
      };
      if (this.callback) {
        this.callback({
          // features: geojson.features,
          result: x.responseText,
          options: layerOptions,
          position: coords,
        });
      }
      if (this.gsiLayer) this.gsiLayer.addLayer(geojsonLayer);
      // this.addRouteData({features: features, position: position});
    };
    x.send();
  }
  start(coords) {
    this.key = coords.x + ":" + coords.y + ":" + coords.z;
    // this.done = [];
    this.load(coords, coords.z);
  }
  load(coords, orgZ) {
    if (coords.z - minZoom < 0) return;
    // if (this.maxNativeZoom < this.minZoom && coords.z < this.maxNativeZoom) return;
    // if (coords.z === orgZ && orgZ < this.minZoom) return;
    let dz = coords.z - this.maxNativeZoom;
    if (dz < 0) {
      // enough zooming
      const num = Math.pow(2, Math.abs(dz));
      let x = "";
      let y = "";
      for (let i = 0; i < num; i++) {
        x = i + Math.floor(coords.x / Math.pow(2, dz));
        for (let j = 0; j < num; j++) {
          y = j + Math.floor(coords.y / Math.pow(2, dz));
          this.load(
            {
              x: x,
              y: y,
              z: this.maxNativeZoom,
            },
            orgZ
          );
        }
      }
      return;
    } else if (0 < dz) {
      // overzooming
      this.load(
        {
          x: Math.floor(coords.x / 2),
          y: Math.floor(coords.y / 2),
          z: coords.z - 1,
        },
        orgZ
      );
      return;
    }
    for (let i in this.ts) {
      coords.t = this.ts[i];
      this.draw(coords);
    }
  }
  draw(coords) {
    // coords.s = '123'[Math.abs(coords.x+coords.y)%('123'.length)];
    var url = L.Util.template(this.baseUrl, coords);
    if (0 < this.done.indexOf(url)) return;
    this.done.push(url);
    this.get_tile(url, coords);
  }
};

<template lang="pug">
.draw-dialog-wrapper
  //- 設置アイコンダイアログ
  v-dialog.theme--white(
    v-show="openDialog",
    v-model="openDialog",
    max-width="500px"
  )
    v-card.white(v-if="openDialog")
      v-card-title.headline {{ markerList[selectedMarkerType].title }}
      v-divider
      v-card-text.cardText
        v-card.pa-2(flat, v-if="selectedMarkerInfo.name")
          h2.mb-0.green--text
            i.ic-shelter-emergency.mr-2
            span {{ selectedMarkerInfo.name || '' }}
          .ml-4
            h4.mb-0.green--text {{ selectedMarkerInfo.address || '' }}
            span(v-for="n in 8", v-if="selectedMarkerInfo")
              v-chip(
                label,
                outlined,
                small,
                color="orange",
                v-if="selectedMarkerInfo['disaster' + n]"
              ) {{ disasterNames[n] }}
        .pa-2(v-else) (オリジナル)
        .grey--text.text-xs-right(v-if="(latlng = selectedMarker._latlng)") {{ latlng.lat }} / {{ latlng.lng }}
        v-divider
        v-btn.primary(
          block,
          large,
          style="height: 64px",
          v-show="selectedMarkerType <= 1",
          @click.native="startAuto"
        )
          v-icon.mr-2 opacity
          span ここから逃げ道をひく！
        v-btn(
          block,
          large,
          v-show="selectedMarkerType <= 1",
          @click.native="resetAuto"
        )
          v-icon.mr-2 format_color_reset
          span 逃げ道をリセット
      v-divider
      v-card-actions
        v-btn.blue--text.darken-1(text, block, @click.native="closeDialog") 閉じる
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("map", [
      "map",
      "markerList",
      "selectedMarker",
      "selectedMarkerInfo",
      "selectedMarkerType",
      "colorsLayer",
    ]),
    disasterNames: () => [
      "洪水",
      "崖崩れ，土石流及び地滑り",
      "高潮",
      "地震",
      "津波",
      "大規模な火事",
      "内水氾濫",
      "火山現象",
    ],
    openDialog: {
      get: function () {
        return -1 < this.selectedMarkerType;
      },
      set: function (newVal) {
        if (!newVal) {
          this.closeDialog();
        }
      },
    },
  },
  methods: {
    ...mapActions("map", [
      "selectMarker",
      "startAutoShirube",
      "setViewsColorLayer",
      "refreshColorsLayer",
    ]),
    closeDialog() {
      // 避難場所ダイアログを閉じる
      this.selectMarker({ type: -1 });
    },
    startAuto() {
      // 避難場所ダイアログにて逃げ道を引く
      const icon = this.selectedMarker;
      this.refreshColorsLayer();
      this.setViewsColorLayer(true);
      this.startAutoShirube({ icon: icon });
      this.closeDialog();
    },
    resetAuto() {
      // 避難場所ダイアログにて逃げ道を消す
      this.refreshColorsLayer();
      this.closeDialog();
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.refreshColorsLayer();
  },
};
</script>

<style scoped>
</style>

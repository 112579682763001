// ここから：以下のWarningが発生する問題を修正
// Deprecated use of _flat, please use L.LineUtil.isFlat instead.
L.Edit.Poly.include({_defaultShape: function () { return L.LineUtil.isFlat ? L.LineUtil.isFlat(this._poly._latlngs) ? this._poly._latlngs : this._poly._latlngs[0] : this._poly._latlngs }});
L.Edit.PolyVerticesEdit.include({_defaultShape: function () { return L.LineUtil.isFlat ? L.LineUtil.isFlat(this._latlngs) ? this._latlngs : this._latlngs[0] : this._latlngs }});
// ここまで

// ここから：L.Draw.Polylineを再定義．項目は以下
// ・最後の点を消す時，残り1点が消されない問題を修正
// ・ドラッグの間は地図固定(dragging.disable)
// ・点を追加するマウスクリックを左のみにする（button==0）
// ・ドラッグすると2点追加モードに（_mouseUpOrigin）
L.Draw.Polyline.include({
  creating: false,
  addHooks: function () { L.Draw.Feature.prototype.addHooks.call(this), this._map && (this._markers = [], this._markerGroup = new L.LayerGroup, this._map.addLayer(this._markerGroup), this._poly = new L.Polyline([], this.options.shapeOptions), this._tooltip.updateContent(this._getTooltipText()), this._mouseMarker || (this._mouseMarker = L.marker(this._map.getCenter(), { icon: L.divIcon({ className: "leaflet-mouse-marker", iconAnchor: [20, 20], iconSize: [40, 40] }), opacity: 0, zIndexOffset: this.options.zIndexOffset })), this._mouseMarker.on("mouseout", this._onMouseOut, this).on("mousemove", this._onMouseMove, this).on("mousedown", this._onMouseDown, this).on("mouseup", this._onMouseUp, this).addTo(this._map), this._map.on("mouseup", this._onMouseUp, this).on("mousemove", this._onMouseMove, this).on("zoomlevelschange", this._onZoomEnd, this).on("touchstart", this._onTouch, this).on("zoomend", this._onZoomEnd, this)), this._map.dragging.enabled()&& this._map.dragging.disable()},
  removeHooks: function () { L.Draw.Feature.prototype.removeHooks.call(this), this._clearHideErrorTimeout(), this._cleanUpShape(), this._map.removeLayer(this._markerGroup), delete this._markerGroup, delete this._markers, this._map.removeLayer(this._poly), delete this._poly, this._mouseMarker.off("mousedown", this._onMouseDown, this).off("mouseout", this._onMouseOut, this).off("mouseup", this._onMouseUp, this).off("mousemove", this._onMouseMove, this), this._map.removeLayer(this._mouseMarker), delete this._mouseMarker, this._clearGuides(), this._map.off("mouseup", this._onMouseUp, this).off("mousemove", this._onMouseMove, this).off("zoomlevelschange", this._onZoomEnd, this).off("zoomend", this._onZoomEnd, this).off("touchstart", this._onTouch, this).off("click", this._onTouch, this), this._map.dragging.enabled()|| this._map.dragging.enable() },
  deleteLastVertex: function () {
    if (this._markers.length <= 0) {
      return;
    }
    var lastMarker = this._markers.pop(),
      poly = this._poly,
      latlngs = poly.getLatLngs(),
      latlng = latlngs.splice(-1, 1)[0];
    this._poly.setLatLngs(latlngs);
    this._markerGroup.removeLayer(lastMarker);
    if (poly.getLatLngs().length < 2) {
      this._map.removeLayer(poly);
    }
    if (0 < this._markers.length) {
      this._vertexChanged(latlng, false);
    } else {
      this._clearGuides();
      this._updateTooltip();
    }
  },
  _onMouseDown: function (e) {
    if (e.originalEvent.button === 0 && !this._clickHandled && !this._touchHandled && !this._disableMarkers) {
      this.creating = true;
      this._clickHandled = true;
      this._disableNewMarkers();
      var originalEvent = e.originalEvent;
      var clientX = originalEvent.clientX;
      var clientY = originalEvent.clientY;
      this._startPoint.call(this, clientX, clientY);
    }
  },
  _onMouseMove: function (e) {
    var newPos = this._map.mouseEventToLayerPoint(e.originalEvent);
    var latlng = this._map.layerPointToLatLng(newPos);
    this._currentLatLng = latlng;
    this._updateTooltip(latlng);
    this._updateGuide(newPos);
    this._mouseMarker.setLatLng(latlng);
    L.DomEvent.preventDefault(e.originalEvent);
  },
  _onMouseUp: function (e) {
    var originalEvent = e.originalEvent;
    var clientX = originalEvent.clientX;
    var clientY = originalEvent.clientY;
    if (this.creating) {
      this.creating = false;
      this._mouseUpOrigin = L.point(clientX, clientY);
    }
    this._endPoint.call(this, clientX, clientY, e);
    this._clickHandled = null;
  },
  _onTouch: function (e) {
    var originalEvent = e.originalEvent;
    var clientX;
    var clientY;
    if ((originalEvent.button === 0 || originalEvent.pointerType !== 'mouse') && originalEvent.touches && originalEvent.touches[0] && !this._clickHandled && !this._touchHandled && !this._disableMarkers) {
      this.creating = true;
      clientX = originalEvent.touches[0].clientX;
      clientY = originalEvent.touches[0].clientY;
      this._disableNewMarkers();
      this._touchHandled = true;
      this._startPoint.call(this, clientX, clientY);
      this._endPoint.call(this, clientX, clientY, e);
      this._touchHandled = null;
    }
    this._clickHandled = null;
  },
  _endPoint: function (clientX, clientY, e) {
    if (this._mouseDownOrigin || this._mouseUpOrigin) {
      var dragCheckDistance = L.point(clientX, clientY)
        .distanceTo(this._mouseDownOrigin || this._mouseUpOrigin);
      var lastPtDistance = this._calculateFinishDistance(e.latlng);
      if (this.options.maxPoints > 1 && this.options.maxPoints == this._markers.length + 1) {
        // 終点を追加して完了
        this.addVertex(e.latlng);
        this._finishShape();
      } else if (lastPtDistance < 10 && L.Browser.touch && this._mouseDownOrigin) {
        // 完了
        this._finishShape();
      } else if (Math.abs(dragCheckDistance) < 9 * (window.devicePixelRatio || 1)) {
        // 終点を追加
        this.addVertex(e.latlng);
      }
      this._enableNewMarkers(); // after a short pause, enable new markers
    }
    this._mouseDownOrigin = null;
    this._mouseUpOrigin = null;
  },
});
// ここまで

// ここから：ドラッグでも削除できるよう修正
L.EditToolbar.Delete.include({
  dragging: false,
  addHooks: function () {
    var map = this._map;
    if (map) {
      map.getContainer().focus();
      this._deletableLayers.eachLayer(this._enableLayerDelete, this);
      this._deletedLayers = new L.LayerGroup();
      this._tooltip = new L.Draw.Tooltip(this._map);
      this._tooltip.updateContent({text: L.drawLocal.edit.handlers.remove.tooltip.text});
      this._map.on('mousedown', this._onMouseDown, this)
      // ここから追加
      this._map.on('mouseup', this._onMouseUp, this);
      this._map.on('mousemove', this._onMouseMove, this);
      if (this._map.dragging.enabled()) this._map.dragging.disable();
      // ここまで追加
    }
  },
  removeHooks: function () {
    if (this._map) {
      this._deletableLayers.eachLayer(this._disableLayerDelete, this);
      this._deletedLayers = null;

      this._tooltip.dispose();
      this._tooltip = null;

      this._map.off('mousedown', this._onMouseDown, this).off('mouseup', this._onMouseUp, this);
      if (!this._map.dragging.enabled()) this._map.dragging.enable();
    }
  },
  _enableLayerDelete: function (e) {
    var layer = e.layer || e.target || e;
    layer.on('click', this._removeLayer, this).on('mouseover', this._onMouseOver, this);
  },
  _disableLayerDelete: function (e) {
    var layer = e.layer || e.target || e;
    layer.off('click', this._removeLayer, this).off('mouseover', this._onMouseOver, this);
    this._deletedLayers.removeLayer(layer);
  },
  _onMouseDown: function (e) {
    this.dragging = true;
  },
  _onMouseUp: function (e) {
    this.dragging = false;
  },
  _onMouseMove: function (e) {
  if (this._tooltip) {
    this._tooltip.updatePosition(e.latlng);
  }
},
  _onMouseOver: function (e) {
    if (this.dragging) {
      var layer = e.layer || e.target || e;
      this._removeLayer({layer: layer});
    }
  },
});
// ここまで

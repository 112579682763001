export default [{
    "name": "北海道",
    "cities": [{
        "name": "札幌市中央区",
        "latitude": 43.05546,
        "longitude": 141.340956
      },
      {
        "name": "札幌市北区",
        "latitude": 43.09085,
        "longitude": 141.340831
      },
      {
        "name": "札幌市東区",
        "latitude": 43.076069,
        "longitude": 141.363722
      },
      {
        "name": "札幌市白石区",
        "latitude": 43.047687,
        "longitude": 141.405078
      },
      {
        "name": "札幌市豊平区",
        "latitude": 43.031291,
        "longitude": 141.380106
      },
      {
        "name": "札幌市南区",
        "latitude": 42.990031,
        "longitude": 141.353497
      },
      {
        "name": "札幌市西区",
        "latitude": 43.07447,
        "longitude": 141.300889
      },
      {
        "name": "札幌市厚別区",
        "latitude": 43.036408,
        "longitude": 141.474789
      },
      {
        "name": "札幌市手稲区",
        "latitude": 43.121944,
        "longitude": 141.245632
      },
      {
        "name": "札幌市清田区",
        "latitude": 42.999636,
        "longitude": 141.44383
      },
      {
        "name": "函館市",
        "latitude": 41.768793,
        "longitude": 140.72881
      },
      {
        "name": "小樽市",
        "latitude": 43.190717,
        "longitude": 140.994662
      },
      {
        "name": "旭川市",
        "latitude": 43.770636,
        "longitude": 142.364819
      },
      {
        "name": "室蘭市",
        "latitude": 42.315231,
        "longitude": 140.973799
      },
      {
        "name": "釧路市",
        "latitude": 42.984854,
        "longitude": 144.381356
      },
      {
        "name": "帯広市",
        "latitude": 42.923899,
        "longitude": 143.196103
      },
      {
        "name": "北見市",
        "latitude": 43.804031,
        "longitude": 143.895876
      },
      {
        "name": "夕張市",
        "latitude": 43.056734,
        "longitude": 141.973896
      },
      {
        "name": "岩見沢市",
        "latitude": 43.196206,
        "longitude": 141.775933
      },
      {
        "name": "網走市",
        "latitude": 44.020632,
        "longitude": 144.273398
      },
      {
        "name": "留萌市",
        "latitude": 43.940987,
        "longitude": 141.637012
      },
      {
        "name": "苫小牧市",
        "latitude": 42.634302,
        "longitude": 141.605372
      },
      {
        "name": "稚内市",
        "latitude": 45.415664,
        "longitude": 141.673082
      },
      {
        "name": "美唄市",
        "latitude": 43.332881,
        "longitude": 141.853785
      },
      {
        "name": "芦別市",
        "latitude": 43.518166,
        "longitude": 142.189436
      },
      {
        "name": "江別市",
        "latitude": 43.103745,
        "longitude": 141.536247
      },
      {
        "name": "赤平市",
        "latitude": 43.558074,
        "longitude": 142.044211
      },
      {
        "name": "紋別市",
        "latitude": 44.356484,
        "longitude": 143.354503
      },
      {
        "name": "士別市",
        "latitude": 44.178734,
        "longitude": 142.400404
      },
      {
        "name": "名寄市",
        "latitude": 44.355938,
        "longitude": 142.463167
      },
      {
        "name": "三笠市",
        "latitude": 43.245435,
        "longitude": 141.875327
      },
      {
        "name": "根室市",
        "latitude": 43.330076,
        "longitude": 145.58279
      },
      {
        "name": "千歳市",
        "latitude": 42.820958,
        "longitude": 141.650876
      },
      {
        "name": "滝川市",
        "latitude": 43.557795,
        "longitude": 141.91019
      },
      {
        "name": "砂川市",
        "latitude": 43.494843,
        "longitude": 141.903465
      },
      {
        "name": "歌志内市",
        "latitude": 43.521353,
        "longitude": 142.034568
      },
      {
        "name": "深川市",
        "latitude": 43.72326,
        "longitude": 142.053538
      },
      {
        "name": "富良野市",
        "latitude": 43.34214,
        "longitude": 142.383225
      },
      {
        "name": "登別市",
        "latitude": 42.41276,
        "longitude": 141.106632
      },
      {
        "name": "恵庭市",
        "latitude": 42.882562,
        "longitude": 141.577815
      },
      {
        "name": "伊達市",
        "latitude": 42.471851,
        "longitude": 140.864679
      },
      {
        "name": "北広島市",
        "latitude": 42.98563,
        "longitude": 141.563543
      },
      {
        "name": "石狩市",
        "latitude": 43.171384,
        "longitude": 141.31552
      },
      {
        "name": "北斗市",
        "latitude": 41.82406,
        "longitude": 140.652903
      },
      {
        "name": "石狩郡当別町",
        "latitude": 43.223683,
        "longitude": 141.517072
      },
      {
        "name": "石狩郡新篠津村",
        "latitude": 43.225424,
        "longitude": 141.649216
      },
      {
        "name": "松前郡松前町",
        "latitude": 41.430037,
        "longitude": 140.110435
      },
      {
        "name": "松前郡福島町",
        "latitude": 41.483802,
        "longitude": 140.251326
      },
      {
        "name": "上磯郡知内町",
        "latitude": 41.598356,
        "longitude": 140.418963
      },
      {
        "name": "上磯郡木古内町",
        "latitude": 41.678264,
        "longitude": 140.437568
      },
      {
        "name": "亀田郡七飯町",
        "latitude": 41.89572,
        "longitude": 140.69428
      },
      {
        "name": "茅部郡鹿部町",
        "latitude": 42.038487,
        "longitude": 140.815939
      },
      {
        "name": "茅部郡森町",
        "latitude": 42.104795,
        "longitude": 140.576324
      },
      {
        "name": "二海郡八雲町",
        "latitude": 42.255999,
        "longitude": 140.265281
      },
      {
        "name": "山越郡長万部町",
        "latitude": 42.513688,
        "longitude": 140.380633
      },
      {
        "name": "檜山郡江差町",
        "latitude": 41.869156,
        "longitude": 140.12746
      },
      {
        "name": "檜山郡上ノ国町",
        "latitude": 41.801082,
        "longitude": 140.121348
      },
      {
        "name": "檜山郡厚沢部町",
        "latitude": 41.920901,
        "longitude": 140.225472
      },
      {
        "name": "爾志郡乙部町",
        "latitude": 41.968501,
        "longitude": 140.135719
      },
      {
        "name": "奥尻郡奥尻町",
        "latitude": 42.172278,
        "longitude": 139.514008
      },
      {
        "name": "瀬棚郡今金町",
        "latitude": 42.429422,
        "longitude": 140.008878
      },
      {
        "name": "久遠郡せたな町",
        "latitude": 42.417161,
        "longitude": 139.882988
      },
      {
        "name": "島牧郡島牧村",
        "latitude": 42.70052,
        "longitude": 140.06146
      },
      {
        "name": "寿都郡寿都町",
        "latitude": 42.790979,
        "longitude": 140.22898
      },
      {
        "name": "寿都郡黒松内町",
        "latitude": 42.667864,
        "longitude": 140.30754
      },
      {
        "name": "磯谷郡蘭越町",
        "latitude": 42.809075,
        "longitude": 140.528312
      },
      {
        "name": "虻田郡ニセコ町",
        "latitude": 42.804705,
        "longitude": 140.687493
      },
      {
        "name": "虻田郡真狩村",
        "latitude": 42.762971,
        "longitude": 140.803744
      },
      {
        "name": "虻田郡留寿都村",
        "latitude": 42.73736,
        "longitude": 140.875591
      },
      {
        "name": "虻田郡喜茂別町",
        "latitude": 42.795472,
        "longitude": 140.9345
      },
      {
        "name": "虻田郡京極町",
        "latitude": 42.858208,
        "longitude": 140.884088
      },
      {
        "name": "虻田郡倶知安町",
        "latitude": 42.901829,
        "longitude": 140.758698
      },
      {
        "name": "虻田郡豊浦町",
        "latitude": 42.583447,
        "longitude": 140.711832
      },
      {
        "name": "虻田郡洞爺湖町",
        "latitude": 42.551269,
        "longitude": 140.764098
      },
      {
        "name": "岩内郡共和町",
        "latitude": 42.980398,
        "longitude": 140.611187
      },
      {
        "name": "岩内郡岩内町",
        "latitude": 42.979785,
        "longitude": 140.514753
      },
      {
        "name": "古宇郡泊村",
        "latitude": 43.063355,
        "longitude": 140.498859
      },
      {
        "name": "古宇郡神恵内村",
        "latitude": 43.143813,
        "longitude": 140.430742
      },
      {
        "name": "積丹郡積丹町",
        "latitude": 43.298853,
        "longitude": 140.598193
      },
      {
        "name": "古平郡古平町",
        "latitude": 43.26536,
        "longitude": 140.63882
      },
      {
        "name": "余市郡仁木町",
        "latitude": 43.151691,
        "longitude": 140.76613
      },
      {
        "name": "余市郡余市町",
        "latitude": 43.195232,
        "longitude": 140.783467
      },
      {
        "name": "余市郡赤井川村",
        "latitude": 43.08349,
        "longitude": 140.813481
      },
      {
        "name": "空知郡南幌町",
        "latitude": 43.063727,
        "longitude": 141.650069
      },
      {
        "name": "空知郡奈井江町",
        "latitude": 43.425106,
        "longitude": 141.882788
      },
      {
        "name": "空知郡上砂川町",
        "latitude": 43.482608,
        "longitude": 141.984084
      },
      {
        "name": "空知郡上富良野町",
        "latitude": 43.455661,
        "longitude": 142.467073
      },
      {
        "name": "空知郡中富良野町",
        "latitude": 43.405671,
        "longitude": 142.425136
      },
      {
        "name": "空知郡南富良野町",
        "latitude": 43.163979,
        "longitude": 142.568488
      },
      {
        "name": "夕張郡由仁町",
        "latitude": 42.999605,
        "longitude": 141.790338
      },
      {
        "name": "夕張郡長沼町",
        "latitude": 43.010353,
        "longitude": 141.695252
      },
      {
        "name": "夕張郡栗山町",
        "latitude": 43.056309,
        "longitude": 141.784255
      },
      {
        "name": "樺戸郡月形町",
        "latitude": 43.338436,
        "longitude": 141.669451
      },
      {
        "name": "樺戸郡浦臼町",
        "latitude": 43.430322,
        "longitude": 141.818837
      },
      {
        "name": "樺戸郡新十津川町",
        "latitude": 43.548547,
        "longitude": 141.877032
      },
      {
        "name": "雨竜郡妹背牛町",
        "latitude": 43.700112,
        "longitude": 141.961711
      },
      {
        "name": "雨竜郡秩父別町",
        "latitude": 43.767013,
        "longitude": 141.957958
      },
      {
        "name": "雨竜郡雨竜町",
        "latitude": 43.643924,
        "longitude": 141.88899
      },
      {
        "name": "雨竜郡北竜町",
        "latitude": 43.731356,
        "longitude": 141.879022
      },
      {
        "name": "雨竜郡沼田町",
        "latitude": 43.806802,
        "longitude": 141.933272
      },
      {
        "name": "雨竜郡幌加内町",
        "latitude": 44.009854,
        "longitude": 142.153933
      },
      {
        "name": "上川郡鷹栖町",
        "latitude": 43.843353,
        "longitude": 142.354451
      },
      {
        "name": "上川郡東神楽町",
        "latitude": 43.696626,
        "longitude": 142.451437
      },
      {
        "name": "上川郡当麻町",
        "latitude": 43.828177,
        "longitude": 142.508366
      },
      {
        "name": "上川郡比布町",
        "latitude": 43.875076,
        "longitude": 142.477552
      },
      {
        "name": "上川郡愛別町",
        "latitude": 43.906504,
        "longitude": 142.577973
      },
      {
        "name": "上川郡上川町",
        "latitude": 43.84718,
        "longitude": 142.770431
      },
      {
        "name": "上川郡東川町",
        "latitude": 43.698974,
        "longitude": 142.510317
      },
      {
        "name": "上川郡美瑛町",
        "latitude": 43.588187,
        "longitude": 142.466972
      },
      {
        "name": "上川郡和寒町",
        "latitude": 44.023068,
        "longitude": 142.413306
      },
      {
        "name": "上川郡剣淵町",
        "latitude": 44.095677,
        "longitude": 142.361221
      },
      {
        "name": "上川郡下川町",
        "latitude": 44.302503,
        "longitude": 142.635308
      },
      {
        "name": "上川郡新得町",
        "latitude": 43.079796,
        "longitude": 142.838737
      },
      {
        "name": "上川郡清水町",
        "latitude": 43.011356,
        "longitude": 142.88454
      },
      {
        "name": "勇払郡占冠村",
        "latitude": 42.979779,
        "longitude": 142.398357
      },
      {
        "name": "勇払郡厚真町",
        "latitude": 42.723662,
        "longitude": 141.877935
      },
      {
        "name": "勇払郡安平町",
        "latitude": 42.762815,
        "longitude": 141.818017
      },
      {
        "name": "勇払郡むかわ町",
        "latitude": 42.574807,
        "longitude": 141.92675
      },
      {
        "name": "中川郡美深町",
        "latitude": 44.481126,
        "longitude": 142.343014
      },
      {
        "name": "中川郡音威子府村",
        "latitude": 44.725004,
        "longitude": 142.262464
      },
      {
        "name": "中川郡中川町",
        "latitude": 44.811346,
        "longitude": 142.071438
      },
      {
        "name": "中川郡幕別町",
        "latitude": 42.908234,
        "longitude": 143.355969
      },
      {
        "name": "中川郡池田町",
        "latitude": 42.928955,
        "longitude": 143.448355
      },
      {
        "name": "中川郡豊頃町",
        "latitude": 42.801052,
        "longitude": 143.505919
      },
      {
        "name": "中川郡本別町",
        "latitude": 43.124712,
        "longitude": 143.610573
      },
      {
        "name": "増毛郡増毛町",
        "latitude": 43.856033,
        "longitude": 141.525198
      },
      {
        "name": "留萌郡小平町",
        "latitude": 44.015108,
        "longitude": 141.662575
      },
      {
        "name": "苫前郡苫前町",
        "latitude": 44.30604,
        "longitude": 141.653022
      },
      {
        "name": "苫前郡羽幌町",
        "latitude": 44.360683,
        "longitude": 141.69725
      },
      {
        "name": "苫前郡初山別村",
        "latitude": 44.532154,
        "longitude": 141.766409
      },
      {
        "name": "天塩郡遠別町",
        "latitude": 44.722139,
        "longitude": 141.792369
      },
      {
        "name": "天塩郡天塩町",
        "latitude": 44.888341,
        "longitude": 141.745397
      },
      {
        "name": "天塩郡豊富町",
        "latitude": 45.102933,
        "longitude": 141.777505
      },
      {
        "name": "天塩郡幌延町",
        "latitude": 45.017711,
        "longitude": 141.849384
      },
      {
        "name": "宗谷郡猿払村",
        "latitude": 45.330616,
        "longitude": 142.109192
      },
      {
        "name": "枝幸郡浜頓別町",
        "latitude": 45.123694,
        "longitude": 142.359948
      },
      {
        "name": "枝幸郡中頓別町",
        "latitude": 44.969819,
        "longitude": 142.286735
      },
      {
        "name": "枝幸郡枝幸町",
        "latitude": 44.938714,
        "longitude": 142.581411
      },
      {
        "name": "礼文郡礼文町",
        "latitude": 45.30302,
        "longitude": 141.047689
      },
      {
        "name": "利尻郡利尻町",
        "latitude": 45.187068,
        "longitude": 141.139627
      },
      {
        "name": "利尻郡利尻富士町",
        "latitude": 45.247735,
        "longitude": 141.214344
      },
      {
        "name": "網走郡美幌町",
        "latitude": 43.823782,
        "longitude": 144.107042
      },
      {
        "name": "網走郡津別町",
        "latitude": 43.706453,
        "longitude": 144.025032
      },
      {
        "name": "網走郡大空町",
        "latitude": 43.91185,
        "longitude": 144.172709
      },
      {
        "name": "斜里郡斜里町",
        "latitude": 43.911513,
        "longitude": 144.670605
      },
      {
        "name": "斜里郡清里町",
        "latitude": 43.835216,
        "longitude": 144.594562
      },
      {
        "name": "斜里郡小清水町",
        "latitude": 43.856852,
        "longitude": 144.462025
      },
      {
        "name": "常呂郡訓子府町",
        "latitude": 43.725255,
        "longitude": 143.74173
      },
      {
        "name": "常呂郡置戸町",
        "latitude": 43.676323,
        "longitude": 143.586397
      },
      {
        "name": "常呂郡佐呂間町",
        "latitude": 44.017756,
        "longitude": 143.774506
      },
      {
        "name": "紋別郡遠軽町",
        "latitude": 44.061953,
        "longitude": 143.527947
      },
      {
        "name": "紋別郡湧別町",
        "latitude": 44.151598,
        "longitude": 143.572894
      },
      {
        "name": "紋別郡滝上町",
        "latitude": 44.192203,
        "longitude": 143.077588
      },
      {
        "name": "紋別郡興部町",
        "latitude": 44.469878,
        "longitude": 143.123891
      },
      {
        "name": "紋別郡西興部村",
        "latitude": 44.328853,
        "longitude": 142.944392
      },
      {
        "name": "紋別郡雄武町",
        "latitude": 44.582551,
        "longitude": 142.96181
      },
      {
        "name": "有珠郡壮瞥町",
        "latitude": 42.552283,
        "longitude": 140.886014
      },
      {
        "name": "白老郡白老町",
        "latitude": 42.55126,
        "longitude": 141.355955
      },
      {
        "name": "沙流郡日高町",
        "latitude": 42.48029,
        "longitude": 142.074398
      },
      {
        "name": "沙流郡平取町",
        "latitude": 42.585081,
        "longitude": 142.128523
      },
      {
        "name": "新冠郡新冠町",
        "latitude": 42.362406,
        "longitude": 142.318332
      },
      {
        "name": "浦河郡浦河町",
        "latitude": 42.168516,
        "longitude": 142.76812
      },
      {
        "name": "様似郡様似町",
        "latitude": 42.127853,
        "longitude": 142.93355
      },
      {
        "name": "幌泉郡えりも町",
        "latitude": 42.01628,
        "longitude": 143.148443
      },
      {
        "name": "日高郡新ひだか町",
        "latitude": 42.341281,
        "longitude": 142.368591
      },
      {
        "name": "河東郡音更町",
        "latitude": 42.994143,
        "longitude": 143.19796
      },
      {
        "name": "河東郡士幌町",
        "latitude": 43.168027,
        "longitude": 143.241377
      },
      {
        "name": "河東郡上士幌町",
        "latitude": 43.232548,
        "longitude": 143.296189
      },
      {
        "name": "河東郡鹿追町",
        "latitude": 43.099102,
        "longitude": 142.989043
      },
      {
        "name": "河西郡芽室町",
        "latitude": 42.91186,
        "longitude": 143.050863
      },
      {
        "name": "河西郡中札内村",
        "latitude": 42.697614,
        "longitude": 143.132443
      },
      {
        "name": "河西郡更別村",
        "latitude": 42.650351,
        "longitude": 143.187858
      },
      {
        "name": "広尾郡大樹町",
        "latitude": 42.49754,
        "longitude": 143.278814
      },
      {
        "name": "広尾郡広尾町",
        "latitude": 42.285838,
        "longitude": 143.311496
      },
      {
        "name": "足寄郡足寄町",
        "latitude": 43.244801,
        "longitude": 143.553905
      },
      {
        "name": "足寄郡陸別町",
        "latitude": 43.468981,
        "longitude": 143.747243
      },
      {
        "name": "十勝郡浦幌町",
        "latitude": 42.808927,
        "longitude": 143.658396
      },
      {
        "name": "釧路郡釧路町",
        "latitude": 42.996045,
        "longitude": 144.466032
      },
      {
        "name": "厚岸郡厚岸町",
        "latitude": 43.051871,
        "longitude": 144.84736
      },
      {
        "name": "厚岸郡浜中町",
        "latitude": 43.077145,
        "longitude": 145.129369
      },
      {
        "name": "川上郡標茶町",
        "latitude": 43.30344,
        "longitude": 144.600779
      },
      {
        "name": "川上郡弟子屈町",
        "latitude": 43.485348,
        "longitude": 144.459221
      },
      {
        "name": "阿寒郡鶴居村",
        "latitude": 43.229887,
        "longitude": 144.321216
      },
      {
        "name": "白糠郡白糠町",
        "latitude": 42.956177,
        "longitude": 144.071805
      },
      {
        "name": "野付郡別海町",
        "latitude": 43.393787,
        "longitude": 145.117271
      },
      {
        "name": "標津郡中標津町",
        "latitude": 43.555242,
        "longitude": 144.971391
      },
      {
        "name": "標津郡標津町",
        "latitude": 43.661387,
        "longitude": 145.131319
      },
      {
        "name": "目梨郡羅臼町",
        "latitude": 44.021876,
        "longitude": 145.18962
      }
    ]
  },
  {
    "name": "青森県",
    "cities": [{
        "name": "青森市",
        "latitude": 40.822072,
        "longitude": 140.747365
      },
      {
        "name": "弘前市",
        "latitude": 40.603124,
        "longitude": 140.463922
      },
      {
        "name": "八戸市",
        "latitude": 40.512284,
        "longitude": 141.488399
      },
      {
        "name": "黒石市",
        "latitude": 40.642632,
        "longitude": 140.594645
      },
      {
        "name": "五所川原市",
        "latitude": 40.808002,
        "longitude": 140.44009
      },
      {
        "name": "十和田市",
        "latitude": 40.612703,
        "longitude": 141.205908
      },
      {
        "name": "三沢市",
        "latitude": 40.683134,
        "longitude": 141.368976
      },
      {
        "name": "むつ市",
        "latitude": 41.292746,
        "longitude": 141.183476
      },
      {
        "name": "つがる市",
        "latitude": 40.808639,
        "longitude": 140.380059
      },
      {
        "name": "平川市",
        "latitude": 40.584011,
        "longitude": 140.566478
      },
      {
        "name": "東津軽郡平内町",
        "latitude": 40.926031,
        "longitude": 140.95592
      },
      {
        "name": "東津軽郡今別町",
        "latitude": 41.181757,
        "longitude": 140.481654
      },
      {
        "name": "東津軽郡蓬田村",
        "latitude": 40.971714,
        "longitude": 140.655966
      },
      {
        "name": "東津軽郡外ヶ浜町",
        "latitude": 41.043159,
        "longitude": 140.632296
      },
      {
        "name": "西津軽郡鰺ヶ沢町",
        "latitude": 40.779913,
        "longitude": 140.208695
      },
      {
        "name": "西津軽郡深浦町",
        "latitude": 40.647895,
        "longitude": 139.927375
      },
      {
        "name": "中津軽郡西目屋村",
        "latitude": 40.576709,
        "longitude": 140.296288
      },
      {
        "name": "南津軽郡藤崎町",
        "latitude": 40.656069,
        "longitude": 140.502811
      },
      {
        "name": "南津軽郡大鰐町",
        "latitude": 40.518188,
        "longitude": 140.567828
      },
      {
        "name": "南津軽郡田舎館村",
        "latitude": 40.631252,
        "longitude": 140.550116
      },
      {
        "name": "北津軽郡板柳町",
        "latitude": 40.695894,
        "longitude": 140.457324
      },
      {
        "name": "北津軽郡鶴田町",
        "latitude": 40.758605,
        "longitude": 140.428382
      },
      {
        "name": "北津軽郡中泊町",
        "latitude": 40.965179,
        "longitude": 140.43984
      },
      {
        "name": "上北郡野辺地町",
        "latitude": 40.864469,
        "longitude": 141.128757
      },
      {
        "name": "上北郡七戸町",
        "latitude": 40.697416,
        "longitude": 141.151021
      },
      {
        "name": "上北郡六戸町",
        "latitude": 40.609521,
        "longitude": 141.324949
      },
      {
        "name": "上北郡横浜町",
        "latitude": 41.083147,
        "longitude": 141.247793
      },
      {
        "name": "上北郡東北町",
        "latitude": 40.727876,
        "longitude": 141.257882
      },
      {
        "name": "上北郡六ヶ所村",
        "latitude": 40.967344,
        "longitude": 141.374552
      },
      {
        "name": "上北郡おいらせ町",
        "latitude": 40.599107,
        "longitude": 141.397797
      },
      {
        "name": "下北郡大間町",
        "latitude": 41.526771,
        "longitude": 140.907438
      },
      {
        "name": "下北郡東通村",
        "latitude": 41.277787,
        "longitude": 141.328936
      },
      {
        "name": "下北郡風間浦村",
        "latitude": 41.487574,
        "longitude": 140.995729
      },
      {
        "name": "下北郡佐井村",
        "latitude": 41.429706,
        "longitude": 140.859129
      },
      {
        "name": "三戸郡三戸町",
        "latitude": 40.378407,
        "longitude": 141.258745
      },
      {
        "name": "三戸郡五戸町",
        "latitude": 40.531225,
        "longitude": 141.30779
      },
      {
        "name": "三戸郡田子町",
        "latitude": 40.339962,
        "longitude": 141.15205
      },
      {
        "name": "三戸郡南部町",
        "latitude": 40.466593,
        "longitude": 141.381986
      },
      {
        "name": "三戸郡階上町",
        "latitude": 40.452489,
        "longitude": 141.620851
      },
      {
        "name": "三戸郡新郷村",
        "latitude": 40.465626,
        "longitude": 141.173464
      }
    ]
  },
  {
    "name": "岩手県",
    "cities": [{
        "name": "盛岡市",
        "latitude": 39.702053,
        "longitude": 141.154484
      },
      {
        "name": "宮古市",
        "latitude": 39.64142,
        "longitude": 141.95714
      },
      {
        "name": "大船渡市",
        "latitude": 39.081901,
        "longitude": 141.70853
      },
      {
        "name": "花巻市",
        "latitude": 39.388578,
        "longitude": 141.116645
      },
      {
        "name": "北上市",
        "latitude": 39.286765,
        "longitude": 141.113191
      },
      {
        "name": "久慈市",
        "latitude": 40.190524,
        "longitude": 141.775583
      },
      {
        "name": "遠野市",
        "latitude": 39.327838,
        "longitude": 141.533303
      },
      {
        "name": "一関市",
        "latitude": 38.934682,
        "longitude": 141.1268
      },
      {
        "name": "陸前高田市",
        "latitude": 39.027661,
        "longitude": 141.625495
      },
      {
        "name": "釜石市",
        "latitude": 39.275818,
        "longitude": 141.885677
      },
      {
        "name": "二戸市",
        "latitude": 40.271213,
        "longitude": 141.304817
      },
      {
        "name": "八幡平市",
        "latitude": 39.956477,
        "longitude": 141.071292
      },
      {
        "name": "奥州市",
        "latitude": 39.144529,
        "longitude": 141.139141
      },
      {
        "name": "滝沢市",
        "latitude": 39.734684,
        "longitude": 141.077095
      },
      {
        "name": "岩手郡雫石町",
        "latitude": 39.696288,
        "longitude": 140.975776
      },
      {
        "name": "岩手郡葛巻町",
        "latitude": 40.039896,
        "longitude": 141.436465
      },
      {
        "name": "岩手郡岩手町",
        "latitude": 39.97259,
        "longitude": 141.212516
      },
      {
        "name": "紫波郡紫波町",
        "latitude": 39.554568,
        "longitude": 141.155659
      },
      {
        "name": "紫波郡矢巾町",
        "latitude": 39.605981,
        "longitude": 141.142889
      },
      {
        "name": "和賀郡西和賀町",
        "latitude": 39.434621,
        "longitude": 140.750124
      },
      {
        "name": "胆沢郡金ケ崎町",
        "latitude": 39.195742,
        "longitude": 141.116324
      },
      {
        "name": "西磐井郡平泉町",
        "latitude": 38.986662,
        "longitude": 141.113822
      },
      {
        "name": "気仙郡住田町",
        "latitude": 39.14223,
        "longitude": 141.575047
      },
      {
        "name": "上閉伊郡大槌町",
        "latitude": 39.358231,
        "longitude": 141.899422
      },
      {
        "name": "下閉伊郡山田町",
        "latitude": 39.467662,
        "longitude": 141.94895
      },
      {
        "name": "下閉伊郡岩泉町",
        "latitude": 39.843042,
        "longitude": 141.796303
      },
      {
        "name": "下閉伊郡田野畑村",
        "latitude": 39.93045,
        "longitude": 141.888868
      },
      {
        "name": "下閉伊郡普代村",
        "latitude": 40.005167,
        "longitude": 141.885908
      },
      {
        "name": "九戸郡軽米町",
        "latitude": 40.326746,
        "longitude": 141.460633
      },
      {
        "name": "九戸郡野田村",
        "latitude": 40.110266,
        "longitude": 141.817668
      },
      {
        "name": "九戸郡九戸村",
        "latitude": 40.211392,
        "longitude": 141.418985
      },
      {
        "name": "九戸郡洋野町",
        "latitude": 40.284263,
        "longitude": 141.624833
      },
      {
        "name": "二戸郡一戸町",
        "latitude": 40.213011,
        "longitude": 141.295475
      }
    ]
  },
  {
    "name": "宮城県",
    "cities": [{
        "name": "仙台市青葉区",
        "latitude": 38.269076,
        "longitude": 140.870403
      },
      {
        "name": "仙台市宮城野区",
        "latitude": 38.266139,
        "longitude": 140.909832
      },
      {
        "name": "仙台市若林区",
        "latitude": 38.244224,
        "longitude": 140.900756
      },
      {
        "name": "仙台市太白区",
        "latitude": 38.224348,
        "longitude": 140.877134
      },
      {
        "name": "仙台市泉区",
        "latitude": 38.326375,
        "longitude": 140.881629
      },
      {
        "name": "石巻市",
        "latitude": 38.43448,
        "longitude": 141.302917
      },
      {
        "name": "塩竈市",
        "latitude": 38.314362,
        "longitude": 141.022027
      },
      {
        "name": "気仙沼市",
        "latitude": 38.908078,
        "longitude": 141.569992
      },
      {
        "name": "白石市",
        "latitude": 38.002478,
        "longitude": 140.61986
      },
      {
        "name": "名取市",
        "latitude": 38.17152,
        "longitude": 140.891913
      },
      {
        "name": "角田市",
        "latitude": 37.977063,
        "longitude": 140.78199
      },
      {
        "name": "多賀城市",
        "latitude": 38.293839,
        "longitude": 141.004225
      },
      {
        "name": "岩沼市",
        "latitude": 38.104292,
        "longitude": 140.870138
      },
      {
        "name": "登米市",
        "latitude": 38.691923,
        "longitude": 141.187579
      },
      {
        "name": "栗原市",
        "latitude": 38.730128,
        "longitude": 141.021333
      },
      {
        "name": "東松島市",
        "latitude": 38.426168,
        "longitude": 141.210242
      },
      {
        "name": "大崎市",
        "latitude": 38.577071,
        "longitude": 140.95535
      },
      {
        "name": "刈田郡蔵王町",
        "latitude": 38.098164,
        "longitude": 140.658416
      },
      {
        "name": "刈田郡七ヶ宿町",
        "latitude": 37.993063,
        "longitude": 140.441612
      },
      {
        "name": "柴田郡大河原町",
        "latitude": 38.049493,
        "longitude": 140.730721
      },
      {
        "name": "柴田郡村田町",
        "latitude": 38.118539,
        "longitude": 140.722404
      },
      {
        "name": "柴田郡柴田町",
        "latitude": 38.056563,
        "longitude": 140.76579
      },
      {
        "name": "柴田郡川崎町",
        "latitude": 38.177737,
        "longitude": 140.643202
      },
      {
        "name": "伊具郡丸森町",
        "latitude": 37.911429,
        "longitude": 140.765528
      },
      {
        "name": "亘理郡亘理町",
        "latitude": 38.03746,
        "longitude": 140.852581
      },
      {
        "name": "亘理郡山元町",
        "latitude": 37.961668,
        "longitude": 140.877818
      },
      {
        "name": "宮城郡松島町",
        "latitude": 38.380128,
        "longitude": 141.067244
      },
      {
        "name": "宮城郡七ヶ浜町",
        "latitude": 38.304613,
        "longitude": 141.059186
      },
      {
        "name": "宮城郡利府町",
        "latitude": 38.33019,
        "longitude": 140.975722
      },
      {
        "name": "黒川郡大和町",
        "latitude": 38.437329,
        "longitude": 140.886306
      },
      {
        "name": "黒川郡大郷町",
        "latitude": 38.42426,
        "longitude": 141.004445
      },
      {
        "name": "黒川郡富谷町",
        "latitude": 38.399815,
        "longitude": 140.895408
      },
      {
        "name": "黒川郡大衡村",
        "latitude": 38.467226,
        "longitude": 140.880087
      },
      {
        "name": "加美郡色麻町",
        "latitude": 38.54892,
        "longitude": 140.849878
      },
      {
        "name": "加美郡加美町",
        "latitude": 38.571767,
        "longitude": 140.854751
      },
      {
        "name": "遠田郡涌谷町",
        "latitude": 38.539646,
        "longitude": 141.128075
      },
      {
        "name": "遠田郡美里町",
        "latitude": 38.544391,
        "longitude": 141.056695
      },
      {
        "name": "牡鹿郡女川町",
        "latitude": 38.449733,
        "longitude": 141.443088
      },
      {
        "name": "本吉郡南三陸町",
        "latitude": 38.679771,
        "longitude": 141.460514
      }
    ]
  },
  {
    "name": "秋田県",
    "cities": [{
        "name": "秋田市",
        "latitude": "39.4322",
        "longitude": "140.061"
      },
      {
        "name": "能代市",
        "latitude": "40.1241",
        "longitude": "140.0127"
      },
      {
        "name": "横手市",
        "latitude": "39.1853",
        "longitude": "140.3411"
      },
      {
        "name": "大館市",
        "latitude": "40.1622",
        "longitude": "140.3352"
      },
      {
        "name": "本荘市",
        "latitude": "39.2305",
        "longitude": "140.0247"
      },
      {
        "name": "男鹿市",
        "latitude": "39.5312",
        "longitude": "139.5041"
      },
      {
        "name": "湯沢市",
        "latitude": "39.0941",
        "longitude": "140.2947"
      },
      {
        "name": "大曲市",
        "latitude": "39.2711",
        "longitude": "140.2839"
      },
      {
        "name": "鹿角市",
        "latitude": "40.1246",
        "longitude": "140.47"
      },
      {
        "name": "鹿角郡",
        "latitude": "40.221",
        "longitude": "140.4448"
      },
      {
        "name": "小坂町",
        "latitude": "40.1916",
        "longitude": "140.4458"
      },
      {
        "name": "北秋田郡",
        "latitude": "40.111",
        "longitude": "140.2748"
      },
      {
        "name": "鷹巣町",
        "latitude": "40.1343",
        "longitude": "140.2247"
      },
      {
        "name": "比内町",
        "latitude": "40.1259",
        "longitude": "140.3521"
      },
      {
        "name": "森吉町",
        "latitude": "40.0645",
        "longitude": "140.2309"
      },
      {
        "name": "阿仁町",
        "latitude": "39.5914",
        "longitude": "140.242"
      },
      {
        "name": "田代町",
        "latitude": "40.1553",
        "longitude": "140.2652"
      },
      {
        "name": "合川町",
        "latitude": "40.0911",
        "longitude": "140.1949"
      },
      {
        "name": "上小阿仁村",
        "latitude": "40.0326",
        "longitude": "140.1755"
      },
      {
        "name": "山本郡",
        "latitude": "40.151",
        "longitude": "140.0448"
      },
      {
        "name": "琴丘町",
        "latitude": "40.0233",
        "longitude": "140.0458"
      },
      {
        "name": "二ツ井町",
        "latitude": "40.1221",
        "longitude": "140.1414"
      },
      {
        "name": "八森町",
        "latitude": "40.2205",
        "longitude": "140.0034"
      },
      {
        "name": "山本町",
        "latitude": "40.0626",
        "longitude": "140.0432"
      },
      {
        "name": "八竜町",
        "latitude": "40.061",
        "longitude": "140.0048"
      },
      {
        "name": "藤里町",
        "latitude": "40.1621",
        "longitude": "140.1552"
      },
      {
        "name": "峰浜村",
        "latitude": "40.1715",
        "longitude": "140.0239"
      },
      {
        "name": "南秋田郡",
        "latitude": "40.001",
        "longitude": "140.0048"
      },
      {
        "name": "五城目町",
        "latitude": "39.5626",
        "longitude": "140.065"
      },
      {
        "name": "昭和町",
        "latitude": "39.515",
        "longitude": "140.0403"
      },
      {
        "name": "八郎潟町",
        "latitude": "39.5651",
        "longitude": "140.0427"
      },
      {
        "name": "飯田川町",
        "latitude": "39.5308",
        "longitude": "140.0434"
      },
      {
        "name": "天王町",
        "latitude": "39.5308",
        "longitude": "139.5922"
      },
      {
        "name": "井川町",
        "latitude": "39.5438",
        "longitude": "140.0528"
      },
      {
        "name": "若美町",
        "latitude": "39.581",
        "longitude": "139.5448"
      },
      {
        "name": "大潟村",
        "latitude": "40.01",
        "longitude": "139.5745"
      },
      {
        "name": "河辺郡",
        "latitude": "39.3711",
        "longitude": "140.0948"
      },
      {
        "name": "河辺町",
        "latitude": "39.39",
        "longitude": "140.1314"
      },
      {
        "name": "雄和町",
        "latitude": "39.3526",
        "longitude": "140.1016"
      },
      {
        "name": "由利郡",
        "latitude": "39.211",
        "longitude": "140.1048"
      },
      {
        "name": "仁賀保町",
        "latitude": "39.1657",
        "longitude": "139.5744"
      },
      {
        "name": "金浦町",
        "latitude": "39.1448",
        "longitude": "139.5536"
      },
      {
        "name": "象潟町",
        "latitude": "39.1209",
        "longitude": "139.5429"
      },
      {
        "name": "矢島町",
        "latitude": "39.1335",
        "longitude": "140.0756"
      },
      {
        "name": "岩城町",
        "latitude": "39.3311",
        "longitude": "140.0318"
      },
      {
        "name": "由利町",
        "latitude": "39.1836",
        "longitude": "140.0627"
      },
      {
        "name": "西目町",
        "latitude": "39.201",
        "longitude": "140.0048"
      },
      {
        "name": "鳥海町",
        "latitude": "39.111",
        "longitude": "140.1148"
      },
      {
        "name": "東由利町",
        "latitude": "39.1808",
        "longitude": "140.1722"
      },
      {
        "name": "大内町",
        "latitude": "39.2624",
        "longitude": "140.0547"
      },
      {
        "name": "仙北郡",
        "latitude": "39.381",
        "longitude": "140.2848"
      },
      {
        "name": "神岡町",
        "latitude": "39.2913",
        "longitude": "140.2541"
      },
      {
        "name": "西仙北町",
        "latitude": "39.3218",
        "longitude": "140.2231"
      },
      {
        "name": "角館町",
        "latitude": "39.3542",
        "longitude": "140.3341"
      },
      {
        "name": "六郷町",
        "latitude": "39.261",
        "longitude": "140.3348"
      },
      {
        "name": "中仙町",
        "latitude": "39.324",
        "longitude": "140.3252"
      },
      {
        "name": "田沢湖町",
        "latitude": "39.4141",
        "longitude": "140.4348"
      },
      {
        "name": "協和町",
        "latitude": "39.3641",
        "longitude": "140.1915"
      },
      {
        "name": "南外村",
        "latitude": "39.2658",
        "longitude": "140.2209"
      },
      {
        "name": "仙北町",
        "latitude": "39.275",
        "longitude": "140.3037"
      },
      {
        "name": "西木村",
        "latitude": "39.3853",
        "longitude": "140.343"
      },
      {
        "name": "太田町",
        "latitude": "39.3049",
        "longitude": "140.3611"
      },
      {
        "name": "千畑町",
        "latitude": "39.2738",
        "longitude": "140.3517"
      },
      {
        "name": "仙南村",
        "latitude": "39.2304",
        "longitude": "140.3224"
      },
      {
        "name": "平鹿郡",
        "latitude": "39.1911",
        "longitude": "140.3548"
      },
      {
        "name": "増田町",
        "latitude": "39.1159",
        "longitude": "140.3249"
      },
      {
        "name": "平鹿町",
        "latitude": "39.155",
        "longitude": "140.2926"
      },
      {
        "name": "雄物川町",
        "latitude": "39.1719",
        "longitude": "140.2554"
      },
      {
        "name": "大森町",
        "latitude": "39.203",
        "longitude": "140.2553"
      },
      {
        "name": "十文字町",
        "latitude": "39.131",
        "longitude": "140.3114"
      },
      {
        "name": "山内村",
        "latitude": "39.1611",
        "longitude": "140.3748"
      },
      {
        "name": "大雄村",
        "latitude": "39.1949",
        "longitude": "140.2828"
      },
      {
        "name": "雄勝郡",
        "latitude": "39.081",
        "longitude": "140.2848"
      },
      {
        "name": "稲川町",
        "latitude": "39.083",
        "longitude": "140.3433"
      },
      {
        "name": "雄勝町",
        "latitude": "39.0342",
        "longitude": "140.2722"
      },
      {
        "name": "羽後町",
        "latitude": "39.1141",
        "longitude": "140.2504"
      },
      {
        "name": "東成瀬村",
        "latitude": "39.1033",
        "longitude": "140.3856"
      },
      {
        "name": "皆瀬村",
        "latitude": "39.0407",
        "longitude": "140.3522"
      }
    ]
  },
  {
    "name": "山形県",
    "cities": [{
        "name": "山形市",
        "latitude": 38.255439,
        "longitude": 140.339602
      },
      {
        "name": "米沢市",
        "latitude": 37.92224,
        "longitude": 140.116781
      },
      {
        "name": "鶴岡市",
        "latitude": 38.727197,
        "longitude": 139.826731
      },
      {
        "name": "酒田市",
        "latitude": 38.914433,
        "longitude": 139.836515
      },
      {
        "name": "新庄市",
        "latitude": 38.765015,
        "longitude": 140.301608
      },
      {
        "name": "寒河江市",
        "latitude": 38.380964,
        "longitude": 140.275967
      },
      {
        "name": "上山市",
        "latitude": 38.149562,
        "longitude": 140.267827
      },
      {
        "name": "村山市",
        "latitude": 38.483344,
        "longitude": 140.380372
      },
      {
        "name": "長井市",
        "latitude": 38.107494,
        "longitude": 140.040524
      },
      {
        "name": "天童市",
        "latitude": 38.362265,
        "longitude": 140.377886
      },
      {
        "name": "東根市",
        "latitude": 38.431354,
        "longitude": 140.39109
      },
      {
        "name": "尾花沢市",
        "latitude": 38.600622,
        "longitude": 140.405691
      },
      {
        "name": "南陽市",
        "latitude": 38.055141,
        "longitude": 140.148375
      },
      {
        "name": "東村山郡山辺町",
        "latitude": 38.289114,
        "longitude": 140.262178
      },
      {
        "name": "東村山郡中山町",
        "latitude": 38.333114,
        "longitude": 140.283034
      },
      {
        "name": "西村山郡河北町",
        "latitude": 38.426271,
        "longitude": 140.314284
      },
      {
        "name": "西村山郡西川町",
        "latitude": 38.426499,
        "longitude": 140.147347
      },
      {
        "name": "西村山郡朝日町",
        "latitude": 38.299248,
        "longitude": 140.145926
      },
      {
        "name": "西村山郡大江町",
        "latitude": 38.380648,
        "longitude": 140.207013
      },
      {
        "name": "北村山郡大石田町",
        "latitude": 38.593863,
        "longitude": 140.37268
      },
      {
        "name": "最上郡金山町",
        "latitude": 38.883357,
        "longitude": 140.339456
      },
      {
        "name": "最上郡最上町",
        "latitude": 38.758387,
        "longitude": 140.519354
      },
      {
        "name": "最上郡舟形町",
        "latitude": 38.691444,
        "longitude": 140.319776
      },
      {
        "name": "最上郡真室川町",
        "latitude": 38.857859,
        "longitude": 140.252301
      },
      {
        "name": "最上郡大蔵村",
        "latitude": 38.704182,
        "longitude": 140.230374
      },
      {
        "name": "最上郡鮭川村",
        "latitude": 38.796457,
        "longitude": 140.222052
      },
      {
        "name": "最上郡戸沢村",
        "latitude": 38.737473,
        "longitude": 140.143804
      },
      {
        "name": "東置賜郡高畠町",
        "latitude": 38.002736,
        "longitude": 140.18916
      },
      {
        "name": "東置賜郡川西町",
        "latitude": 38.004479,
        "longitude": 140.04579
      },
      {
        "name": "西置賜郡小国町",
        "latitude": 38.061627,
        "longitude": 139.743364
      },
      {
        "name": "西置賜郡白鷹町",
        "latitude": 38.183095,
        "longitude": 140.098438
      },
      {
        "name": "西置賜郡飯豊町",
        "latitude": 38.045827,
        "longitude": 139.987529
      },
      {
        "name": "東田川郡三川町",
        "latitude": 38.794487,
        "longitude": 139.849696
      },
      {
        "name": "東田川郡庄内町",
        "latitude": 38.849915,
        "longitude": 139.904745
      },
      {
        "name": "飽海郡遊佐町",
        "latitude": 39.014616,
        "longitude": 139.907352
      }
    ]
  },
  {
    "name": "福島県",
    "cities": [{
        "name": "福島市",
        "latitude": 37.760834,
        "longitude": 140.474728
      },
      {
        "name": "会津若松市",
        "latitude": 37.494761,
        "longitude": 139.92981
      },
      {
        "name": "郡山市",
        "latitude": 37.400529,
        "longitude": 140.359742
      },
      {
        "name": "いわき市",
        "latitude": 37.05042,
        "longitude": 140.887682
      },
      {
        "name": "白河市",
        "latitude": 37.126362,
        "longitude": 140.210943
      },
      {
        "name": "須賀川市",
        "latitude": 37.292893,
        "longitude": 140.356975
      },
      {
        "name": "喜多方市",
        "latitude": 37.651355,
        "longitude": 139.874609
      },
      {
        "name": "相馬市",
        "latitude": 37.796624,
        "longitude": 140.91947
      },
      {
        "name": "二本松市",
        "latitude": 37.584858,
        "longitude": 140.431129
      },
      {
        "name": "田村市",
        "latitude": 37.44055,
        "longitude": 140.576422
      },
      {
        "name": "南相馬市",
        "latitude": 37.642161,
        "longitude": 140.957276
      },
      {
        "name": "伊達市",
        "latitude": 37.819138,
        "longitude": 140.562908
      },
      {
        "name": "本宮市",
        "latitude": 37.513131,
        "longitude": 140.39389
      },
      {
        "name": "伊達郡桑折町",
        "latitude": 37.849537,
        "longitude": 140.516352
      },
      {
        "name": "伊達郡国見町",
        "latitude": 37.837,
        "longitude": 140.549185
      },
      {
        "name": "伊達郡川俣町",
        "latitude": 37.663922,
        "longitude": 140.597333
      },
      {
        "name": "安達郡大玉村",
        "latitude": 37.534392,
        "longitude": 140.371189
      },
      {
        "name": "岩瀬郡鏡石町",
        "latitude": 37.25283,
        "longitude": 140.343408
      },
      {
        "name": "岩瀬郡天栄村",
        "latitude": 37.255606,
        "longitude": 140.247074
      },
      {
        "name": "南会津郡下郷町",
        "latitude": 37.25558,
        "longitude": 139.872128
      },
      {
        "name": "南会津郡檜枝岐村",
        "latitude": 37.024146,
        "longitude": 139.388945
      },
      {
        "name": "南会津郡只見町",
        "latitude": 37.348801,
        "longitude": 139.316091
      },
      {
        "name": "南会津郡南会津町",
        "latitude": 37.200395,
        "longitude": 139.773199
      },
      {
        "name": "耶麻郡北塩原村",
        "latitude": 37.655715,
        "longitude": 139.937374
      },
      {
        "name": "耶麻郡西会津町",
        "latitude": 37.588798,
        "longitude": 139.647495
      },
      {
        "name": "耶麻郡磐梯町",
        "latitude": 37.562115,
        "longitude": 139.98878
      },
      {
        "name": "耶麻郡猪苗代町",
        "latitude": 37.557768,
        "longitude": 140.104761
      },
      {
        "name": "河沼郡会津坂下町",
        "latitude": 37.561481,
        "longitude": 139.821643
      },
      {
        "name": "河沼郡湯川村",
        "latitude": 37.565299,
        "longitude": 139.886778
      },
      {
        "name": "河沼郡柳津町",
        "latitude": 37.52616,
        "longitude": 139.719542
      },
      {
        "name": "大沼郡三島町",
        "latitude": 37.470369,
        "longitude": 139.644447
      },
      {
        "name": "大沼郡金山町",
        "latitude": 37.453851,
        "longitude": 139.524298
      },
      {
        "name": "大沼郡昭和村",
        "latitude": 37.335416,
        "longitude": 139.61066
      },
      {
        "name": "大沼郡会津美里町",
        "latitude": 37.459854,
        "longitude": 139.841178
      },
      {
        "name": "西白河郡西郷村",
        "latitude": 37.141696,
        "longitude": 140.155364
      },
      {
        "name": "西白河郡泉崎村",
        "latitude": 37.157052,
        "longitude": 140.295252
      },
      {
        "name": "西白河郡中島村",
        "latitude": 37.148757,
        "longitude": 140.350204
      },
      {
        "name": "西白河郡矢吹町",
        "latitude": 37.201278,
        "longitude": 140.338608
      },
      {
        "name": "東白川郡棚倉町",
        "latitude": 37.029905,
        "longitude": 140.379648
      },
      {
        "name": "東白川郡矢祭町",
        "latitude": 36.871403,
        "longitude": 140.424783
      },
      {
        "name": "東白川郡塙町",
        "latitude": 36.957437,
        "longitude": 140.409573
      },
      {
        "name": "東白川郡鮫川村",
        "latitude": 37.042327,
        "longitude": 140.509669
      },
      {
        "name": "石川郡石川町",
        "latitude": 37.14433,
        "longitude": 140.452351
      },
      {
        "name": "石川郡玉川村",
        "latitude": 37.210706,
        "longitude": 140.409038
      },
      {
        "name": "石川郡平田村",
        "latitude": 37.221786,
        "longitude": 140.575876
      },
      {
        "name": "石川郡浅川町",
        "latitude": 37.080995,
        "longitude": 140.412809
      },
      {
        "name": "石川郡古殿町",
        "latitude": 37.089093,
        "longitude": 140.555649
      },
      {
        "name": "田村郡三春町",
        "latitude": 37.441048,
        "longitude": 140.492569
      },
      {
        "name": "田村郡小野町",
        "latitude": 37.286823,
        "longitude": 140.626277
      },
      {
        "name": "双葉郡広野町",
        "latitude": 37.214559,
        "longitude": 140.994458
      },
      {
        "name": "双葉郡楢葉町",
        "latitude": 37.2825,
        "longitude": 140.993611
      },
      {
        "name": "双葉郡富岡町",
        "latitude": 37.333056,
        "longitude": 141.016944
      },
      {
        "name": "双葉郡川内村",
        "latitude": 37.337691,
        "longitude": 140.809345
      },
      {
        "name": "双葉郡大熊町",
        "latitude": 37.404444,
        "longitude": 140.983611
      },
      {
        "name": "双葉郡双葉町",
        "latitude": 37.449167,
        "longitude": 141.0125
      },
      {
        "name": "双葉郡浪江町",
        "latitude": 37.483333,
        "longitude": 141
      },
      {
        "name": "双葉郡葛尾村",
        "latitude": 37.503611,
        "longitude": 140.764444
      },
      {
        "name": "相馬郡新地町",
        "latitude": 37.876357,
        "longitude": 140.919599
      },
      {
        "name": "相馬郡飯舘村",
        "latitude": 37.67919,
        "longitude": 140.735247
      }
    ]
  },
  {
    "name": "茨城県",
    "cities": [{
        "name": "水戸市",
        "latitude": 36.366503,
        "longitude": 140.470996
      },
      {
        "name": "日立市",
        "latitude": 36.599154,
        "longitude": 140.651469
      },
      {
        "name": "土浦市",
        "latitude": 36.078297,
        "longitude": 140.204333
      },
      {
        "name": "古河市",
        "latitude": 36.184645,
        "longitude": 139.702525
      },
      {
        "name": "石岡市",
        "latitude": 36.190048,
        "longitude": 140.287556
      },
      {
        "name": "結城市",
        "latitude": 36.305417,
        "longitude": 139.876641
      },
      {
        "name": "龍ヶ崎市",
        "latitude": 35.911594,
        "longitude": 140.182264
      },
      {
        "name": "下妻市",
        "latitude": 36.184514,
        "longitude": 139.967374
      },
      {
        "name": "常総市",
        "latitude": 36.023494,
        "longitude": 139.993617
      },
      {
        "name": "常陸太田市",
        "latitude": 36.538275,
        "longitude": 140.53095
      },
      {
        "name": "高萩市",
        "latitude": 36.719147,
        "longitude": 140.716688
      },
      {
        "name": "北茨城市",
        "latitude": 36.801911,
        "longitude": 140.750979
      },
      {
        "name": "笠間市",
        "latitude": 36.3453,
        "longitude": 140.304354
      },
      {
        "name": "取手市",
        "latitude": 35.911442,
        "longitude": 140.050468
      },
      {
        "name": "牛久市",
        "latitude": 35.979375,
        "longitude": 140.149524
      },
      {
        "name": "つくば市",
        "latitude": 36.083486,
        "longitude": 140.076642
      },
      {
        "name": "ひたちなか市",
        "latitude": 36.396615,
        "longitude": 140.534676
      },
      {
        "name": "鹿嶋市",
        "latitude": 35.965756,
        "longitude": 140.644813
      },
      {
        "name": "潮来市",
        "latitude": 35.947123,
        "longitude": 140.555372
      },
      {
        "name": "守谷市",
        "latitude": 35.951421,
        "longitude": 139.975391
      },
      {
        "name": "常陸大宮市",
        "latitude": 36.542577,
        "longitude": 140.41089
      },
      {
        "name": "那珂市",
        "latitude": 36.457469,
        "longitude": 140.486854
      },
      {
        "name": "筑西市",
        "latitude": 36.307053,
        "longitude": 139.983123
      },
      {
        "name": "坂東市",
        "latitude": 36.048738,
        "longitude": 139.88832
      },
      {
        "name": "稲敷市",
        "latitude": 35.956492,
        "longitude": 140.323908
      },
      {
        "name": "かすみがうら市",
        "latitude": 36.094529,
        "longitude": 140.330691
      },
      {
        "name": "桜川市",
        "latitude": 36.32725,
        "longitude": 140.090433
      },
      {
        "name": "神栖市",
        "latitude": 35.889966,
        "longitude": 140.664513
      },
      {
        "name": "行方市",
        "latitude": 35.990416,
        "longitude": 140.489025
      },
      {
        "name": "鉾田市",
        "latitude": 36.15864,
        "longitude": 140.516461
      },
      {
        "name": "つくばみらい市",
        "latitude": 35.963024,
        "longitude": 140.03702
      },
      {
        "name": "小美玉市",
        "latitude": 36.239345,
        "longitude": 140.352576
      },
      {
        "name": "東茨城郡茨城町",
        "latitude": 36.286921,
        "longitude": 140.424514
      },
      {
        "name": "東茨城郡大洗町",
        "latitude": 36.313443,
        "longitude": 140.574974
      },
      {
        "name": "東茨城郡城里町",
        "latitude": 36.479363,
        "longitude": 140.376166
      },
      {
        "name": "那珂郡東海村",
        "latitude": 36.47288,
        "longitude": 140.566299
      },
      {
        "name": "久慈郡大子町",
        "latitude": 36.768096,
        "longitude": 140.355266
      },
      {
        "name": "稲敷郡美浦村",
        "latitude": 36.004562,
        "longitude": 140.301914
      },
      {
        "name": "稲敷郡阿見町",
        "latitude": 36.030795,
        "longitude": 140.214786
      },
      {
        "name": "稲敷郡河内町",
        "latitude": 35.884794,
        "longitude": 140.244465
      },
      {
        "name": "結城郡八千代町",
        "latitude": 36.181635,
        "longitude": 139.891129
      },
      {
        "name": "猿島郡五霞町",
        "latitude": 36.114113,
        "longitude": 139.745832
      },
      {
        "name": "猿島郡境町",
        "latitude": 36.108426,
        "longitude": 139.794961
      },
      {
        "name": "北相馬郡利根町",
        "latitude": 35.857747,
        "longitude": 140.139148
      }
    ]
  },
  {
    "name": "栃木県",
    "cities": [{
        "name": "宇都宮市",
        "latitude": 36.555112,
        "longitude": 139.882807
      },
      {
        "name": "足利市",
        "latitude": 36.340151,
        "longitude": 139.449698
      },
      {
        "name": "栃木市",
        "latitude": 36.38238,
        "longitude": 139.734081
      },
      {
        "name": "佐野市",
        "latitude": 36.308578,
        "longitude": 139.593118
      },
      {
        "name": "鹿沼市",
        "latitude": 36.567111,
        "longitude": 139.745013
      },
      {
        "name": "日光市",
        "latitude": 36.719903,
        "longitude": 139.698216
      },
      {
        "name": "小山市",
        "latitude": 36.314671,
        "longitude": 139.800131
      },
      {
        "name": "真岡市",
        "latitude": 36.440419,
        "longitude": 140.013442
      },
      {
        "name": "大田原市",
        "latitude": 36.871484,
        "longitude": 140.017419
      },
      {
        "name": "矢板市",
        "latitude": 36.806686,
        "longitude": 139.924054
      },
      {
        "name": "那須塩原市",
        "latitude": 36.961698,
        "longitude": 140.046066
      },
      {
        "name": "さくら市",
        "latitude": 36.685301,
        "longitude": 139.966452
      },
      {
        "name": "那須烏山市",
        "latitude": 36.656893,
        "longitude": 140.151426
      },
      {
        "name": "下野市",
        "latitude": 36.387195,
        "longitude": 139.841967
      },
      {
        "name": "河内郡上三川町",
        "latitude": 36.439296,
        "longitude": 139.909884
      },
      {
        "name": "芳賀郡益子町",
        "latitude": 36.467353,
        "longitude": 140.093378
      },
      {
        "name": "芳賀郡茂木町",
        "latitude": 36.53212,
        "longitude": 140.187565
      },
      {
        "name": "芳賀郡市貝町",
        "latitude": 36.543308,
        "longitude": 140.102046
      },
      {
        "name": "芳賀郡芳賀町",
        "latitude": 36.548323,
        "longitude": 140.058196
      },
      {
        "name": "下都賀郡壬生町",
        "latitude": 36.427058,
        "longitude": 139.803932
      },
      {
        "name": "下都賀郡野木町",
        "latitude": 36.233251,
        "longitude": 139.74075
      },
      {
        "name": "塩谷郡塩谷町",
        "latitude": 36.777613,
        "longitude": 139.85057
      },
      {
        "name": "塩谷郡高根沢町",
        "latitude": 36.630973,
        "longitude": 139.986684
      },
      {
        "name": "那須郡那須町",
        "latitude": 37.019731,
        "longitude": 140.121017
      },
      {
        "name": "那須郡那珂川町",
        "latitude": 36.761661,
        "longitude": 140.130072
      }
    ]
  },
  {
    "name": "群馬県",
    "cities": [{
        "name": "前橋市",
        "latitude": 36.389482,
        "longitude": 139.063428
      },
      {
        "name": "高崎市",
        "latitude": 36.321909,
        "longitude": 139.003294
      },
      {
        "name": "桐生市",
        "latitude": 36.405491,
        "longitude": 139.330688
      },
      {
        "name": "伊勢崎市",
        "latitude": 36.311364,
        "longitude": 139.19677
      },
      {
        "name": "太田市",
        "latitude": 36.291122,
        "longitude": 139.375471
      },
      {
        "name": "沼田市",
        "latitude": 36.646077,
        "longitude": 139.044161
      },
      {
        "name": "館林市",
        "latitude": 36.244849,
        "longitude": 139.541968
      },
      {
        "name": "渋川市",
        "latitude": 36.489471,
        "longitude": 139.000448
      },
      {
        "name": "藤岡市",
        "latitude": 36.258477,
        "longitude": 139.074439
      },
      {
        "name": "富岡市",
        "latitude": 36.259896,
        "longitude": 138.889932
      },
      {
        "name": "安中市",
        "latitude": 36.326368,
        "longitude": 138.887324
      },
      {
        "name": "みどり市",
        "latitude": 36.394838,
        "longitude": 139.281135
      },
      {
        "name": "北群馬郡榛東村",
        "latitude": 36.438642,
        "longitude": 138.967118
      },
      {
        "name": "北群馬郡吉岡町",
        "latitude": 36.447501,
        "longitude": 139.009766
      },
      {
        "name": "多野郡上野村",
        "latitude": 36.083143,
        "longitude": 138.777376
      },
      {
        "name": "多野郡神流町",
        "latitude": 36.116056,
        "longitude": 138.916955
      },
      {
        "name": "甘楽郡下仁田町",
        "latitude": 36.21239,
        "longitude": 138.789094
      },
      {
        "name": "甘楽郡南牧村",
        "latitude": 36.158548,
        "longitude": 138.711406
      },
      {
        "name": "甘楽郡甘楽町",
        "latitude": 36.242954,
        "longitude": 138.921682
      },
      {
        "name": "吾妻郡中之条町",
        "latitude": 36.589893,
        "longitude": 138.840986
      },
      {
        "name": "吾妻郡長野原町",
        "latitude": 36.552446,
        "longitude": 138.637477
      },
      {
        "name": "吾妻郡嬬恋村",
        "latitude": 36.516874,
        "longitude": 138.530182
      },
      {
        "name": "吾妻郡草津町",
        "latitude": 36.620778,
        "longitude": 138.596053
      },
      {
        "name": "吾妻郡高山村",
        "latitude": 36.620887,
        "longitude": 138.943449
      },
      {
        "name": "吾妻郡東吾妻町",
        "latitude": 36.571347,
        "longitude": 138.825472
      },
      {
        "name": "利根郡片品村",
        "latitude": 36.772469,
        "longitude": 139.225243
      },
      {
        "name": "利根郡川場村",
        "latitude": 36.694669,
        "longitude": 139.106534
      },
      {
        "name": "利根郡昭和村",
        "latitude": 36.639753,
        "longitude": 139.065865
      },
      {
        "name": "利根郡みなかみ町",
        "latitude": 36.6787,
        "longitude": 138.999064
      },
      {
        "name": "佐波郡玉村町",
        "latitude": 36.304433,
        "longitude": 139.114913
      },
      {
        "name": "邑楽郡板倉町",
        "latitude": 36.222952,
        "longitude": 139.610271
      },
      {
        "name": "邑楽郡明和町",
        "latitude": 36.2113,
        "longitude": 139.534244
      },
      {
        "name": "邑楽郡千代田町",
        "latitude": 36.217754,
        "longitude": 139.442435
      },
      {
        "name": "邑楽郡大泉町",
        "latitude": 36.247902,
        "longitude": 139.40487
      },
      {
        "name": "邑楽郡邑楽町",
        "latitude": 36.252394,
        "longitude": 139.462286
      }
    ]
  },
  {
    "name": "埼玉県",
    "cities": [{
        "name": "さいたま市西区",
        "latitude": 35.925302,
        "longitude": 139.579472
      },
      {
        "name": "さいたま市北区",
        "latitude": 35.931522,
        "longitude": 139.620346
      },
      {
        "name": "さいたま市大宮区",
        "latitude": 35.906487,
        "longitude": 139.628754
      },
      {
        "name": "さいたま市見沼区",
        "latitude": 35.935206,
        "longitude": 139.654526
      },
      {
        "name": "さいたま市中央区",
        "latitude": 35.884096,
        "longitude": 139.626102
      },
      {
        "name": "さいたま市桜区",
        "latitude": 35.85614,
        "longitude": 139.610352
      },
      {
        "name": "さいたま市浦和区",
        "latitude": 35.861651,
        "longitude": 139.645435
      },
      {
        "name": "さいたま市南区",
        "latitude": 35.845253,
        "longitude": 139.645352
      },
      {
        "name": "さいたま市緑区",
        "latitude": 35.871087,
        "longitude": 139.68397
      },
      {
        "name": "さいたま市岩槻区",
        "latitude": 35.949861,
        "longitude": 139.694191
      },
      {
        "name": "川越市",
        "latitude": 35.925101,
        "longitude": 139.485793
      },
      {
        "name": "熊谷市",
        "latitude": 36.14731,
        "longitude": 139.388645
      },
      {
        "name": "川口市",
        "latitude": 35.807738,
        "longitude": 139.724171
      },
      {
        "name": "行田市",
        "latitude": 36.138949,
        "longitude": 139.455643
      },
      {
        "name": "秩父市",
        "latitude": 35.992055,
        "longitude": 139.084817
      },
      {
        "name": "所沢市",
        "latitude": 35.799672,
        "longitude": 139.46861
      },
      {
        "name": "飯能市",
        "latitude": 35.855731,
        "longitude": 139.327734
      },
      {
        "name": "加須市",
        "latitude": 36.131438,
        "longitude": 139.601719
      },
      {
        "name": "本庄市",
        "latitude": 36.243568,
        "longitude": 139.190393
      },
      {
        "name": "東松山市",
        "latitude": 36.042126,
        "longitude": 139.399959
      },
      {
        "name": "春日部市",
        "latitude": 35.975198,
        "longitude": 139.752301
      },
      {
        "name": "狭山市",
        "latitude": 35.852942,
        "longitude": 139.412213
      },
      {
        "name": "羽生市",
        "latitude": 36.172626,
        "longitude": 139.548465
      },
      {
        "name": "鴻巣市",
        "latitude": 36.065758,
        "longitude": 139.522169
      },
      {
        "name": "深谷市",
        "latitude": 36.197444,
        "longitude": 139.281464
      },
      {
        "name": "上尾市",
        "latitude": 35.977381,
        "longitude": 139.593206
      },
      {
        "name": "草加市",
        "latitude": 35.825355,
        "longitude": 139.805402
      },
      {
        "name": "越谷市",
        "latitude": 35.891084,
        "longitude": 139.790938
      },
      {
        "name": "蕨市",
        "latitude": 35.825634,
        "longitude": 139.679708
      },
      {
        "name": "戸田市",
        "latitude": 35.817616,
        "longitude": 139.677892
      },
      {
        "name": "入間市",
        "latitude": 35.835766,
        "longitude": 139.391058
      },
      {
        "name": "朝霞市",
        "latitude": 35.797252,
        "longitude": 139.593916
      },
      {
        "name": "志木市",
        "latitude": 35.83675,
        "longitude": 139.580319
      },
      {
        "name": "和光市",
        "latitude": 35.781208,
        "longitude": 139.605793
      },
      {
        "name": "新座市",
        "latitude": 35.793511,
        "longitude": 139.565369
      },
      {
        "name": "桶川市",
        "latitude": 36.005779,
        "longitude": 139.542531
      },
      {
        "name": "久喜市",
        "latitude": 36.061995,
        "longitude": 139.666838
      },
      {
        "name": "北本市",
        "latitude": 36.026768,
        "longitude": 139.530211
      },
      {
        "name": "八潮市",
        "latitude": 35.822536,
        "longitude": 139.839175
      },
      {
        "name": "富士見市",
        "latitude": 35.856759,
        "longitude": 139.549074
      },
      {
        "name": "三郷市",
        "latitude": 35.830149,
        "longitude": 139.872275
      },
      {
        "name": "蓮田市",
        "latitude": 35.994307,
        "longitude": 139.662106
      },
      {
        "name": "坂戸市",
        "latitude": 35.95717,
        "longitude": 139.402905
      },
      {
        "name": "幸手市",
        "latitude": 36.078072,
        "longitude": 139.725861
      },
      {
        "name": "鶴ヶ島市",
        "latitude": 35.934515,
        "longitude": 139.393098
      },
      {
        "name": "日高市",
        "latitude": 35.907796,
        "longitude": 139.339026
      },
      {
        "name": "吉川市",
        "latitude": 35.891152,
        "longitude": 139.841375
      },
      {
        "name": "ふじみ野市",
        "latitude": 35.879538,
        "longitude": 139.519764
      },
      {
        "name": "白岡市",
        "latitude": 36.019026,
        "longitude": 139.676925
      },
      {
        "name": "北足立郡伊奈町",
        "latitude": 36.000112,
        "longitude": 139.624185
      },
      {
        "name": "入間郡三芳町",
        "latitude": 35.828367,
        "longitude": 139.526432
      },
      {
        "name": "入間郡毛呂山町",
        "latitude": 35.941501,
        "longitude": 139.316067
      },
      {
        "name": "入間郡越生町",
        "latitude": 35.964478,
        "longitude": 139.294199
      },
      {
        "name": "比企郡滑川町",
        "latitude": 36.065987,
        "longitude": 139.360917
      },
      {
        "name": "比企郡嵐山町",
        "latitude": 36.056655,
        "longitude": 139.320542
      },
      {
        "name": "比企郡小川町",
        "latitude": 36.056697,
        "longitude": 139.261845
      },
      {
        "name": "比企郡川島町",
        "latitude": 35.982014,
        "longitude": 139.481518
      },
      {
        "name": "比企郡吉見町",
        "latitude": 36.039853,
        "longitude": 139.453728
      },
      {
        "name": "比企郡鳩山町",
        "latitude": 35.981466,
        "longitude": 139.334101
      },
      {
        "name": "比企郡ときがわ町",
        "latitude": 36.00862,
        "longitude": 139.296844
      },
      {
        "name": "秩父郡横瀬町",
        "latitude": 35.987284,
        "longitude": 139.100046
      },
      {
        "name": "秩父郡皆野町",
        "latitude": 36.07084,
        "longitude": 139.098754
      },
      {
        "name": "秩父郡長瀞町",
        "latitude": 36.114803,
        "longitude": 139.109717
      },
      {
        "name": "秩父郡小鹿野町",
        "latitude": 36.017129,
        "longitude": 139.008574
      },
      {
        "name": "秩父郡東秩父村",
        "latitude": 36.058153,
        "longitude": 139.194607
      },
      {
        "name": "児玉郡美里町",
        "latitude": 36.177104,
        "longitude": 139.18141
      },
      {
        "name": "児玉郡神川町",
        "latitude": 36.213876,
        "longitude": 139.101776
      },
      {
        "name": "児玉郡上里町",
        "latitude": 36.251607,
        "longitude": 139.144826
      },
      {
        "name": "大里郡寄居町",
        "latitude": 36.118348,
        "longitude": 139.193012
      },
      {
        "name": "南埼玉郡宮代町",
        "latitude": 36.022682,
        "longitude": 139.722885
      },
      {
        "name": "北葛飾郡杉戸町",
        "latitude": 36.025765,
        "longitude": 139.736709
      },
      {
        "name": "北葛飾郡松伏町",
        "latitude": 35.925774,
        "longitude": 139.815178
      }
    ]
  },
  {
    "name": "千葉県",
    "cities": [{
        "name": "千葉市中央区",
        "latitude": 35.608932,
        "longitude": 140.124636
      },
      {
        "name": "千葉市花見川区",
        "latitude": 35.662977,
        "longitude": 140.069167
      },
      {
        "name": "千葉市稲毛区",
        "latitude": 35.636212,
        "longitude": 140.107083
      },
      {
        "name": "千葉市若葉区",
        "latitude": 35.633988,
        "longitude": 140.155661
      },
      {
        "name": "千葉市緑区",
        "latitude": 35.560459,
        "longitude": 140.176164
      },
      {
        "name": "千葉市美浜区",
        "latitude": 35.640485,
        "longitude": 140.06304
      },
      {
        "name": "銚子市",
        "latitude": 35.734681,
        "longitude": 140.826641
      },
      {
        "name": "市川市",
        "latitude": 35.721912,
        "longitude": 139.93101
      },
      {
        "name": "船橋市",
        "latitude": 35.694706,
        "longitude": 139.982618
      },
      {
        "name": "館山市",
        "latitude": 34.996506,
        "longitude": 139.869965
      },
      {
        "name": "木更津市",
        "latitude": 35.381227,
        "longitude": 139.92486
      },
      {
        "name": "松戸市",
        "latitude": 35.787649,
        "longitude": 139.903093
      },
      {
        "name": "野田市",
        "latitude": 35.955192,
        "longitude": 139.874764
      },
      {
        "name": "茂原市",
        "latitude": 35.428544,
        "longitude": 140.288027
      },
      {
        "name": "成田市",
        "latitude": 35.776608,
        "longitude": 140.318781
      },
      {
        "name": "佐倉市",
        "latitude": 35.723486,
        "longitude": 140.223964
      },
      {
        "name": "東金市",
        "latitude": 35.559944,
        "longitude": 140.366092
      },
      {
        "name": "旭市",
        "latitude": 35.720434,
        "longitude": 140.646469
      },
      {
        "name": "習志野市",
        "latitude": 35.683035,
        "longitude": 140.024365
      },
      {
        "name": "柏市",
        "latitude": 35.867612,
        "longitude": 139.97585
      },
      {
        "name": "勝浦市",
        "latitude": 35.152283,
        "longitude": 140.320932
      },
      {
        "name": "市原市",
        "latitude": 35.497838,
        "longitude": 140.115665
      },
      {
        "name": "流山市",
        "latitude": 35.85631,
        "longitude": 139.90266
      },
      {
        "name": "八千代市",
        "latitude": 35.722468,
        "longitude": 140.099772
      },
      {
        "name": "我孫子市",
        "latitude": 35.864123,
        "longitude": 140.028225
      },
      {
        "name": "鴨川市",
        "latitude": 35.113987,
        "longitude": 140.098711
      },
      {
        "name": "鎌ケ谷市",
        "latitude": 35.77675,
        "longitude": 140.00079
      },
      {
        "name": "君津市",
        "latitude": 35.33045,
        "longitude": 139.902677
      },
      {
        "name": "富津市",
        "latitude": 35.30412,
        "longitude": 139.857105
      },
      {
        "name": "浦安市",
        "latitude": 35.653984,
        "longitude": 139.902203
      },
      {
        "name": "四街道市",
        "latitude": 35.669816,
        "longitude": 140.167925
      },
      {
        "name": "袖ケ浦市",
        "latitude": 35.429808,
        "longitude": 139.95443
      },
      {
        "name": "八街市",
        "latitude": 35.665917,
        "longitude": 140.317952
      },
      {
        "name": "印西市",
        "latitude": 35.832348,
        "longitude": 140.145815
      },
      {
        "name": "白井市",
        "latitude": 35.7915,
        "longitude": 140.056372
      },
      {
        "name": "富里市",
        "latitude": 35.726808,
        "longitude": 140.343058
      },
      {
        "name": "南房総市",
        "latitude": 35.042991,
        "longitude": 139.840128
      },
      {
        "name": "匝瑳市",
        "latitude": 35.707917,
        "longitude": 140.564281
      },
      {
        "name": "香取市",
        "latitude": 35.897735,
        "longitude": 140.499268
      },
      {
        "name": "山武市",
        "latitude": 35.602964,
        "longitude": 140.413506
      },
      {
        "name": "いすみ市",
        "latitude": 35.253889,
        "longitude": 140.385051
      },
      {
        "name": "大網白里市",
        "latitude": 35.521708,
        "longitude": 140.32096
      },
      {
        "name": "印旛郡酒々井町",
        "latitude": 35.724824,
        "longitude": 140.269547
      },
      {
        "name": "印旛郡栄町",
        "latitude": 35.840784,
        "longitude": 140.243831
      },
      {
        "name": "香取郡神崎町",
        "latitude": 35.901602,
        "longitude": 140.405285
      },
      {
        "name": "香取郡多古町",
        "latitude": 35.735434,
        "longitude": 140.467728
      },
      {
        "name": "香取郡東庄町",
        "latitude": 35.83715,
        "longitude": 140.668581
      },
      {
        "name": "山武郡九十九里町",
        "latitude": 35.535062,
        "longitude": 140.44025
      },
      {
        "name": "山武郡芝山町",
        "latitude": 35.693056,
        "longitude": 140.414194
      },
      {
        "name": "山武郡横芝光町",
        "latitude": 35.665568,
        "longitude": 140.504309
      },
      {
        "name": "長生郡一宮町",
        "latitude": 35.372623,
        "longitude": 140.368659
      },
      {
        "name": "長生郡睦沢町",
        "latitude": 35.361088,
        "longitude": 140.319273
      },
      {
        "name": "長生郡長生村",
        "latitude": 35.412184,
        "longitude": 140.35406
      },
      {
        "name": "長生郡白子町",
        "latitude": 35.45434,
        "longitude": 140.374234
      },
      {
        "name": "長生郡長柄町",
        "latitude": 35.431125,
        "longitude": 140.227059
      },
      {
        "name": "長生郡長南町",
        "latitude": 35.386421,
        "longitude": 140.237065
      },
      {
        "name": "夷隅郡大多喜町",
        "latitude": 35.285191,
        "longitude": 140.245439
      },
      {
        "name": "夷隅郡御宿町",
        "latitude": 35.191506,
        "longitude": 140.348796
      },
      {
        "name": "安房郡鋸南町",
        "latitude": 35.111159,
        "longitude": 139.835509
      }
    ]
  },
  {
    "name": "東京都",
    "cities": [{
        "name": "千代田区",
        "latitude": 35.694003,
        "longitude": 139.753595
      },
      {
        "name": "中央区",
        "latitude": 35.670651,
        "longitude": 139.771861
      },
      {
        "name": "港区",
        "latitude": 35.658068,
        "longitude": 139.751599
      },
      {
        "name": "新宿区",
        "latitude": 35.69384,
        "longitude": 139.703549
      },
      {
        "name": "文京区",
        "latitude": 35.708068,
        "longitude": 139.752167
      },
      {
        "name": "台東区",
        "latitude": 35.712607,
        "longitude": 139.779996
      },
      {
        "name": "墨田区",
        "latitude": 35.710722,
        "longitude": 139.801497
      },
      {
        "name": "江東区",
        "latitude": 35.672854,
        "longitude": 139.81741
      },
      {
        "name": "品川区",
        "latitude": 35.609226,
        "longitude": 139.730186
      },
      {
        "name": "目黒区",
        "latitude": 35.641463,
        "longitude": 139.698171
      },
      {
        "name": "大田区",
        "latitude": 35.561257,
        "longitude": 139.716051
      },
      {
        "name": "世田谷区",
        "latitude": 35.646572,
        "longitude": 139.653247
      },
      {
        "name": "渋谷区",
        "latitude": 35.661777,
        "longitude": 139.704051
      },
      {
        "name": "中野区",
        "latitude": 35.707399,
        "longitude": 139.663835
      },
      {
        "name": "杉並区",
        "latitude": 35.699566,
        "longitude": 139.636438
      },
      {
        "name": "豊島区",
        "latitude": 35.726118,
        "longitude": 139.716605
      },
      {
        "name": "北区",
        "latitude": 35.752804,
        "longitude": 139.733481
      },
      {
        "name": "荒川区",
        "latitude": 35.73608,
        "longitude": 139.783369
      },
      {
        "name": "板橋区",
        "latitude": 35.751165,
        "longitude": 139.709244
      },
      {
        "name": "練馬区",
        "latitude": 35.735623,
        "longitude": 139.651658
      },
      {
        "name": "足立区",
        "latitude": 35.775664,
        "longitude": 139.804479
      },
      {
        "name": "葛飾区",
        "latitude": 35.743575,
        "longitude": 139.84718
      },
      {
        "name": "江戸川区",
        "latitude": 35.706657,
        "longitude": 139.868427
      },
      {
        "name": "八王子市",
        "latitude": 35.666339,
        "longitude": 139.315806
      },
      {
        "name": "立川市",
        "latitude": 35.714014,
        "longitude": 139.407843
      },
      {
        "name": "武蔵野市",
        "latitude": 35.717784,
        "longitude": 139.56626
      },
      {
        "name": "三鷹市",
        "latitude": 35.683513,
        "longitude": 139.559584
      },
      {
        "name": "青梅市",
        "latitude": 35.787996,
        "longitude": 139.27583
      },
      {
        "name": "府中市",
        "latitude": 35.668974,
        "longitude": 139.477661
      },
      {
        "name": "昭島市",
        "latitude": 35.705755,
        "longitude": 139.353548
      },
      {
        "name": "調布市",
        "latitude": 35.650615,
        "longitude": 139.540694
      },
      {
        "name": "町田市",
        "latitude": 35.546631,
        "longitude": 139.438619
      },
      {
        "name": "小金井市",
        "latitude": 35.699473,
        "longitude": 139.502992
      },
      {
        "name": "小平市",
        "latitude": 35.728577,
        "longitude": 139.477456
      },
      {
        "name": "日野市",
        "latitude": 35.671337,
        "longitude": 139.394926
      },
      {
        "name": "東村山市",
        "latitude": 35.754597,
        "longitude": 139.468489
      },
      {
        "name": "国分寺市",
        "latitude": 35.710335,
        "longitude": 139.463191
      },
      {
        "name": "国立市",
        "latitude": 35.683885,
        "longitude": 139.44138
      },
      {
        "name": "福生市",
        "latitude": 35.738451,
        "longitude": 139.326932
      },
      {
        "name": "狛江市",
        "latitude": 35.634912,
        "longitude": 139.578733
      },
      {
        "name": "東大和市",
        "latitude": 35.745369,
        "longitude": 139.426593
      },
      {
        "name": "清瀬市",
        "latitude": 35.785753,
        "longitude": 139.52643
      },
      {
        "name": "東久留米市",
        "latitude": 35.758048,
        "longitude": 139.529739
      },
      {
        "name": "武蔵村山市",
        "latitude": 35.754861,
        "longitude": 139.387399
      },
      {
        "name": "多摩市",
        "latitude": 35.637004,
        "longitude": 139.446307
      },
      {
        "name": "稲城市",
        "latitude": 35.637924,
        "longitude": 139.504567
      },
      {
        "name": "羽村市",
        "latitude": 35.767222,
        "longitude": 139.310944
      },
      {
        "name": "あきる野市",
        "latitude": 35.72892,
        "longitude": 139.294119
      },
      {
        "name": "西東京市",
        "latitude": 35.725496,
        "longitude": 139.538156
      },
      {
        "name": "西多摩郡瑞穂町",
        "latitude": 35.772036,
        "longitude": 139.353846
      },
      {
        "name": "西多摩郡日の出町",
        "latitude": 35.742142,
        "longitude": 139.257444
      },
      {
        "name": "西多摩郡檜原村",
        "latitude": 35.726839,
        "longitude": 139.148866
      },
      {
        "name": "西多摩郡奥多摩町",
        "latitude": 35.809543,
        "longitude": 139.096197
      },
      {
        "name": "大島町",
        "latitude": 34.75004,
        "longitude": 139.355579
      },
      {
        "name": "利島村",
        "latitude": 34.529364,
        "longitude": 139.282585
      },
      {
        "name": "新島村",
        "latitude": 34.377134,
        "longitude": 139.256571
      },
      {
        "name": "神津島村",
        "latitude": 34.205267,
        "longitude": 139.134255
      },
      {
        "name": "三宅村",
        "latitude": 34.075774,
        "longitude": 139.479744
      },
      {
        "name": "御蔵島村",
        "latitude": 33.897366,
        "longitude": 139.596116
      },
      {
        "name": "八丈町",
        "latitude": 33.11274,
        "longitude": 139.789065
      },
      {
        "name": "青ヶ島村",
        "latitude": 32.466459,
        "longitude": 139.763312
      },
      {
        "name": "小笠原村",
        "latitude": 27.094366,
        "longitude": 142.191918
      }
    ]
  },
  {
    "name": "神奈川県",
    "cities": [{
        "name": "横浜市鶴見区",
        "latitude": 35.508404,
        "longitude": 139.68254
      },
      {
        "name": "横浜市神奈川区",
        "latitude": 35.476901,
        "longitude": 139.629431
      },
      {
        "name": "横浜市西区",
        "latitude": 35.453623,
        "longitude": 139.61696
      },
      {
        "name": "横浜市中区",
        "latitude": 35.444699,
        "longitude": 139.642178
      },
      {
        "name": "横浜市南区",
        "latitude": 35.431462,
        "longitude": 139.608837
      },
      {
        "name": "横浜市保土ケ谷区",
        "latitude": 35.459953,
        "longitude": 139.595979
      },
      {
        "name": "横浜市磯子区",
        "latitude": 35.402439,
        "longitude": 139.618433
      },
      {
        "name": "横浜市金沢区",
        "latitude": 35.33725,
        "longitude": 139.624408
      },
      {
        "name": "横浜市港北区",
        "latitude": 35.519083,
        "longitude": 139.633024
      },
      {
        "name": "横浜市戸塚区",
        "latitude": 35.400001,
        "longitude": 139.533507
      },
      {
        "name": "横浜市港南区",
        "latitude": 35.40078,
        "longitude": 139.591167
      },
      {
        "name": "横浜市旭区",
        "latitude": 35.474836,
        "longitude": 139.54472
      },
      {
        "name": "横浜市緑区",
        "latitude": 35.512396,
        "longitude": 139.538006
      },
      {
        "name": "横浜市瀬谷区",
        "latitude": 35.466026,
        "longitude": 139.49886
      },
      {
        "name": "横浜市栄区",
        "latitude": 35.364348,
        "longitude": 139.554076
      },
      {
        "name": "横浜市泉区",
        "latitude": 35.417938,
        "longitude": 139.488722
      },
      {
        "name": "横浜市青葉区",
        "latitude": 35.552824,
        "longitude": 139.537019
      },
      {
        "name": "横浜市都筑区",
        "latitude": 35.544851,
        "longitude": 139.57053
      },
      {
        "name": "川崎市川崎区",
        "latitude": 35.529667,
        "longitude": 139.703848
      },
      {
        "name": "川崎市幸区",
        "latitude": 35.544293,
        "longitude": 139.686772
      },
      {
        "name": "川崎市中原区",
        "latitude": 35.576371,
        "longitude": 139.655709
      },
      {
        "name": "川崎市高津区",
        "latitude": 35.599462,
        "longitude": 139.607938
      },
      {
        "name": "川崎市多摩区",
        "latitude": 35.619489,
        "longitude": 139.562127
      },
      {
        "name": "川崎市宮前区",
        "latitude": 35.589192,
        "longitude": 139.578469
      },
      {
        "name": "川崎市麻生区",
        "latitude": 35.60373,
        "longitude": 139.505747
      },
      {
        "name": "相模原市緑区",
        "latitude": 35.59565,
        "longitude": 139.33771
      },
      {
        "name": "相模原市中央区",
        "latitude": 35.571384,
        "longitude": 139.373246
      },
      {
        "name": "相模原市南区",
        "latitude": 35.530297,
        "longitude": 139.430195
      },
      {
        "name": "横須賀市",
        "latitude": 35.281341,
        "longitude": 139.672201
      },
      {
        "name": "平塚市",
        "latitude": 35.33511,
        "longitude": 139.349476
      },
      {
        "name": "鎌倉市",
        "latitude": 35.319225,
        "longitude": 139.546687
      },
      {
        "name": "藤沢市",
        "latitude": 35.33917,
        "longitude": 139.490036
      },
      {
        "name": "小田原市",
        "latitude": 35.264564,
        "longitude": 139.152154
      },
      {
        "name": "茅ヶ崎市",
        "latitude": 35.33386,
        "longitude": 139.404716
      },
      {
        "name": "逗子市",
        "latitude": 35.295606,
        "longitude": 139.580423
      },
      {
        "name": "三浦市",
        "latitude": 35.144171,
        "longitude": 139.62077
      },
      {
        "name": "秦野市",
        "latitude": 35.374736,
        "longitude": 139.219943
      },
      {
        "name": "厚木市",
        "latitude": 35.443082,
        "longitude": 139.362489
      },
      {
        "name": "大和市",
        "latitude": 35.487512,
        "longitude": 139.45795
      },
      {
        "name": "伊勢原市",
        "latitude": 35.402969,
        "longitude": 139.314973
      },
      {
        "name": "海老名市",
        "latitude": 35.446446,
        "longitude": 139.390803
      },
      {
        "name": "座間市",
        "latitude": 35.488587,
        "longitude": 139.407673
      },
      {
        "name": "南足柄市",
        "latitude": 35.320715,
        "longitude": 139.0996
      },
      {
        "name": "綾瀬市",
        "latitude": 35.437175,
        "longitude": 139.426148
      },
      {
        "name": "三浦郡葉山町",
        "latitude": 35.272089,
        "longitude": 139.5862
      },
      {
        "name": "高座郡寒川町",
        "latitude": 35.372956,
        "longitude": 139.383861
      },
      {
        "name": "中郡大磯町",
        "latitude": 35.30685,
        "longitude": 139.311314
      },
      {
        "name": "中郡二宮町",
        "latitude": 35.299491,
        "longitude": 139.255402
      },
      {
        "name": "足柄上郡中井町",
        "latitude": 35.330717,
        "longitude": 139.218774
      },
      {
        "name": "足柄上郡大井町",
        "latitude": 35.326674,
        "longitude": 139.156577
      },
      {
        "name": "足柄上郡松田町",
        "latitude": 35.348159,
        "longitude": 139.139375
      },
      {
        "name": "足柄上郡山北町",
        "latitude": 35.360605,
        "longitude": 139.083831
      },
      {
        "name": "足柄上郡開成町",
        "latitude": 35.336427,
        "longitude": 139.123183
      },
      {
        "name": "足柄下郡箱根町",
        "latitude": 35.232355,
        "longitude": 139.106937
      },
      {
        "name": "足柄下郡真鶴町",
        "latitude": 35.158468,
        "longitude": 139.137119
      },
      {
        "name": "足柄下郡湯河原町",
        "latitude": 35.147964,
        "longitude": 139.108317
      },
      {
        "name": "愛甲郡愛川町",
        "latitude": 35.528796,
        "longitude": 139.321717
      },
      {
        "name": "愛甲郡清川村",
        "latitude": 35.482383,
        "longitude": 139.276382
      }
    ]
  },
  {
    "name": "新潟県",
    "cities": [{
        "name": "新潟市北区",
        "latitude": 37.916243,
        "longitude": 139.218588
      },
      {
        "name": "新潟市東区",
        "latitude": 37.924871,
        "longitude": 139.092591
      },
      {
        "name": "新潟市中央区",
        "latitude": 37.916367,
        "longitude": 139.036326
      },
      {
        "name": "新潟市江南区",
        "latitude": 37.867646,
        "longitude": 139.093983
      },
      {
        "name": "新潟市秋葉区",
        "latitude": 37.788506,
        "longitude": 139.114631
      },
      {
        "name": "新潟市南区",
        "latitude": 37.765785,
        "longitude": 139.019127
      },
      {
        "name": "新潟市西区",
        "latitude": 37.874223,
        "longitude": 138.97194
      },
      {
        "name": "新潟市西蒲区",
        "latitude": 37.760464,
        "longitude": 138.889284
      },
      {
        "name": "長岡市",
        "latitude": 37.446265,
        "longitude": 138.851277
      },
      {
        "name": "三条市",
        "latitude": 37.636768,
        "longitude": 138.961689
      },
      {
        "name": "柏崎市",
        "latitude": 37.3719,
        "longitude": 138.558984
      },
      {
        "name": "新発田市",
        "latitude": 37.950886,
        "longitude": 139.327892
      },
      {
        "name": "小千谷市",
        "latitude": 37.314339,
        "longitude": 138.7951
      },
      {
        "name": "加茂市",
        "latitude": 37.666275,
        "longitude": 139.040198
      },
      {
        "name": "十日町市",
        "latitude": 37.127582,
        "longitude": 138.755708
      },
      {
        "name": "見附市",
        "latitude": 37.531604,
        "longitude": 138.912825
      },
      {
        "name": "村上市",
        "latitude": 38.223979,
        "longitude": 139.480021
      },
      {
        "name": "燕市",
        "latitude": 37.673152,
        "longitude": 138.88223
      },
      {
        "name": "糸魚川市",
        "latitude": 37.039028,
        "longitude": 137.862652
      },
      {
        "name": "妙高市",
        "latitude": 37.025196,
        "longitude": 138.253473
      },
      {
        "name": "五泉市",
        "latitude": 37.744536,
        "longitude": 139.182568
      },
      {
        "name": "上越市",
        "latitude": 37.147848,
        "longitude": 138.23608
      },
      {
        "name": "阿賀野市",
        "latitude": 37.834501,
        "longitude": 139.225983
      },
      {
        "name": "佐渡市",
        "latitude": 38.018353,
        "longitude": 138.368082
      },
      {
        "name": "魚沼市",
        "latitude": 37.244124,
        "longitude": 138.924361
      },
      {
        "name": "南魚沼市",
        "latitude": 37.161428,
        "longitude": 138.925448
      },
      {
        "name": "胎内市",
        "latitude": 38.059711,
        "longitude": 139.410342
      },
      {
        "name": "北蒲原郡聖籠町",
        "latitude": 37.974597,
        "longitude": 139.274336
      },
      {
        "name": "西蒲原郡弥彦村",
        "latitude": 37.691066,
        "longitude": 138.855249
      },
      {
        "name": "南蒲原郡田上町",
        "latitude": 37.698826,
        "longitude": 139.057982
      },
      {
        "name": "東蒲原郡阿賀町",
        "latitude": 37.675485,
        "longitude": 139.45878
      },
      {
        "name": "三島郡出雲崎町",
        "latitude": 37.530707,
        "longitude": 138.709303
      },
      {
        "name": "南魚沼郡湯沢町",
        "latitude": 36.934025,
        "longitude": 138.81744
      },
      {
        "name": "中魚沼郡津南町",
        "latitude": 37.014274,
        "longitude": 138.652622
      },
      {
        "name": "刈羽郡刈羽村",
        "latitude": 37.422359,
        "longitude": 138.622442
      },
      {
        "name": "岩船郡関川村",
        "latitude": 38.089376,
        "longitude": 139.564918
      },
      {
        "name": "岩船郡粟島浦村",
        "latitude": 38.468267,
        "longitude": 139.254439
      }
    ]
  },
  {
    "name": "富山県",
    "cities": [{
        "name": "富山市",
        "latitude": 36.695952,
        "longitude": 137.213677
      },
      {
        "name": "高岡市",
        "latitude": 36.754096,
        "longitude": 137.025728
      },
      {
        "name": "魚津市",
        "latitude": 36.827367,
        "longitude": 137.409215
      },
      {
        "name": "氷見市",
        "latitude": 36.855981,
        "longitude": 136.972849
      },
      {
        "name": "滑川市",
        "latitude": 36.764378,
        "longitude": 137.341191
      },
      {
        "name": "黒部市",
        "latitude": 36.871508,
        "longitude": 137.44803
      },
      {
        "name": "砺波市",
        "latitude": 36.647534,
        "longitude": 136.962009
      },
      {
        "name": "小矢部市",
        "latitude": 36.675575,
        "longitude": 136.868622
      },
      {
        "name": "南砺市",
        "latitude": 36.557513,
        "longitude": 136.875336
      },
      {
        "name": "射水市",
        "latitude": 36.782455,
        "longitude": 137.079614
      },
      {
        "name": "中新川郡舟橋村",
        "latitude": 36.703531,
        "longitude": 137.307377
      },
      {
        "name": "中新川郡上市町",
        "latitude": 36.698414,
        "longitude": 137.36238
      },
      {
        "name": "中新川郡立山町",
        "latitude": 36.663522,
        "longitude": 137.313697
      },
      {
        "name": "下新川郡入善町",
        "latitude": 36.933511,
        "longitude": 137.502045
      },
      {
        "name": "下新川郡朝日町",
        "latitude": 36.94619,
        "longitude": 137.559943
      }
    ]
  },
  {
    "name": "石川県",
    "cities": [{
        "name": "金沢市",
        "latitude": 36.561325,
        "longitude": 136.656205
      },
      {
        "name": "七尾市",
        "latitude": 37.043102,
        "longitude": 136.967518
      },
      {
        "name": "小松市",
        "latitude": 36.408351,
        "longitude": 136.445571
      },
      {
        "name": "輪島市",
        "latitude": 37.39059,
        "longitude": 136.899196
      },
      {
        "name": "珠洲市",
        "latitude": 37.436347,
        "longitude": 137.260436
      },
      {
        "name": "加賀市",
        "latitude": 36.302624,
        "longitude": 136.314627
      },
      {
        "name": "羽咋市",
        "latitude": 36.893599,
        "longitude": 136.778996
      },
      {
        "name": "かほく市",
        "latitude": 36.719826,
        "longitude": 136.706727
      },
      {
        "name": "白山市",
        "latitude": 36.514427,
        "longitude": 136.565892
      },
      {
        "name": "能美市",
        "latitude": 36.446941,
        "longitude": 136.554242
      },
      {
        "name": "野々市市",
        "latitude": 36.519704,
        "longitude": 136.609971
      },
      {
        "name": "能美郡川北町",
        "latitude": 36.46863,
        "longitude": 136.542304
      },
      {
        "name": "河北郡津幡町",
        "latitude": 36.668693,
        "longitude": 136.728442
      },
      {
        "name": "河北郡内灘町",
        "latitude": 36.653494,
        "longitude": 136.645086
      },
      {
        "name": "羽咋郡志賀町",
        "latitude": 37.006312,
        "longitude": 136.77801
      },
      {
        "name": "羽咋郡宝達志水町",
        "latitude": 36.862773,
        "longitude": 136.797529
      },
      {
        "name": "鹿島郡中能登町",
        "latitude": 36.988884,
        "longitude": 136.901832
      },
      {
        "name": "鳳珠郡穴水町",
        "latitude": 37.230995,
        "longitude": 136.912475
      },
      {
        "name": "鳳珠郡能登町",
        "latitude": 37.353216,
        "longitude": 137.244521
      }
    ]
  },
  {
    "name": "福井県",
    "cities": [{
        "name": "福井市",
        "latitude": 36.064067,
        "longitude": 136.219494
      },
      {
        "name": "敦賀市",
        "latitude": 35.645244,
        "longitude": 136.055441
      },
      {
        "name": "小浜市",
        "latitude": 35.495593,
        "longitude": 135.746644
      },
      {
        "name": "大野市",
        "latitude": 35.979814,
        "longitude": 136.487564
      },
      {
        "name": "勝山市",
        "latitude": 36.060945,
        "longitude": 136.500584
      },
      {
        "name": "鯖江市",
        "latitude": 35.956553,
        "longitude": 136.184474
      },
      {
        "name": "あわら市",
        "latitude": 36.211353,
        "longitude": 136.229025
      },
      {
        "name": "越前市",
        "latitude": 35.903499,
        "longitude": 136.16878
      },
      {
        "name": "坂井市",
        "latitude": 36.166925,
        "longitude": 136.231457
      },
      {
        "name": "吉田郡永平寺町",
        "latitude": 36.092256,
        "longitude": 136.298706
      },
      {
        "name": "今立郡池田町",
        "latitude": 35.890472,
        "longitude": 136.343907
      },
      {
        "name": "南条郡南越前町",
        "latitude": 35.835164,
        "longitude": 136.194465
      },
      {
        "name": "丹生郡越前町",
        "latitude": 35.974208,
        "longitude": 136.129743
      },
      {
        "name": "三方郡美浜町",
        "latitude": 35.60053,
        "longitude": 135.940543
      },
      {
        "name": "大飯郡高浜町",
        "latitude": 35.490428,
        "longitude": 135.55099
      },
      {
        "name": "大飯郡おおい町",
        "latitude": 35.481166,
        "longitude": 135.617828
      },
      {
        "name": "三方上中郡若狭町",
        "latitude": 35.461888,
        "longitude": 135.857211
      }
    ]
  },
  {
    "name": "山梨県",
    "cities": [{
        "name": "甲府市",
        "latitude": 35.66228,
        "longitude": 138.568202
      },
      {
        "name": "富士吉田市",
        "latitude": 35.487497,
        "longitude": 138.807854
      },
      {
        "name": "都留市",
        "latitude": 35.55168,
        "longitude": 138.905473
      },
      {
        "name": "山梨市",
        "latitude": 35.693394,
        "longitude": 138.686914
      },
      {
        "name": "大月市",
        "latitude": 35.610485,
        "longitude": 138.940028
      },
      {
        "name": "韮崎市",
        "latitude": 35.708897,
        "longitude": 138.446127
      },
      {
        "name": "南アルプス市",
        "latitude": 35.608328,
        "longitude": 138.464966
      },
      {
        "name": "北杜市",
        "latitude": 35.77653,
        "longitude": 138.423781
      },
      {
        "name": "甲斐市",
        "latitude": 35.686259,
        "longitude": 138.487257
      },
      {
        "name": "笛吹市",
        "latitude": 35.64736,
        "longitude": 138.639786
      },
      {
        "name": "上野原市",
        "latitude": 35.630259,
        "longitude": 139.108599
      },
      {
        "name": "甲州市",
        "latitude": 35.704316,
        "longitude": 138.729404
      },
      {
        "name": "中央市",
        "latitude": 35.599629,
        "longitude": 138.517272
      },
      {
        "name": "西八代郡市川三郷町",
        "latitude": 35.565303,
        "longitude": 138.502369
      },
      {
        "name": "南巨摩郡早川町",
        "latitude": 35.412917,
        "longitude": 138.362759
      },
      {
        "name": "南巨摩郡身延町",
        "latitude": 35.467494,
        "longitude": 138.44246
      },
      {
        "name": "南巨摩郡南部町",
        "latitude": 35.242352,
        "longitude": 138.486109
      },
      {
        "name": "南巨摩郡富士川町",
        "latitude": 35.561156,
        "longitude": 138.461384
      },
      {
        "name": "中巨摩郡昭和町",
        "latitude": 35.627926,
        "longitude": 138.535142
      },
      {
        "name": "南都留郡道志村",
        "latitude": 35.527956,
        "longitude": 139.033379
      },
      {
        "name": "南都留郡西桂町",
        "latitude": 35.524069,
        "longitude": 138.846891
      },
      {
        "name": "南都留郡忍野村",
        "latitude": 35.460098,
        "longitude": 138.847861
      },
      {
        "name": "南都留郡山中湖村",
        "latitude": 35.410645,
        "longitude": 138.860917
      },
      {
        "name": "南都留郡鳴沢村",
        "latitude": 35.481392,
        "longitude": 138.706362
      },
      {
        "name": "南都留郡富士河口湖町",
        "latitude": 35.497314,
        "longitude": 138.755084
      },
      {
        "name": "北都留郡小菅村",
        "latitude": 35.760124,
        "longitude": 138.940161
      },
      {
        "name": "北都留郡丹波山村",
        "latitude": 35.789712,
        "longitude": 138.922231
      }
    ]
  },
  {
    "name": "長野県",
    "cities": [{
        "name": "長野市",
        "latitude": 36.64855,
        "longitude": 138.194243
      },
      {
        "name": "松本市",
        "latitude": 36.238038,
        "longitude": 137.972034
      },
      {
        "name": "上田市",
        "latitude": 36.401912,
        "longitude": 138.248889
      },
      {
        "name": "岡谷市",
        "latitude": 36.067012,
        "longitude": 138.049326
      },
      {
        "name": "飯田市",
        "latitude": 35.514978,
        "longitude": 137.821447
      },
      {
        "name": "諏訪市",
        "latitude": 36.039142,
        "longitude": 138.114182
      },
      {
        "name": "須坂市",
        "latitude": 36.651097,
        "longitude": 138.307192
      },
      {
        "name": "小諸市",
        "latitude": 36.327419,
        "longitude": 138.42588
      },
      {
        "name": "伊那市",
        "latitude": 35.827481,
        "longitude": 137.95408
      },
      {
        "name": "駒ヶ根市",
        "latitude": 35.728814,
        "longitude": 137.933782
      },
      {
        "name": "中野市",
        "latitude": 36.742044,
        "longitude": 138.36945
      },
      {
        "name": "大町市",
        "latitude": 36.503018,
        "longitude": 137.851094
      },
      {
        "name": "飯山市",
        "latitude": 36.851745,
        "longitude": 138.365495
      },
      {
        "name": "茅野市",
        "latitude": 35.995571,
        "longitude": 138.158893
      },
      {
        "name": "塩尻市",
        "latitude": 36.114972,
        "longitude": 137.953449
      },
      {
        "name": "佐久市",
        "latitude": 36.248868,
        "longitude": 138.476831
      },
      {
        "name": "千曲市",
        "latitude": 36.533897,
        "longitude": 138.120108
      },
      {
        "name": "東御市",
        "latitude": 36.359477,
        "longitude": 138.330247
      },
      {
        "name": "安曇野市",
        "latitude": 36.303951,
        "longitude": 137.905801
      },
      {
        "name": "南佐久郡小海町",
        "latitude": 36.095097,
        "longitude": 138.483472
      },
      {
        "name": "南佐久郡川上村",
        "latitude": 35.975487,
        "longitude": 138.578605
      },
      {
        "name": "南佐久郡南牧村",
        "latitude": 36.020856,
        "longitude": 138.492091
      },
      {
        "name": "南佐久郡南相木村",
        "latitude": 36.035985,
        "longitude": 138.54699
      },
      {
        "name": "南佐久郡北相木村",
        "latitude": 36.059169,
        "longitude": 138.550883
      },
      {
        "name": "南佐久郡佐久穂町",
        "latitude": 36.161106,
        "longitude": 138.483544
      },
      {
        "name": "北佐久郡軽井沢町",
        "latitude": 36.34829,
        "longitude": 138.596955
      },
      {
        "name": "北佐久郡御代田町",
        "latitude": 36.321183,
        "longitude": 138.508784
      },
      {
        "name": "北佐久郡立科町",
        "latitude": 36.27207,
        "longitude": 138.315972
      },
      {
        "name": "小県郡青木村",
        "latitude": 36.370346,
        "longitude": 138.12884
      },
      {
        "name": "小県郡長和町",
        "latitude": 36.255854,
        "longitude": 138.267866
      },
      {
        "name": "諏訪郡下諏訪町",
        "latitude": 36.069559,
        "longitude": 138.08017
      },
      {
        "name": "諏訪郡富士見町",
        "latitude": 35.914621,
        "longitude": 138.240699
      },
      {
        "name": "諏訪郡原村",
        "latitude": 35.964456,
        "longitude": 138.21772
      },
      {
        "name": "上伊那郡辰野町",
        "latitude": 35.982513,
        "longitude": 137.987484
      },
      {
        "name": "上伊那郡箕輪町",
        "latitude": 35.915134,
        "longitude": 137.981944
      },
      {
        "name": "上伊那郡飯島町",
        "latitude": 35.676737,
        "longitude": 137.91968
      },
      {
        "name": "上伊那郡南箕輪村",
        "latitude": 35.8729,
        "longitude": 137.975024
      },
      {
        "name": "上伊那郡中川村",
        "latitude": 35.634537,
        "longitude": 137.946058
      },
      {
        "name": "上伊那郡宮田村",
        "latitude": 35.768904,
        "longitude": 137.944307
      },
      {
        "name": "下伊那郡松川町",
        "latitude": 35.597292,
        "longitude": 137.909567
      },
      {
        "name": "下伊那郡高森町",
        "latitude": 35.551497,
        "longitude": 137.878437
      },
      {
        "name": "下伊那郡阿南町",
        "latitude": 35.32364,
        "longitude": 137.816055
      },
      {
        "name": "下伊那郡阿智村",
        "latitude": 35.443769,
        "longitude": 137.747505
      },
      {
        "name": "下伊那郡平谷村",
        "latitude": 35.323236,
        "longitude": 137.63003
      },
      {
        "name": "下伊那郡根羽村",
        "latitude": 35.253077,
        "longitude": 137.58126
      },
      {
        "name": "下伊那郡下條村",
        "latitude": 35.397414,
        "longitude": 137.786051
      },
      {
        "name": "下伊那郡売木村",
        "latitude": 35.271046,
        "longitude": 137.711313
      },
      {
        "name": "下伊那郡天龍村",
        "latitude": 35.276227,
        "longitude": 137.85414
      },
      {
        "name": "下伊那郡泰阜村",
        "latitude": 35.377523,
        "longitude": 137.846015
      },
      {
        "name": "下伊那郡喬木村",
        "latitude": 35.513682,
        "longitude": 137.8737
      },
      {
        "name": "下伊那郡豊丘村",
        "latitude": 35.551439,
        "longitude": 137.895975
      },
      {
        "name": "下伊那郡大鹿村",
        "latitude": 35.578222,
        "longitude": 138.034017
      },
      {
        "name": "木曽郡上松町",
        "latitude": 35.784076,
        "longitude": 137.694069
      },
      {
        "name": "木曽郡南木曽町",
        "latitude": 35.603756,
        "longitude": 137.609016
      },
      {
        "name": "木曽郡木祖村",
        "latitude": 35.936437,
        "longitude": 137.783301
      },
      {
        "name": "木曽郡王滝村",
        "latitude": 35.809395,
        "longitude": 137.550969
      },
      {
        "name": "木曽郡大桑村",
        "latitude": 35.682911,
        "longitude": 137.664918
      },
      {
        "name": "木曽郡木曽町",
        "latitude": 35.842431,
        "longitude": 137.691508
      },
      {
        "name": "東筑摩郡麻績村",
        "latitude": 36.456089,
        "longitude": 138.045289
      },
      {
        "name": "東筑摩郡生坂村",
        "latitude": 36.425248,
        "longitude": 137.927383
      },
      {
        "name": "東筑摩郡山形村",
        "latitude": 36.168028,
        "longitude": 137.879155
      },
      {
        "name": "東筑摩郡朝日村",
        "latitude": 36.12355,
        "longitude": 137.866133
      },
      {
        "name": "東筑摩郡筑北村",
        "latitude": 36.402553,
        "longitude": 138.011938
      },
      {
        "name": "北安曇郡池田町",
        "latitude": 36.421366,
        "longitude": 137.874502
      },
      {
        "name": "北安曇郡松川村",
        "latitude": 36.424076,
        "longitude": 137.854498
      },
      {
        "name": "北安曇郡白馬村",
        "latitude": 36.69818,
        "longitude": 137.86185
      },
      {
        "name": "北安曇郡小谷村",
        "latitude": 36.779067,
        "longitude": 137.908405
      },
      {
        "name": "埴科郡坂城町",
        "latitude": 36.46182,
        "longitude": 138.180106
      },
      {
        "name": "上高井郡小布施町",
        "latitude": 36.697492,
        "longitude": 138.312037
      },
      {
        "name": "上高井郡高山村",
        "latitude": 36.679768,
        "longitude": 138.363255
      },
      {
        "name": "下高井郡山ノ内町",
        "latitude": 36.744634,
        "longitude": 138.412596
      },
      {
        "name": "下高井郡木島平村",
        "latitude": 36.858406,
        "longitude": 138.406829
      },
      {
        "name": "下高井郡野沢温泉村",
        "latitude": 36.922902,
        "longitude": 138.440641
      },
      {
        "name": "上水内郡信濃町",
        "latitude": 36.806404,
        "longitude": 138.207113
      },
      {
        "name": "上水内郡小川村",
        "latitude": 36.617271,
        "longitude": 137.974724
      },
      {
        "name": "上水内郡飯綱町",
        "latitude": 36.763185,
        "longitude": 138.254809
      },
      {
        "name": "下水内郡栄村",
        "latitude": 36.987514,
        "longitude": 138.577069
      }
    ]
  },
  {
    "name": "岐阜県",
    "cities": [{
        "name": "岐阜市",
        "latitude": 35.423298,
        "longitude": 136.760654
      },
      {
        "name": "大垣市",
        "latitude": 35.359384,
        "longitude": 136.61275
      },
      {
        "name": "高山市",
        "latitude": 36.146124,
        "longitude": 137.252173
      },
      {
        "name": "多治見市",
        "latitude": 35.3328,
        "longitude": 137.132077
      },
      {
        "name": "関市",
        "latitude": 35.495738,
        "longitude": 136.917842
      },
      {
        "name": "中津川市",
        "latitude": 35.487548,
        "longitude": 137.500509
      },
      {
        "name": "美濃市",
        "latitude": 35.54477,
        "longitude": 136.90754
      },
      {
        "name": "瑞浪市",
        "latitude": 35.361712,
        "longitude": 137.254587
      },
      {
        "name": "羽島市",
        "latitude": 35.31991,
        "longitude": 136.703349
      },
      {
        "name": "恵那市",
        "latitude": 35.449314,
        "longitude": 137.412808
      },
      {
        "name": "美濃加茂市",
        "latitude": 35.440242,
        "longitude": 137.015664
      },
      {
        "name": "土岐市",
        "latitude": 35.3525,
        "longitude": 137.183202
      },
      {
        "name": "各務原市",
        "latitude": 35.398869,
        "longitude": 136.848264
      },
      {
        "name": "可児市",
        "latitude": 35.426121,
        "longitude": 137.061011
      },
      {
        "name": "山県市",
        "latitude": 35.506109,
        "longitude": 136.781372
      },
      {
        "name": "瑞穂市",
        "latitude": 35.404094,
        "longitude": 136.649774
      },
      {
        "name": "飛騨市",
        "latitude": 36.238124,
        "longitude": 137.186317
      },
      {
        "name": "本巣市",
        "latitude": 35.483047,
        "longitude": 136.678766
      },
      {
        "name": "郡上市",
        "latitude": 35.748577,
        "longitude": 136.964354
      },
      {
        "name": "下呂市",
        "latitude": 35.805882,
        "longitude": 137.2441
      },
      {
        "name": "海津市",
        "latitude": 35.220492,
        "longitude": 136.636662
      },
      {
        "name": "羽島郡岐南町",
        "latitude": 35.389722,
        "longitude": 136.783317
      },
      {
        "name": "羽島郡笠松町",
        "latitude": 35.3672,
        "longitude": 136.763167
      },
      {
        "name": "養老郡養老町",
        "latitude": 35.308333,
        "longitude": 136.561555
      },
      {
        "name": "不破郡垂井町",
        "latitude": 35.370254,
        "longitude": 136.527102
      },
      {
        "name": "不破郡関ケ原町",
        "latitude": 35.365517,
        "longitude": 136.466982
      },
      {
        "name": "安八郡神戸町",
        "latitude": 35.417368,
        "longitude": 136.608459
      },
      {
        "name": "安八郡輪之内町",
        "latitude": 35.285108,
        "longitude": 136.637435
      },
      {
        "name": "安八郡安八町",
        "latitude": 35.335391,
        "longitude": 136.665409
      },
      {
        "name": "揖斐郡揖斐川町",
        "latitude": 35.487309,
        "longitude": 136.568653
      },
      {
        "name": "揖斐郡大野町",
        "latitude": 35.470614,
        "longitude": 136.627552
      },
      {
        "name": "揖斐郡池田町",
        "latitude": 35.442259,
        "longitude": 136.572926
      },
      {
        "name": "本巣郡北方町",
        "latitude": 35.436948,
        "longitude": 136.685967
      },
      {
        "name": "加茂郡坂祝町",
        "latitude": 35.426662,
        "longitude": 136.985459
      },
      {
        "name": "加茂郡富加町",
        "latitude": 35.484883,
        "longitude": 136.979824
      },
      {
        "name": "加茂郡川辺町",
        "latitude": 35.486579,
        "longitude": 137.070608
      },
      {
        "name": "加茂郡七宗町",
        "latitude": 35.543724,
        "longitude": 137.119833
      },
      {
        "name": "加茂郡八百津町",
        "latitude": 35.475952,
        "longitude": 137.141502
      },
      {
        "name": "加茂郡白川町",
        "latitude": 35.581859,
        "longitude": 137.187824
      },
      {
        "name": "加茂郡東白川村",
        "latitude": 35.642561,
        "longitude": 137.323751
      },
      {
        "name": "可児郡御嵩町",
        "latitude": 35.434245,
        "longitude": 137.13079
      },
      {
        "name": "大野郡白川村",
        "latitude": 36.270806,
        "longitude": 136.898478
      }
    ]
  },
  {
    "name": "静岡県",
    "cities": [{
        "name": "静岡市葵区",
        "latitude": 34.97514,
        "longitude": 138.383243
      },
      {
        "name": "静岡市駿河区",
        "latitude": 34.960745,
        "longitude": 138.404039
      },
      {
        "name": "静岡市清水区",
        "latitude": 35.015888,
        "longitude": 138.489528
      },
      {
        "name": "浜松市中区",
        "latitude": 34.710896,
        "longitude": 137.72594
      },
      {
        "name": "浜松市東区",
        "latitude": 34.741325,
        "longitude": 137.791772
      },
      {
        "name": "浜松市西区",
        "latitude": 34.69271,
        "longitude": 137.645317
      },
      {
        "name": "浜松市南区",
        "latitude": 34.667333,
        "longitude": 137.7523
      },
      {
        "name": "浜松市北区",
        "latitude": 34.806193,
        "longitude": 137.651023
      },
      {
        "name": "浜松市浜北区",
        "latitude": 34.793372,
        "longitude": 137.789925
      },
      {
        "name": "浜松市天竜区",
        "latitude": 34.872649,
        "longitude": 137.816265
      },
      {
        "name": "沼津市",
        "latitude": 35.095584,
        "longitude": 138.863493
      },
      {
        "name": "熱海市",
        "latitude": 35.096276,
        "longitude": 139.071705
      },
      {
        "name": "三島市",
        "latitude": 35.118402,
        "longitude": 138.918513
      },
      {
        "name": "富士宮市",
        "latitude": 35.222054,
        "longitude": 138.621643
      },
      {
        "name": "伊東市",
        "latitude": 34.965671,
        "longitude": 139.101936
      },
      {
        "name": "島田市",
        "latitude": 34.836364,
        "longitude": 138.175997
      },
      {
        "name": "富士市",
        "latitude": 35.161399,
        "longitude": 138.676273
      },
      {
        "name": "磐田市",
        "latitude": 34.717904,
        "longitude": 137.851513
      },
      {
        "name": "焼津市",
        "latitude": 34.86692,
        "longitude": 138.323189
      },
      {
        "name": "掛川市",
        "latitude": 34.768767,
        "longitude": 137.998168
      },
      {
        "name": "藤枝市",
        "latitude": 34.867367,
        "longitude": 138.257458
      },
      {
        "name": "御殿場市",
        "latitude": 35.308584,
        "longitude": 138.934506
      },
      {
        "name": "袋井市",
        "latitude": 34.750174,
        "longitude": 137.924679
      },
      {
        "name": "下田市",
        "latitude": 34.679533,
        "longitude": 138.945316
      },
      {
        "name": "裾野市",
        "latitude": 35.173883,
        "longitude": 138.906729
      },
      {
        "name": "湖西市",
        "latitude": 34.718466,
        "longitude": 137.531626
      },
      {
        "name": "伊豆市",
        "latitude": 34.976586,
        "longitude": 138.946704
      },
      {
        "name": "御前崎市",
        "latitude": 34.637987,
        "longitude": 138.128127
      },
      {
        "name": "菊川市",
        "latitude": 34.757746,
        "longitude": 138.084582
      },
      {
        "name": "伊豆の国市",
        "latitude": 35.027735,
        "longitude": 138.928921
      },
      {
        "name": "牧之原市",
        "latitude": 34.740043,
        "longitude": 138.224652
      },
      {
        "name": "賀茂郡東伊豆町",
        "latitude": 34.772835,
        "longitude": 139.041311
      },
      {
        "name": "賀茂郡河津町",
        "latitude": 34.757033,
        "longitude": 138.987538
      },
      {
        "name": "賀茂郡南伊豆町",
        "latitude": 34.651092,
        "longitude": 138.858527
      },
      {
        "name": "賀茂郡松崎町",
        "latitude": 34.752769,
        "longitude": 138.778726
      },
      {
        "name": "賀茂郡西伊豆町",
        "latitude": 34.771676,
        "longitude": 138.775356
      },
      {
        "name": "田方郡函南町",
        "latitude": 35.088973,
        "longitude": 138.953366
      },
      {
        "name": "駿東郡清水町",
        "latitude": 35.099015,
        "longitude": 138.902717
      },
      {
        "name": "駿東郡長泉町",
        "latitude": 35.13767,
        "longitude": 138.897178
      },
      {
        "name": "駿東郡小山町",
        "latitude": 35.360107,
        "longitude": 138.987298
      },
      {
        "name": "榛原郡吉田町",
        "latitude": 34.77087,
        "longitude": 138.251993
      },
      {
        "name": "榛原郡川根本町",
        "latitude": 35.046833,
        "longitude": 138.081695
      },
      {
        "name": "周智郡森町",
        "latitude": 34.835627,
        "longitude": 137.927019
      }
    ]
  },
  {
    "name": "愛知県",
    "cities": [{
        "name": "名古屋市千種区",
        "latitude": 35.166486,
        "longitude": 136.946434
      },
      {
        "name": "名古屋市東区",
        "latitude": 35.179325,
        "longitude": 136.925993
      },
      {
        "name": "名古屋市北区",
        "latitude": 35.194206,
        "longitude": 136.911519
      },
      {
        "name": "名古屋市西区",
        "latitude": 35.189117,
        "longitude": 136.890033
      },
      {
        "name": "名古屋市中村区",
        "latitude": 35.168747,
        "longitude": 136.873015
      },
      {
        "name": "名古屋市中区",
        "latitude": 35.168707,
        "longitude": 136.910251
      },
      {
        "name": "名古屋市昭和区",
        "latitude": 35.150199,
        "longitude": 136.934124
      },
      {
        "name": "名古屋市瑞穂区",
        "latitude": 35.131441,
        "longitude": 136.934961
      },
      {
        "name": "名古屋市熱田区",
        "latitude": 35.128545,
        "longitude": 136.91028
      },
      {
        "name": "名古屋市中川区",
        "latitude": 35.141539,
        "longitude": 136.854793
      },
      {
        "name": "名古屋市港区",
        "latitude": 35.107772,
        "longitude": 136.885567
      },
      {
        "name": "名古屋市南区",
        "latitude": 35.095169,
        "longitude": 136.931242
      },
      {
        "name": "名古屋市守山区",
        "latitude": 35.203335,
        "longitude": 136.976314
      },
      {
        "name": "名古屋市緑区",
        "latitude": 35.070805,
        "longitude": 136.952238
      },
      {
        "name": "名古屋市名東区",
        "latitude": 35.175848,
        "longitude": 137.010183
      },
      {
        "name": "名古屋市天白区",
        "latitude": 35.122671,
        "longitude": 136.974982
      },
      {
        "name": "豊橋市",
        "latitude": 34.7692,
        "longitude": 137.391466
      },
      {
        "name": "岡崎市",
        "latitude": 34.954379,
        "longitude": 137.174329
      },
      {
        "name": "一宮市",
        "latitude": 35.30389,
        "longitude": 136.802933
      },
      {
        "name": "瀬戸市",
        "latitude": 35.223614,
        "longitude": 137.084171
      },
      {
        "name": "半田市",
        "latitude": 34.891718,
        "longitude": 136.937961
      },
      {
        "name": "春日井市",
        "latitude": 35.247611,
        "longitude": 136.972224
      },
      {
        "name": "豊川市",
        "latitude": 34.826851,
        "longitude": 137.375542
      },
      {
        "name": "津島市",
        "latitude": 35.177029,
        "longitude": 136.741214
      },
      {
        "name": "碧南市",
        "latitude": 34.884804,
        "longitude": 136.993375
      },
      {
        "name": "刈谷市",
        "latitude": 34.989225,
        "longitude": 137.002107
      },
      {
        "name": "豊田市",
        "latitude": 35.082395,
        "longitude": 137.156249
      },
      {
        "name": "安城市",
        "latitude": 34.958641,
        "longitude": 137.080297
      },
      {
        "name": "西尾市",
        "latitude": 34.861937,
        "longitude": 137.06185
      },
      {
        "name": "蒲郡市",
        "latitude": 34.8265,
        "longitude": 137.219516
      },
      {
        "name": "犬山市",
        "latitude": 35.378607,
        "longitude": 136.944564
      },
      {
        "name": "常滑市",
        "latitude": 34.8867,
        "longitude": 136.832397
      },
      {
        "name": "江南市",
        "latitude": 35.332011,
        "longitude": 136.870617
      },
      {
        "name": "小牧市",
        "latitude": 35.29031,
        "longitude": 136.910952
      },
      {
        "name": "稲沢市",
        "latitude": 35.248132,
        "longitude": 136.780202
      },
      {
        "name": "新城市",
        "latitude": 34.899229,
        "longitude": 137.498476
      },
      {
        "name": "東海市",
        "latitude": 35.022843,
        "longitude": 136.90253
      },
      {
        "name": "大府市",
        "latitude": 35.011657,
        "longitude": 136.963835
      },
      {
        "name": "知多市",
        "latitude": 34.996533,
        "longitude": 136.864755
      },
      {
        "name": "知立市",
        "latitude": 35.001272,
        "longitude": 137.050749
      },
      {
        "name": "尾張旭市",
        "latitude": 35.216513,
        "longitude": 137.035396
      },
      {
        "name": "高浜市",
        "latitude": 34.927643,
        "longitude": 136.987677
      },
      {
        "name": "岩倉市",
        "latitude": 35.279503,
        "longitude": 136.871431
      },
      {
        "name": "豊明市",
        "latitude": 35.053688,
        "longitude": 137.012997
      },
      {
        "name": "日進市",
        "latitude": 35.132015,
        "longitude": 137.039412
      },
      {
        "name": "田原市",
        "latitude": 34.668806,
        "longitude": 137.264203
      },
      {
        "name": "愛西市",
        "latitude": 35.153141,
        "longitude": 136.7281
      },
      {
        "name": "清須市",
        "latitude": 35.203381,
        "longitude": 136.865521
      },
      {
        "name": "北名古屋市",
        "latitude": 35.245668,
        "longitude": 136.866008
      },
      {
        "name": "弥富市",
        "latitude": 35.110018,
        "longitude": 136.724739
      },
      {
        "name": "みよし市",
        "latitude": 35.08941,
        "longitude": 137.074854
      },
      {
        "name": "あま市",
        "latitude": 35.170304,
        "longitude": 136.80083
      },
      {
        "name": "長久手市",
        "latitude": 35.184126,
        "longitude": 137.048762
      },
      {
        "name": "愛知郡東郷町",
        "latitude": 35.096748,
        "longitude": 137.052553
      },
      {
        "name": "西春日井郡豊山町",
        "latitude": 35.250474,
        "longitude": 136.912006
      },
      {
        "name": "丹羽郡大口町",
        "latitude": 35.332436,
        "longitude": 136.90767
      },
      {
        "name": "丹羽郡扶桑町",
        "latitude": 35.35914,
        "longitude": 136.913079
      },
      {
        "name": "海部郡大治町",
        "latitude": 35.175078,
        "longitude": 136.820095
      },
      {
        "name": "海部郡蟹江町",
        "latitude": 35.132331,
        "longitude": 136.786746
      },
      {
        "name": "海部郡飛島村",
        "latitude": 35.078848,
        "longitude": 136.791078
      },
      {
        "name": "知多郡阿久比町",
        "latitude": 34.932611,
        "longitude": 136.915554
      },
      {
        "name": "知多郡東浦町",
        "latitude": 34.977094,
        "longitude": 136.965622
      },
      {
        "name": "知多郡南知多町",
        "latitude": 34.715167,
        "longitude": 136.92997
      },
      {
        "name": "知多郡美浜町",
        "latitude": 34.778815,
        "longitude": 136.908237
      },
      {
        "name": "知多郡武豊町",
        "latitude": 34.851134,
        "longitude": 136.914803
      },
      {
        "name": "額田郡幸田町",
        "latitude": 34.864442,
        "longitude": 137.165617
      },
      {
        "name": "北設楽郡設楽町",
        "latitude": 35.097219,
        "longitude": 137.571445
      },
      {
        "name": "北設楽郡東栄町",
        "latitude": 35.076939,
        "longitude": 137.697837
      },
      {
        "name": "北設楽郡豊根村",
        "latitude": 35.146738,
        "longitude": 137.7197
      }
    ]
  },
  {
    "name": "三重県",
    "cities": [{
        "name": "津市",
        "latitude": 34.718596,
        "longitude": 136.505698
      },
      {
        "name": "四日市市",
        "latitude": 34.965157,
        "longitude": 136.624485
      },
      {
        "name": "伊勢市",
        "latitude": 34.487514,
        "longitude": 136.709336
      },
      {
        "name": "松阪市",
        "latitude": 34.577993,
        "longitude": 136.527593
      },
      {
        "name": "桑名市",
        "latitude": 35.062216,
        "longitude": 136.683803
      },
      {
        "name": "鈴鹿市",
        "latitude": 34.88188,
        "longitude": 136.584209
      },
      {
        "name": "名張市",
        "latitude": 34.627659,
        "longitude": 136.108296
      },
      {
        "name": "尾鷲市",
        "latitude": 34.070799,
        "longitude": 136.190995
      },
      {
        "name": "亀山市",
        "latitude": 34.855755,
        "longitude": 136.451645
      },
      {
        "name": "鳥羽市",
        "latitude": 34.481435,
        "longitude": 136.843362
      },
      {
        "name": "熊野市",
        "latitude": 33.888604,
        "longitude": 136.100237
      },
      {
        "name": "いなべ市",
        "latitude": 35.115701,
        "longitude": 136.561471
      },
      {
        "name": "志摩市",
        "latitude": 34.32816,
        "longitude": 136.829691
      },
      {
        "name": "伊賀市",
        "latitude": 34.768595,
        "longitude": 136.12991
      },
      {
        "name": "桑名郡木曽岬町",
        "latitude": 35.07598,
        "longitude": 136.731328
      },
      {
        "name": "員弁郡東員町",
        "latitude": 35.074257,
        "longitude": 136.583578
      },
      {
        "name": "三重郡菰野町",
        "latitude": 35.020068,
        "longitude": 136.507344
      },
      {
        "name": "三重郡朝日町",
        "latitude": 35.03421,
        "longitude": 136.664352
      },
      {
        "name": "三重郡川越町",
        "latitude": 35.023009,
        "longitude": 136.673865
      },
      {
        "name": "多気郡多気町",
        "latitude": 34.496153,
        "longitude": 136.546146
      },
      {
        "name": "多気郡明和町",
        "latitude": 34.54756,
        "longitude": 136.623176
      },
      {
        "name": "多気郡大台町",
        "latitude": 34.393387,
        "longitude": 136.407875
      },
      {
        "name": "度会郡玉城町",
        "latitude": 34.490278,
        "longitude": 136.630929
      },
      {
        "name": "度会郡度会町",
        "latitude": 34.438923,
        "longitude": 136.622466
      },
      {
        "name": "度会郡大紀町",
        "latitude": 34.358045,
        "longitude": 136.415792
      },
      {
        "name": "度会郡南伊勢町",
        "latitude": 34.352095,
        "longitude": 136.703689
      },
      {
        "name": "北牟婁郡紀北町",
        "latitude": 34.211533,
        "longitude": 136.337313
      },
      {
        "name": "南牟婁郡御浜町",
        "latitude": 33.814478,
        "longitude": 136.048724
      },
      {
        "name": "南牟婁郡紀宝町",
        "latitude": 33.733773,
        "longitude": 136.00967
      }
    ]
  },
  {
    "name": "滋賀県",
    "cities": [{
        "name": "大津市",
        "latitude": 35.017893,
        "longitude": 135.854607
      },
      {
        "name": "彦根市",
        "latitude": 35.274461,
        "longitude": 136.259623
      },
      {
        "name": "長浜市",
        "latitude": 35.380815,
        "longitude": 136.278722
      },
      {
        "name": "近江八幡市",
        "latitude": 35.128339,
        "longitude": 136.097907
      },
      {
        "name": "草津市",
        "latitude": 35.013134,
        "longitude": 135.959936
      },
      {
        "name": "守山市",
        "latitude": 35.05894,
        "longitude": 135.994404
      },
      {
        "name": "栗東市",
        "latitude": 35.021617,
        "longitude": 135.997996
      },
      {
        "name": "甲賀市",
        "latitude": 34.966139,
        "longitude": 136.167092
      },
      {
        "name": "野洲市",
        "latitude": 35.067454,
        "longitude": 136.02585
      },
      {
        "name": "湖南市",
        "latitude": 35.00415,
        "longitude": 136.084679
      },
      {
        "name": "高島市",
        "latitude": 35.353071,
        "longitude": 136.03583
      },
      {
        "name": "東近江市",
        "latitude": 35.112456,
        "longitude": 136.207765
      },
      {
        "name": "米原市",
        "latitude": 35.361585,
        "longitude": 136.370192
      },
      {
        "name": "蒲生郡日野町",
        "latitude": 35.018003,
        "longitude": 136.246043
      },
      {
        "name": "蒲生郡竜王町",
        "latitude": 35.060803,
        "longitude": 136.124535
      },
      {
        "name": "愛知郡愛荘町",
        "latitude": 35.16894,
        "longitude": 136.212367
      },
      {
        "name": "犬上郡豊郷町",
        "latitude": 35.200343,
        "longitude": 136.229893
      },
      {
        "name": "犬上郡甲良町",
        "latitude": 35.204119,
        "longitude": 136.261205
      },
      {
        "name": "犬上郡多賀町",
        "latitude": 35.221996,
        "longitude": 136.292049
      }
    ]
  },
  {
    "name": "京都府",
    "cities": [{
        "name": "京都市北区",
        "latitude": 35.041053,
        "longitude": 135.753983
      },
      {
        "name": "京都市上京区",
        "latitude": 35.029618,
        "longitude": 135.756627
      },
      {
        "name": "京都市左京区",
        "latitude": 35.048569,
        "longitude": 135.778548
      },
      {
        "name": "京都市中京区",
        "latitude": 35.010425,
        "longitude": 135.751411
      },
      {
        "name": "京都市東山区",
        "latitude": 34.997057,
        "longitude": 135.776162
      },
      {
        "name": "京都市下京区",
        "latitude": 34.987655,
        "longitude": 135.755533
      },
      {
        "name": "京都市南区",
        "latitude": 34.976661,
        "longitude": 135.746495
      },
      {
        "name": "京都市右京区",
        "latitude": 35.010133,
        "longitude": 135.716078
      },
      {
        "name": "京都市伏見区",
        "latitude": 34.93603,
        "longitude": 135.76141
      },
      {
        "name": "京都市山科区",
        "latitude": 34.972336,
        "longitude": 135.813697
      },
      {
        "name": "京都市西京区",
        "latitude": 34.985104,
        "longitude": 135.69342
      },
      {
        "name": "福知山市",
        "latitude": 35.2967,
        "longitude": 135.126563
      },
      {
        "name": "舞鶴市",
        "latitude": 35.474797,
        "longitude": 135.385992
      },
      {
        "name": "綾部市",
        "latitude": 35.298916,
        "longitude": 135.258812
      },
      {
        "name": "宇治市",
        "latitude": 34.884461,
        "longitude": 135.799854
      },
      {
        "name": "宮津市",
        "latitude": 35.535577,
        "longitude": 135.195567
      },
      {
        "name": "亀岡市",
        "latitude": 35.013408,
        "longitude": 135.573609
      },
      {
        "name": "城陽市",
        "latitude": 34.853008,
        "longitude": 135.779974
      },
      {
        "name": "向日市",
        "latitude": 34.948736,
        "longitude": 135.698288
      },
      {
        "name": "長岡京市",
        "latitude": 34.926771,
        "longitude": 135.695785
      },
      {
        "name": "八幡市",
        "latitude": 34.875555,
        "longitude": 135.707621
      },
      {
        "name": "京田辺市",
        "latitude": 34.814459,
        "longitude": 135.767695
      },
      {
        "name": "京丹後市",
        "latitude": 35.624166,
        "longitude": 135.061
      },
      {
        "name": "南丹市",
        "latitude": 35.107374,
        "longitude": 135.47026
      },
      {
        "name": "木津川市",
        "latitude": 34.737162,
        "longitude": 135.820117
      },
      {
        "name": "乙訓郡大山崎町",
        "latitude": 34.902806,
        "longitude": 135.688476
      },
      {
        "name": "久世郡久御山町",
        "latitude": 34.8815,
        "longitude": 135.732607
      },
      {
        "name": "綴喜郡井手町",
        "latitude": 34.798408,
        "longitude": 135.803306
      },
      {
        "name": "綴喜郡宇治田原町",
        "latitude": 34.85267,
        "longitude": 135.856855
      },
      {
        "name": "相楽郡笠置町",
        "latitude": 34.76045,
        "longitude": 135.939366
      },
      {
        "name": "相楽郡和束町",
        "latitude": 34.79571,
        "longitude": 135.90488
      },
      {
        "name": "相楽郡精華町",
        "latitude": 34.760856,
        "longitude": 135.785833
      },
      {
        "name": "相楽郡南山城村",
        "latitude": 34.772789,
        "longitude": 135.993537
      },
      {
        "name": "船井郡京丹波町",
        "latitude": 35.164362,
        "longitude": 135.423055
      },
      {
        "name": "与謝郡伊根町",
        "latitude": 35.675146,
        "longitude": 135.272639
      },
      {
        "name": "与謝郡与謝野町",
        "latitude": 35.565309,
        "longitude": 135.152841
      }
    ]
  },
  {
    "name": "大阪府",
    "cities": [{
        "name": "大阪市都島区",
        "latitude": 34.701279,
        "longitude": 135.52809
      },
      {
        "name": "大阪市福島区",
        "latitude": 34.692337,
        "longitude": 135.472229
      },
      {
        "name": "大阪市此花区",
        "latitude": 34.683049,
        "longitude": 135.452328
      },
      {
        "name": "大阪市西区",
        "latitude": 34.676231,
        "longitude": 135.486059
      },
      {
        "name": "大阪市港区",
        "latitude": 34.663918,
        "longitude": 135.460681
      },
      {
        "name": "大阪市大正区",
        "latitude": 34.650403,
        "longitude": 135.4727
      },
      {
        "name": "大阪市天王寺区",
        "latitude": 34.657917,
        "longitude": 135.519362
      },
      {
        "name": "大阪市浪速区",
        "latitude": 34.659444,
        "longitude": 135.49963
      },
      {
        "name": "大阪市西淀川区",
        "latitude": 34.711348,
        "longitude": 135.456202
      },
      {
        "name": "大阪市東淀川区",
        "latitude": 34.741214,
        "longitude": 135.529423
      },
      {
        "name": "大阪市東成区",
        "latitude": 34.670002,
        "longitude": 135.541268
      },
      {
        "name": "大阪市生野区",
        "latitude": 34.653751,
        "longitude": 135.534413
      },
      {
        "name": "大阪市旭区",
        "latitude": 34.721177,
        "longitude": 135.54422
      },
      {
        "name": "大阪市城東区",
        "latitude": 34.70189,
        "longitude": 135.545973
      },
      {
        "name": "大阪市阿倍野区",
        "latitude": 34.638969,
        "longitude": 135.518496
      },
      {
        "name": "大阪市住吉区",
        "latitude": 34.603673,
        "longitude": 135.500632
      },
      {
        "name": "大阪市東住吉区",
        "latitude": 34.622163,
        "longitude": 135.526601
      },
      {
        "name": "大阪市西成区",
        "latitude": 34.634872,
        "longitude": 135.494373
      },
      {
        "name": "大阪市淀川区",
        "latitude": 34.721026,
        "longitude": 135.486711
      },
      {
        "name": "大阪市鶴見区",
        "latitude": 34.704329,
        "longitude": 135.574198
      },
      {
        "name": "大阪市住之江区",
        "latitude": 34.609675,
        "longitude": 135.482742
      },
      {
        "name": "大阪市平野区",
        "latitude": 34.621199,
        "longitude": 135.546072
      },
      {
        "name": "大阪市北区",
        "latitude": 34.705362,
        "longitude": 135.510025
      },
      {
        "name": "大阪市中央区",
        "latitude": 34.681261,
        "longitude": 135.509801
      },
      {
        "name": "堺市堺区",
        "latitude": 34.573254,
        "longitude": 135.483101
      },
      {
        "name": "堺市中区",
        "latitude": 34.528342,
        "longitude": 135.498636
      },
      {
        "name": "堺市東区",
        "latitude": 34.538192,
        "longitude": 135.536591
      },
      {
        "name": "堺市西区",
        "latitude": 34.534999,
        "longitude": 135.463972
      },
      {
        "name": "堺市南区",
        "latitude": 34.486527,
        "longitude": 135.490568
      },
      {
        "name": "堺市北区",
        "latitude": 34.56535,
        "longitude": 135.517178
      },
      {
        "name": "堺市美原区",
        "latitude": 34.538545,
        "longitude": 135.559833
      },
      {
        "name": "岸和田市",
        "latitude": 34.460634,
        "longitude": 135.370829
      },
      {
        "name": "豊中市",
        "latitude": 34.781266,
        "longitude": 135.469739
      },
      {
        "name": "池田市",
        "latitude": 34.821705,
        "longitude": 135.428444
      },
      {
        "name": "吹田市",
        "latitude": 34.759453,
        "longitude": 135.51686
      },
      {
        "name": "泉大津市",
        "latitude": 34.504279,
        "longitude": 135.410464
      },
      {
        "name": "高槻市",
        "latitude": 34.846159,
        "longitude": 135.617272
      },
      {
        "name": "貝塚市",
        "latitude": 34.437584,
        "longitude": 135.358517
      },
      {
        "name": "守口市",
        "latitude": 34.737695,
        "longitude": 135.563904
      },
      {
        "name": "枚方市",
        "latitude": 34.81434,
        "longitude": 135.650658
      },
      {
        "name": "茨木市",
        "latitude": 34.816338,
        "longitude": 135.568505
      },
      {
        "name": "八尾市",
        "latitude": 34.626864,
        "longitude": 135.600978
      },
      {
        "name": "泉佐野市",
        "latitude": 34.406848,
        "longitude": 135.327337
      },
      {
        "name": "富田林市",
        "latitude": 34.499527,
        "longitude": 135.597128
      },
      {
        "name": "寝屋川市",
        "latitude": 34.766093,
        "longitude": 135.6281
      },
      {
        "name": "河内長野市",
        "latitude": 34.458084,
        "longitude": 135.564226
      },
      {
        "name": "松原市",
        "latitude": 34.577879,
        "longitude": 135.55185
      },
      {
        "name": "大東市",
        "latitude": 34.712015,
        "longitude": 135.623659
      },
      {
        "name": "和泉市",
        "latitude": 34.483527,
        "longitude": 135.423557
      },
      {
        "name": "箕面市",
        "latitude": 34.82693,
        "longitude": 135.470463
      },
      {
        "name": "柏原市",
        "latitude": 34.579305,
        "longitude": 135.628642
      },
      {
        "name": "羽曳野市",
        "latitude": 34.558035,
        "longitude": 135.606212
      },
      {
        "name": "門真市",
        "latitude": 34.739542,
        "longitude": 135.58689
      },
      {
        "name": "摂津市",
        "latitude": 34.777356,
        "longitude": 135.561817
      },
      {
        "name": "高石市",
        "latitude": 34.520717,
        "longitude": 135.442452
      },
      {
        "name": "藤井寺市",
        "latitude": 34.574277,
        "longitude": 135.597474
      },
      {
        "name": "東大阪市",
        "latitude": 34.67933,
        "longitude": 135.601012
      },
      {
        "name": "泉南市",
        "latitude": 34.366005,
        "longitude": 135.273339
      },
      {
        "name": "四條畷市",
        "latitude": 34.739941,
        "longitude": 135.639533
      },
      {
        "name": "交野市",
        "latitude": 34.787944,
        "longitude": 135.679953
      },
      {
        "name": "大阪狭山市",
        "latitude": 34.503795,
        "longitude": 135.555693
      },
      {
        "name": "阪南市",
        "latitude": 34.359583,
        "longitude": 135.23967
      },
      {
        "name": "三島郡島本町",
        "latitude": 34.883925,
        "longitude": 135.66297
      },
      {
        "name": "豊能郡豊能町",
        "latitude": 34.918948,
        "longitude": 135.494174
      },
      {
        "name": "豊能郡能勢町",
        "latitude": 34.972551,
        "longitude": 135.414274
      },
      {
        "name": "泉北郡忠岡町",
        "latitude": 34.487093,
        "longitude": 135.401323
      },
      {
        "name": "泉南郡熊取町",
        "latitude": 34.401283,
        "longitude": 135.355785
      },
      {
        "name": "泉南郡田尻町",
        "latitude": 34.393774,
        "longitude": 135.291174
      },
      {
        "name": "泉南郡岬町",
        "latitude": 34.316978,
        "longitude": 135.142139
      },
      {
        "name": "南河内郡太子町",
        "latitude": 34.518682,
        "longitude": 135.647661
      },
      {
        "name": "南河内郡河南町",
        "latitude": 34.491637,
        "longitude": 135.629699
      },
      {
        "name": "南河内郡千早赤阪村",
        "latitude": 34.464587,
        "longitude": 135.622537
      }
    ]
  },
  {
    "name": "兵庫県",
    "cities": [{
        "name": "神戸市東灘区",
        "latitude": 34.720228,
        "longitude": 135.265455
      },
      {
        "name": "神戸市灘区",
        "latitude": 34.712329,
        "longitude": 135.239657
      },
      {
        "name": "神戸市兵庫区",
        "latitude": 34.680578,
        "longitude": 135.165291
      },
      {
        "name": "神戸市長田区",
        "latitude": 34.665638,
        "longitude": 135.151026
      },
      {
        "name": "神戸市須磨区",
        "latitude": 34.658633,
        "longitude": 135.1337
      },
      {
        "name": "神戸市垂水区",
        "latitude": 34.630252,
        "longitude": 135.056766
      },
      {
        "name": "神戸市北区",
        "latitude": 34.727317,
        "longitude": 135.14444
      },
      {
        "name": "神戸市中央区",
        "latitude": 34.695124,
        "longitude": 135.197852
      },
      {
        "name": "神戸市西区",
        "latitude": 34.683239,
        "longitude": 134.981602
      },
      {
        "name": "姫路市",
        "latitude": 34.815149,
        "longitude": 134.685353
      },
      {
        "name": "尼崎市",
        "latitude": 34.733734,
        "longitude": 135.406376
      },
      {
        "name": "明石市",
        "latitude": 34.643107,
        "longitude": 134.997179
      },
      {
        "name": "西宮市",
        "latitude": 34.737597,
        "longitude": 135.341564
      },
      {
        "name": "洲本市",
        "latitude": 34.342426,
        "longitude": 134.89562
      },
      {
        "name": "芦屋市",
        "latitude": 34.72695,
        "longitude": 135.304162
      },
      {
        "name": "伊丹市",
        "latitude": 34.784306,
        "longitude": 135.400947
      },
      {
        "name": "相生市",
        "latitude": 34.803601,
        "longitude": 134.468066
      },
      {
        "name": "豊岡市",
        "latitude": 35.544575,
        "longitude": 134.820181
      },
      {
        "name": "加古川市",
        "latitude": 34.75695,
        "longitude": 134.841177
      },
      {
        "name": "赤穂市",
        "latitude": 34.754956,
        "longitude": 134.390284
      },
      {
        "name": "西脇市",
        "latitude": 34.993355,
        "longitude": 134.969189
      },
      {
        "name": "宝塚市",
        "latitude": 34.799767,
        "longitude": 135.360073
      },
      {
        "name": "三木市",
        "latitude": 34.796944,
        "longitude": 134.990197
      },
      {
        "name": "高砂市",
        "latitude": 34.766201,
        "longitude": 134.790442
      },
      {
        "name": "川西市",
        "latitude": 34.830135,
        "longitude": 135.417278
      },
      {
        "name": "小野市",
        "latitude": 34.853193,
        "longitude": 134.931376
      },
      {
        "name": "三田市",
        "latitude": 34.889996,
        "longitude": 135.225453
      },
      {
        "name": "加西市",
        "latitude": 34.927936,
        "longitude": 134.841622
      },
      {
        "name": "篠山市",
        "latitude": 35.075661,
        "longitude": 135.219051
      },
      {
        "name": "養父市",
        "latitude": 35.404657,
        "longitude": 134.767671
      },
      {
        "name": "丹波市",
        "latitude": 35.1771,
        "longitude": 135.035854
      },
      {
        "name": "南あわじ市",
        "latitude": 34.29448,
        "longitude": 134.779855
      },
      {
        "name": "朝来市",
        "latitude": 35.339665,
        "longitude": 134.85315
      },
      {
        "name": "淡路市",
        "latitude": 34.439699,
        "longitude": 134.915016
      },
      {
        "name": "宍粟市",
        "latitude": 35.004417,
        "longitude": 134.549466
      },
      {
        "name": "加東市",
        "latitude": 34.918573,
        "longitude": 134.973381
      },
      {
        "name": "たつの市",
        "latitude": 34.857863,
        "longitude": 134.54536
      },
      {
        "name": "川辺郡猪名川町",
        "latitude": 34.895037,
        "longitude": 135.376162
      },
      {
        "name": "多可郡多可町",
        "latitude": 35.050335,
        "longitude": 134.923275
      },
      {
        "name": "加古郡稲美町",
        "latitude": 34.748807,
        "longitude": 134.913551
      },
      {
        "name": "加古郡播磨町",
        "latitude": 34.715299,
        "longitude": 134.867912
      },
      {
        "name": "神崎郡市川町",
        "latitude": 34.989368,
        "longitude": 134.763317
      },
      {
        "name": "神崎郡福崎町",
        "latitude": 34.950302,
        "longitude": 134.760248
      },
      {
        "name": "神崎郡神河町",
        "latitude": 35.064226,
        "longitude": 134.739831
      },
      {
        "name": "揖保郡太子町",
        "latitude": 34.833264,
        "longitude": 134.572255
      },
      {
        "name": "赤穂郡上郡町",
        "latitude": 34.873563,
        "longitude": 134.356098
      },
      {
        "name": "佐用郡佐用町",
        "latitude": 35.004346,
        "longitude": 134.355734
      },
      {
        "name": "美方郡香美町",
        "latitude": 35.632186,
        "longitude": 134.629178
      },
      {
        "name": "美方郡新温泉町",
        "latitude": 35.623481,
        "longitude": 134.448939
      }
    ]
  },
  {
    "name": "奈良県",
    "cities": [{
        "name": "奈良市",
        "latitude": 34.685087,
        "longitude": 135.805
      },
      {
        "name": "大和高田市",
        "latitude": 34.515046,
        "longitude": 135.736392
      },
      {
        "name": "大和郡山市",
        "latitude": 34.649374,
        "longitude": 135.782755
      },
      {
        "name": "天理市",
        "latitude": 34.596647,
        "longitude": 135.837331
      },
      {
        "name": "橿原市",
        "latitude": 34.509448,
        "longitude": 135.792626
      },
      {
        "name": "桜井市",
        "latitude": 34.518714,
        "longitude": 135.843216
      },
      {
        "name": "五條市",
        "latitude": 34.35203,
        "longitude": 135.693632
      },
      {
        "name": "御所市",
        "latitude": 34.463331,
        "longitude": 135.74024
      },
      {
        "name": "生駒市",
        "latitude": 34.69196,
        "longitude": 135.700536
      },
      {
        "name": "香芝市",
        "latitude": 34.541256,
        "longitude": 135.698937
      },
      {
        "name": "葛城市",
        "latitude": 34.511119,
        "longitude": 135.706917
      },
      {
        "name": "宇陀市",
        "latitude": 34.528029,
        "longitude": 135.952269
      },
      {
        "name": "山辺郡山添村",
        "latitude": 34.68128,
        "longitude": 136.0438
      },
      {
        "name": "生駒郡平群町",
        "latitude": 34.629174,
        "longitude": 135.700654
      },
      {
        "name": "生駒郡三郷町",
        "latitude": 34.600138,
        "longitude": 135.695525
      },
      {
        "name": "生駒郡斑鳩町",
        "latitude": 34.608849,
        "longitude": 135.730591
      },
      {
        "name": "生駒郡安堵町",
        "latitude": 34.60648,
        "longitude": 135.756781
      },
      {
        "name": "磯城郡川西町",
        "latitude": 34.584394,
        "longitude": 135.774038
      },
      {
        "name": "磯城郡三宅町",
        "latitude": 34.573698,
        "longitude": 135.773144
      },
      {
        "name": "磯城郡田原本町",
        "latitude": 34.556601,
        "longitude": 135.794876
      },
      {
        "name": "宇陀郡曽爾村",
        "latitude": 34.510647,
        "longitude": 136.124478
      },
      {
        "name": "宇陀郡御杖村",
        "latitude": 34.488389,
        "longitude": 136.165916
      },
      {
        "name": "高市郡高取町",
        "latitude": 34.449513,
        "longitude": 135.793175
      },
      {
        "name": "高市郡明日香村",
        "latitude": 34.471278,
        "longitude": 135.820641
      },
      {
        "name": "北葛城郡上牧町",
        "latitude": 34.562749,
        "longitude": 135.716629
      },
      {
        "name": "北葛城郡王寺町",
        "latitude": 34.594725,
        "longitude": 135.706551
      },
      {
        "name": "北葛城郡広陵町",
        "latitude": 34.542784,
        "longitude": 135.750835
      },
      {
        "name": "北葛城郡河合町",
        "latitude": 34.578472,
        "longitude": 135.736672
      },
      {
        "name": "吉野郡吉野町",
        "latitude": 34.396049,
        "longitude": 135.857618
      },
      {
        "name": "吉野郡大淀町",
        "latitude": 34.390562,
        "longitude": 135.789716
      },
      {
        "name": "吉野郡下市町",
        "latitude": 34.360981,
        "longitude": 135.791858
      },
      {
        "name": "吉野郡黒滝村",
        "latitude": 34.309307,
        "longitude": 135.852142
      },
      {
        "name": "吉野郡天川村",
        "latitude": 34.241909,
        "longitude": 135.855149
      },
      {
        "name": "吉野郡野迫川村",
        "latitude": 34.166257,
        "longitude": 135.633063
      },
      {
        "name": "吉野郡十津川村",
        "latitude": 33.988495,
        "longitude": 135.792415
      },
      {
        "name": "吉野郡下北山村",
        "latitude": 34.005038,
        "longitude": 135.955167
      },
      {
        "name": "吉野郡上北山村",
        "latitude": 34.134322,
        "longitude": 135.999988
      },
      {
        "name": "吉野郡川上村",
        "latitude": 34.338107,
        "longitude": 135.954325
      },
      {
        "name": "吉野郡東吉野村",
        "latitude": 34.403582,
        "longitude": 135.968365
      }
    ]
  },
  {
    "name": "和歌山県",
    "cities": [{
        "name": "和歌山市",
        "latitude": 34.230511,
        "longitude": 135.170808
      },
      {
        "name": "海南市",
        "latitude": 34.155336,
        "longitude": 135.209223
      },
      {
        "name": "橋本市",
        "latitude": 34.315053,
        "longitude": 135.605296
      },
      {
        "name": "有田市",
        "latitude": 34.083095,
        "longitude": 135.127635
      },
      {
        "name": "御坊市",
        "latitude": 33.891492,
        "longitude": 135.152346
      },
      {
        "name": "田辺市",
        "latitude": 33.728034,
        "longitude": 135.377768
      },
      {
        "name": "新宮市",
        "latitude": 33.724108,
        "longitude": 135.992955
      },
      {
        "name": "紀の川市",
        "latitude": 34.26986,
        "longitude": 135.362757
      },
      {
        "name": "岩出市",
        "latitude": 34.256295,
        "longitude": 135.311323
      },
      {
        "name": "海草郡紀美野町",
        "latitude": 34.167225,
        "longitude": 135.307489
      },
      {
        "name": "伊都郡かつらぎ町",
        "latitude": 34.296396,
        "longitude": 135.503836
      },
      {
        "name": "伊都郡九度山町",
        "latitude": 34.287169,
        "longitude": 135.562245
      },
      {
        "name": "伊都郡高野町",
        "latitude": 34.216069,
        "longitude": 135.586536
      },
      {
        "name": "有田郡湯浅町",
        "latitude": 34.029394,
        "longitude": 135.190452
      },
      {
        "name": "有田郡広川町",
        "latitude": 34.030032,
        "longitude": 135.172993
      },
      {
        "name": "有田郡有田川町",
        "latitude": 34.065322,
        "longitude": 135.254432
      },
      {
        "name": "日高郡美浜町",
        "latitude": 33.893756,
        "longitude": 135.133101
      },
      {
        "name": "日高郡日高町",
        "latitude": 33.92573,
        "longitude": 135.140883
      },
      {
        "name": "日高郡由良町",
        "latitude": 33.959223,
        "longitude": 135.118349
      },
      {
        "name": "日高郡印南町",
        "latitude": 33.818367,
        "longitude": 135.218247
      },
      {
        "name": "日高郡みなべ町",
        "latitude": 33.772324,
        "longitude": 135.321442
      },
      {
        "name": "日高郡日高川町",
        "latitude": 33.911783,
        "longitude": 135.185875
      },
      {
        "name": "西牟婁郡白浜町",
        "latitude": 33.678165,
        "longitude": 135.348134
      },
      {
        "name": "西牟婁郡上富田町",
        "latitude": 33.696366,
        "longitude": 135.42883
      },
      {
        "name": "西牟婁郡すさみ町",
        "latitude": 33.550151,
        "longitude": 135.49664
      },
      {
        "name": "東牟婁郡那智勝浦町",
        "latitude": 33.62595,
        "longitude": 135.941067
      },
      {
        "name": "東牟婁郡太地町",
        "latitude": 33.594031,
        "longitude": 135.943962
      },
      {
        "name": "東牟婁郡古座川町",
        "latitude": 33.53184,
        "longitude": 135.814802
      },
      {
        "name": "東牟婁郡北山村",
        "latitude": 33.932097,
        "longitude": 135.969207
      },
      {
        "name": "東牟婁郡串本町",
        "latitude": 33.47247,
        "longitude": 135.781498
      }
    ]
  },
  {
    "name": "鳥取県",
    "cities": [{
        "name": "鳥取市",
        "latitude": "35.501133",
        "longitude": "134.235091"
      },
      {
        "name": "米子市",
        "latitude": "35.428072",
        "longitude": "133.330945"
      },
      {
        "name": "倉吉市",
        "latitude": "35.430179",
        "longitude": "133.825561"
      },
      {
        "name": "境港市",
        "latitude": "35.539592",
        "longitude": "133.231486"
      },
      {
        "name": "岩美郡岩美町",
        "latitude": "35.575993",
        "longitude": "134.33209"
      },
      {
        "name": "八頭郡若桜町",
        "latitude": "35.34017",
        "longitude": "134.400997"
      },
      {
        "name": "八頭郡智頭町",
        "latitude": "35.26505",
        "longitude": "134.226612"
      },
      {
        "name": "八頭町",
        "latitude": "35.409207",
        "longitude": "134.250526"
      },
      {
        "name": "東伯郡三朝町",
        "latitude": "35.408508",
        "longitude": "133.862306"
      },
      {
        "name": "東伯郡湯梨浜町",
        "latitude": "35.489959",
        "longitude": "133.864781"
      },
      {
        "name": "東伯郡琴浦町",
        "latitude": "35.495398",
        "longitude": "133.693278"
      },
      {
        "name": "東伯郡北栄町",
        "latitude": "35.49003",
        "longitude": "133.758355"
      },
      {
        "name": "西伯郡日吉津村",
        "latitude": "35.440207",
        "longitude": "133.380788"
      },
      {
        "name": "西伯郡大山町",
        "latitude": "35.510737",
        "longitude": "133.496114"
      },
      {
        "name": "西伯郡南部町",
        "latitude": "35.370784",
        "longitude": "133.361891"
      },
      {
        "name": "西伯郡伯耆町",
        "latitude": "35.385333",
        "longitude": "133.407164"
      },
      {
        "name": "日野郡日南町",
        "latitude": "35.163294",
        "longitude": "133.306233"
      },
      {
        "name": "日野郡日野町",
        "latitude": "35.240791",
        "longitude": "133.442786"
      },
      {
        "name": "日野郡江府町",
        "latitude": "35.283187",
        "longitude": "133.488561"
      }
    ]
  },
  {
    "name": "島根県",
    "cities": [{
        "name": "松江市",
        "latitude": "35.46806",
        "longitude": "133.048375"
      },
      {
        "name": "浜田市",
        "latitude": "34.899303",
        "longitude": "132.079783"
      },
      {
        "name": "出雲市",
        "latitude": "35.367035",
        "longitude": "132.754682"
      },
      {
        "name": "益田市",
        "latitude": "34.674817",
        "longitude": "131.842862"
      },
      {
        "name": "大田市",
        "latitude": "35.192106",
        "longitude": "132.499293"
      },
      {
        "name": "安来市",
        "latitude": "35.431442",
        "longitude": "133.250915"
      },
      {
        "name": "江津市",
        "latitude": "35.011418",
        "longitude": "132.22093"
      },
      {
        "name": "雲南市",
        "latitude": "35.307726",
        "longitude": "132.900299"
      },
      {
        "name": "仁多郡奥出雲町",
        "latitude": "35.19743",
        "longitude": "133.002543"
      },
      {
        "name": "飯石郡飯南町",
        "latitude": "35.000035",
        "longitude": "132.713874"
      },
      {
        "name": "邑智郡川本町",
        "latitude": "34.994145",
        "longitude": "132.495496"
      },
      {
        "name": "邑智郡美郷町",
        "latitude": "35.07669",
        "longitude": "132.590559"
      },
      {
        "name": "邑智郡邑南町",
        "latitude": "34.893963",
        "longitude": "132.43797"
      },
      {
        "name": "鹿足郡津和野町",
        "latitude": "34.467185",
        "longitude": "131.773166"
      },
      {
        "name": "鹿足郡吉賀町",
        "latitude": "34.442094",
        "longitude": "131.867897"
      },
      {
        "name": "隠岐郡海士町",
        "latitude": "36.096571",
        "longitude": "133.096709"
      },
      {
        "name": "隠岐郡西ノ島町",
        "latitude": "36.093027",
        "longitude": "132.994422"
      },
      {
        "name": "隠岐郡知夫村",
        "latitude": "36.013929",
        "longitude": "133.039386"
      },
      {
        "name": "隠岐郡隠岐の島町",
        "latitude": "36.209014",
        "longitude": "133.32177"
      }
    ]
  },
  {
    "name": "岡山県",
    "cities": [{
        "name": "岡山市北区",
        "latitude": "34.65534",
        "longitude": "133.919813"
      },
      {
        "name": "岡山市中区",
        "latitude": "34.670842",
        "longitude": "133.94318"
      },
      {
        "name": "岡山市東区",
        "latitude": "34.651443",
        "longitude": "134.029488"
      },
      {
        "name": "岡山市南区",
        "latitude": "34.599777",
        "longitude": "133.919553"
      },
      {
        "name": "倉敷市",
        "latitude": "34.585013",
        "longitude": "133.772084"
      },
      {
        "name": "津山市",
        "latitude": "35.069116",
        "longitude": "134.004543"
      },
      {
        "name": "玉野市",
        "latitude": "34.491943",
        "longitude": "133.945987"
      },
      {
        "name": "笠岡市",
        "latitude": "34.50718",
        "longitude": "133.507441"
      },
      {
        "name": "井原市",
        "latitude": "34.597672",
        "longitude": "133.463681"
      },
      {
        "name": "総社市",
        "latitude": "34.672807",
        "longitude": "133.746528"
      },
      {
        "name": "高梁市",
        "latitude": "34.791023",
        "longitude": "133.616733"
      },
      {
        "name": "新見市",
        "latitude": "34.97703",
        "longitude": "133.470306"
      },
      {
        "name": "備前市",
        "latitude": "34.74516",
        "longitude": "134.188044"
      },
      {
        "name": "瀬戸内市",
        "latitude": "34.664887",
        "longitude": "134.092846"
      },
      {
        "name": "赤磐市",
        "latitude": "34.755388",
        "longitude": "134.01884"
      },
      {
        "name": "真庭市",
        "latitude": "35.075602",
        "longitude": "133.75273"
      },
      {
        "name": "美作市",
        "latitude": "35.008588",
        "longitude": "134.148572"
      },
      {
        "name": "浅口市",
        "latitude": "34.527839",
        "longitude": "133.584922"
      },
      {
        "name": "和気郡和気町",
        "latitude": "34.849729",
        "longitude": "134.097923"
      },
      {
        "name": "都窪郡早島町",
        "latitude": "34.600702",
        "longitude": "133.828354"
      },
      {
        "name": "浅口郡里庄町",
        "latitude": "34.51379",
        "longitude": "133.556866"
      },
      {
        "name": "小田郡矢掛町",
        "latitude": "34.627593",
        "longitude": "133.587085"
      },
      {
        "name": "真庭郡新庄村",
        "latitude": "35.179458",
        "longitude": "133.567734"
      },
      {
        "name": "苫田郡鏡野町",
        "latitude": "35.091833",
        "longitude": "133.932799"
      },
      {
        "name": "勝田郡勝央町",
        "latitude": "35.04184",
        "longitude": "134.116183"
      },
      {
        "name": "勝田郡奈義町",
        "latitude": "35.123024",
        "longitude": "134.177425"
      },
      {
        "name": "英田郡西粟倉村",
        "latitude": "35.171331",
        "longitude": "134.336273"
      },
      {
        "name": "久米郡久米南町",
        "latitude": "34.929246",
        "longitude": "133.960687"
      },
      {
        "name": "久米郡美咲町",
        "latitude": "34.997953",
        "longitude": "133.958163"
      },
      {
        "name": "加賀郡吉備中央町",
        "latitude": "34.860965",
        "longitude": "133.811806"
      }
    ]
  },
  {
    "name": "広島県",
    "cities": [{
        "name": "広島市中区",
        "latitude": "34.386245",
        "longitude": "132.455018"
      },
      {
        "name": "広島市東区",
        "latitude": "34.39548",
        "longitude": "132.482429"
      },
      {
        "name": "広島市南区",
        "latitude": "34.379979",
        "longitude": "132.469033"
      },
      {
        "name": "広島市西区",
        "latitude": "34.393972",
        "longitude": "132.434421"
      },
      {
        "name": "広島市安佐南区",
        "latitude": "34.451844",
        "longitude": "132.47163"
      },
      {
        "name": "広島市安佐北区",
        "latitude": "34.518373",
        "longitude": "132.507753"
      },
      {
        "name": "広島市安芸区",
        "latitude": "34.371728",
        "longitude": "132.525417"
      },
      {
        "name": "広島市佐伯区",
        "latitude": "34.364446",
        "longitude": "132.36084"
      },
      {
        "name": "呉市",
        "latitude": "34.249254",
        "longitude": "132.565805"
      },
      {
        "name": "竹原市",
        "latitude": "34.341794",
        "longitude": "132.907088"
      },
      {
        "name": "三原市",
        "latitude": "34.397433",
        "longitude": "133.078658"
      },
      {
        "name": "尾道市",
        "latitude": "34.408876",
        "longitude": "133.204889"
      },
      {
        "name": "福山市",
        "latitude": "34.485899",
        "longitude": "133.362313"
      },
      {
        "name": "府中市",
        "latitude": "34.568347",
        "longitude": "133.236321"
      },
      {
        "name": "三次市",
        "latitude": "34.805574",
        "longitude": "132.851674"
      },
      {
        "name": "庄原市",
        "latitude": "34.857732",
        "longitude": "133.017277"
      },
      {
        "name": "大竹市",
        "latitude": "34.237977",
        "longitude": "132.222475"
      },
      {
        "name": "東広島市",
        "latitude": "34.42638",
        "longitude": "132.743307"
      },
      {
        "name": "廿日市市",
        "latitude": "34.348258",
        "longitude": "132.331622"
      },
      {
        "name": "安芸高田市",
        "latitude": "34.666307",
        "longitude": "132.704299"
      },
      {
        "name": "江田島市",
        "latitude": "34.223002",
        "longitude": "132.443855"
      },
      {
        "name": "安芸郡府中町",
        "latitude": "34.392486",
        "longitude": "132.50458"
      },
      {
        "name": "安芸郡海田町",
        "latitude": "34.372164",
        "longitude": "132.536089"
      },
      {
        "name": "安芸郡熊野町",
        "latitude": "34.335736",
        "longitude": "132.584637"
      },
      {
        "name": "安芸郡坂町",
        "latitude": "34.34124",
        "longitude": "132.513703"
      },
      {
        "name": "山県郡安芸太田町",
        "latitude": "34.576745",
        "longitude": "132.227141"
      },
      {
        "name": "山県郡北広島町",
        "latitude": "34.674558",
        "longitude": "132.538416"
      },
      {
        "name": "豊田郡大崎上島町",
        "latitude": "34.269633",
        "longitude": "132.915086"
      },
      {
        "name": "世羅郡世羅町",
        "latitude": "34.586828",
        "longitude": "133.056651"
      },
      {
        "name": "神石郡神石高原町",
        "latitude": "34.703661",
        "longitude": "133.247652"
      }
    ]
  },
  {
    "name": "山口県",
    "cities": [{
        "name": "下関市",
        "latitude": "33.957831",
        "longitude": "130.941459"
      },
      {
        "name": "宇部市",
        "latitude": "33.951509",
        "longitude": "131.246786"
      },
      {
        "name": "山口市",
        "latitude": "34.178496",
        "longitude": "131.473727"
      },
      {
        "name": "萩市",
        "latitude": "34.408116",
        "longitude": "131.399085"
      },
      {
        "name": "防府市",
        "latitude": "34.051752",
        "longitude": "131.56269"
      },
      {
        "name": "下松市",
        "latitude": "34.01503",
        "longitude": "131.870342"
      },
      {
        "name": "岩国市",
        "latitude": "34.166562",
        "longitude": "132.218848"
      },
      {
        "name": "光市",
        "latitude": "33.961913",
        "longitude": "131.942282"
      },
      {
        "name": "長門市",
        "latitude": "34.370956",
        "longitude": "131.182195"
      },
      {
        "name": "柳井市",
        "latitude": "33.963833",
        "longitude": "132.101597"
      },
      {
        "name": "美祢市",
        "latitude": "34.166635",
        "longitude": "131.205678"
      },
      {
        "name": "周南市",
        "latitude": "34.055119",
        "longitude": "131.806186"
      },
      {
        "name": "山陽小野田市",
        "latitude": "34.003064",
        "longitude": "131.181967"
      },
      {
        "name": "大島郡周防大島町",
        "latitude": "33.927595",
        "longitude": "132.195234"
      },
      {
        "name": "玖珂郡和木町",
        "latitude": "34.202406",
        "longitude": "132.220408"
      },
      {
        "name": "熊毛郡上関町",
        "latitude": "33.830976",
        "longitude": "132.110743"
      },
      {
        "name": "熊毛郡田布施町",
        "latitude": "33.954703",
        "longitude": "132.041373"
      },
      {
        "name": "熊毛郡平生町",
        "latitude": "33.937988",
        "longitude": "132.073052"
      },
      {
        "name": "阿武郡阿武町",
        "latitude": "34.503335",
        "longitude": "131.471399"
      }
    ]
  },
  {
    "name": "徳島県",
    "cities": [{
        "name": "徳島市",
        "latitude": "34.07027",
        "longitude": "134.554844"
      },
      {
        "name": "鳴門市",
        "latitude": "34.172562",
        "longitude": "134.608786"
      },
      {
        "name": "小松島市",
        "latitude": "34.004711",
        "longitude": "134.590674"
      },
      {
        "name": "阿南市",
        "latitude": "33.921748",
        "longitude": "134.659469"
      },
      {
        "name": "吉野川市",
        "latitude": "34.025689",
        "longitude": "134.259833"
      },
      {
        "name": "阿波市",
        "latitude": "34.102148",
        "longitude": "134.297437"
      },
      {
        "name": "美馬市",
        "latitude": "34.053403",
        "longitude": "134.169749"
      },
      {
        "name": "三好市",
        "latitude": "34.026041",
        "longitude": "133.807263"
      },
      {
        "name": "勝浦郡勝浦町",
        "latitude": "33.931408",
        "longitude": "134.511356"
      },
      {
        "name": "勝浦郡上勝町",
        "latitude": "33.889162",
        "longitude": "134.402051"
      },
      {
        "name": "名東郡佐那河内村",
        "latitude": "33.993208",
        "longitude": "134.453122"
      },
      {
        "name": "名西郡石井町",
        "latitude": "34.074173",
        "longitude": "134.44069"
      },
      {
        "name": "名西郡神山町",
        "latitude": "33.967291",
        "longitude": "134.350461"
      },
      {
        "name": "那賀郡那賀町",
        "latitude": "33.820626",
        "longitude": "134.47883"
      },
      {
        "name": "海部郡牟岐町",
        "latitude": "33.668407",
        "longitude": "134.420555"
      },
      {
        "name": "海部郡美波町",
        "latitude": "33.734553",
        "longitude": "134.535444"
      },
      {
        "name": "海部郡海陽町",
        "latitude": "33.602029",
        "longitude": "134.351997"
      },
      {
        "name": "板野郡松茂町",
        "latitude": "34.133768",
        "longitude": "134.580556"
      },
      {
        "name": "板野郡北島町",
        "latitude": "34.125578",
        "longitude": "134.546987"
      },
      {
        "name": "板野郡藍住町",
        "latitude": "34.126587",
        "longitude": "134.49509"
      },
      {
        "name": "板野郡板野町",
        "latitude": "34.144363",
        "longitude": "134.462618"
      },
      {
        "name": "板野郡上板町",
        "latitude": "34.121384",
        "longitude": "134.404816"
      },
      {
        "name": "美馬郡つるぎ町",
        "latitude": "34.037335",
        "longitude": "134.064074"
      },
      {
        "name": "三好郡東みよし町",
        "latitude": "34.036783",
        "longitude": "133.936757"
      }
    ]
  },
  {
    "name": "香川県",
    "cities": [{
        "name": "高松市",
        "latitude": "34.342788",
        "longitude": "134.046574"
      },
      {
        "name": "丸亀市",
        "latitude": "34.289506",
        "longitude": "133.797706"
      },
      {
        "name": "坂出市",
        "latitude": "34.316319",
        "longitude": "133.86051"
      },
      {
        "name": "善通寺市",
        "latitude": "34.228533",
        "longitude": "133.787113"
      },
      {
        "name": "観音寺市",
        "latitude": "34.128466",
        "longitude": "133.662811"
      },
      {
        "name": "さぬき市",
        "latitude": "34.325207",
        "longitude": "134.17212"
      },
      {
        "name": "東かがわ市",
        "latitude": "34.243809",
        "longitude": "134.358743"
      },
      {
        "name": "三豊市",
        "latitude": "34.182547",
        "longitude": "133.715219"
      },
      {
        "name": "小豆郡土庄町",
        "latitude": "34.486022",
        "longitude": "134.185807"
      },
      {
        "name": "小豆郡小豆島町",
        "latitude": "34.481998",
        "longitude": "134.233595"
      },
      {
        "name": "木田郡三木町",
        "latitude": "34.268284",
        "longitude": "134.134293"
      },
      {
        "name": "香川郡直島町",
        "latitude": "34.459703",
        "longitude": "133.995689"
      },
      {
        "name": "綾歌郡宇多津町",
        "latitude": "34.310298",
        "longitude": "133.825513"
      },
      {
        "name": "綾歌郡綾川町",
        "latitude": "34.249627",
        "longitude": "133.923066"
      },
      {
        "name": "仲多度郡琴平町",
        "latitude": "34.191417",
        "longitude": "133.823303"
      },
      {
        "name": "仲多度郡多度津町",
        "latitude": "34.272488",
        "longitude": "133.753605"
      },
      {
        "name": "仲多度郡まんのう町",
        "latitude": "34.192329",
        "longitude": "133.841402"
      }
    ]
  },
  {
    "name": "愛媛県",
    "cities": [{
        "name": "松山市",
        "latitude": "33.839157",
        "longitude": "132.765575"
      },
      {
        "name": "今治市",
        "latitude": "34.066098",
        "longitude": "132.99786"
      },
      {
        "name": "宇和島市",
        "latitude": "33.22334",
        "longitude": "132.560557"
      },
      {
        "name": "八幡浜市",
        "latitude": "33.462898",
        "longitude": "132.423336"
      },
      {
        "name": "新居浜市",
        "latitude": "33.96029",
        "longitude": "133.283351"
      },
      {
        "name": "西条市",
        "latitude": "33.919511",
        "longitude": "133.18117"
      },
      {
        "name": "大洲市",
        "latitude": "33.506194",
        "longitude": "132.544509"
      },
      {
        "name": "伊予市",
        "latitude": "33.757429",
        "longitude": "132.703729"
      },
      {
        "name": "四国中央市",
        "latitude": "33.980761",
        "longitude": "133.549195"
      },
      {
        "name": "西予市",
        "latitude": "33.36262",
        "longitude": "132.510992"
      },
      {
        "name": "東温市",
        "latitude": "33.791024",
        "longitude": "132.872296"
      },
      {
        "name": "越智郡上島町",
        "latitude": "34.257336",
        "longitude": "133.204507"
      },
      {
        "name": "上浮穴郡久万高原町",
        "latitude": "33.655606",
        "longitude": "132.901671"
      },
      {
        "name": "伊予郡松前町",
        "latitude": "33.78744",
        "longitude": "132.711375"
      },
      {
        "name": "伊予郡砥部町",
        "latitude": "33.749263",
        "longitude": "132.792228"
      },
      {
        "name": "喜多郡内子町",
        "latitude": "33.532902",
        "longitude": "132.658007"
      },
      {
        "name": "西宇和郡伊方町",
        "latitude": "33.488574",
        "longitude": "132.354012"
      },
      {
        "name": "北宇和郡松野町",
        "latitude": "33.227189",
        "longitude": "132.710799"
      },
      {
        "name": "北宇和郡鬼北町",
        "latitude": "33.255857",
        "longitude": "132.684104"
      },
      {
        "name": "南宇和郡愛南町",
        "latitude": "32.962164",
        "longitude": "132.583324"
      }
    ]
  },
  {
    "name": "高知県",
    "cities": [{
        "name": "高知市",
        "latitude": "33.559587",
        "longitude": "133.529279"
      },
      {
        "name": "室戸市",
        "latitude": "33.290009",
        "longitude": "134.152051"
      },
      {
        "name": "安芸市",
        "latitude": "33.502546",
        "longitude": "133.907166"
      },
      {
        "name": "南国市",
        "latitude": "33.575671",
        "longitude": "133.64146"
      },
      {
        "name": "土佐市",
        "latitude": "33.496044",
        "longitude": "133.425292"
      },
      {
        "name": "須崎市",
        "latitude": "33.400745",
        "longitude": "133.282953"
      },
      {
        "name": "宿毛市",
        "latitude": "32.939009",
        "longitude": "132.726286"
      },
      {
        "name": "土佐清水市",
        "latitude": "32.781634",
        "longitude": "132.954989"
      },
      {
        "name": "四万十市",
        "latitude": "32.991384",
        "longitude": "132.933749"
      },
      {
        "name": "香南市",
        "latitude": "33.564171",
        "longitude": "133.700488"
      },
      {
        "name": "香美市",
        "latitude": "33.603719",
        "longitude": "133.686003"
      },
      {
        "name": "安芸郡東洋町",
        "latitude": "33.527937",
        "longitude": "134.279974"
      },
      {
        "name": "安芸郡奈半利町",
        "latitude": "33.424181",
        "longitude": "134.020952"
      },
      {
        "name": "安芸郡田野町",
        "latitude": "33.427708",
        "longitude": "134.008293"
      },
      {
        "name": "安芸郡安田町",
        "latitude": "33.43857",
        "longitude": "133.981074"
      },
      {
        "name": "安芸郡北川村",
        "latitude": "33.447694",
        "longitude": "134.042067"
      },
      {
        "name": "安芸郡馬路村",
        "latitude": "33.55535",
        "longitude": "134.048108"
      },
      {
        "name": "安芸郡芸西村",
        "latitude": "33.526901",
        "longitude": "133.808914"
      },
      {
        "name": "長岡郡本山町",
        "latitude": "33.757107",
        "longitude": "133.591412"
      },
      {
        "name": "長岡郡大豊町",
        "latitude": "33.764291",
        "longitude": "133.664275"
      },
      {
        "name": "土佐郡土佐町",
        "latitude": "33.737064",
        "longitude": "133.531675"
      },
      {
        "name": "土佐郡大川村",
        "latitude": "33.783926",
        "longitude": "133.466608"
      },
      {
        "name": "吾川郡いの町",
        "latitude": "33.548754",
        "longitude": "133.427773"
      },
      {
        "name": "吾川郡仁淀川町",
        "latitude": "33.575245",
        "longitude": "133.168574"
      },
      {
        "name": "高岡郡中土佐町",
        "latitude": "33.337346",
        "longitude": "133.140361"
      },
      {
        "name": "高岡郡佐川町",
        "latitude": "33.500869",
        "longitude": "133.28653"
      },
      {
        "name": "高岡郡越知町",
        "latitude": "33.53292",
        "longitude": "133.251991"
      },
      {
        "name": "高岡郡檮原町",
        "latitude": "33.392246",
        "longitude": "132.926987"
      },
      {
        "name": "高岡郡日高村",
        "latitude": "33.534897",
        "longitude": "133.373353"
      },
      {
        "name": "高岡郡津野町",
        "latitude": "33.44658",
        "longitude": "133.199469"
      },
      {
        "name": "高岡郡四万十町",
        "latitude": "33.211653",
        "longitude": "133.137027"
      },
      {
        "name": "幡多郡大月町",
        "latitude": "32.841482",
        "longitude": "132.707026"
      },
      {
        "name": "幡多郡三原村",
        "latitude": "32.906013",
        "longitude": "132.847242"
      },
      {
        "name": "幡多郡黒潮町",
        "latitude": "33.02493",
        "longitude": "133.01092"
      }
    ]
  },
  {
    "name": "福岡県",
    "cities": [{
        "name": "北九州市門司区",
        "latitude": "33.941138",
        "longitude": "130.959663"
      },
      {
        "name": "北九州市若松区",
        "latitude": "33.905476",
        "longitude": "130.811143"
      },
      {
        "name": "北九州市戸畑区",
        "latitude": "33.893508",
        "longitude": "130.829814"
      },
      {
        "name": "北九州市小倉北区",
        "latitude": "33.880882",
        "longitude": "130.873419"
      },
      {
        "name": "北九州市小倉南区",
        "latitude": "33.846525",
        "longitude": "130.884811"
      },
      {
        "name": "北九州市八幡東区",
        "latitude": "33.863444",
        "longitude": "130.811863"
      },
      {
        "name": "北九州市八幡西区",
        "latitude": "33.866465",
        "longitude": "130.764923"
      },
      {
        "name": "福岡市東区",
        "latitude": "33.617746",
        "longitude": "130.417391"
      },
      {
        "name": "福岡市博多区",
        "latitude": "33.591358",
        "longitude": "130.414878"
      },
      {
        "name": "福岡市中央区",
        "latitude": "33.589216",
        "longitude": "130.392813"
      },
      {
        "name": "福岡市南区",
        "latitude": "33.561569",
        "longitude": "130.426428"
      },
      {
        "name": "福岡市西区",
        "latitude": "33.582939",
        "longitude": "130.323119"
      },
      {
        "name": "福岡市城南区",
        "latitude": "33.575686",
        "longitude": "130.369976"
      },
      {
        "name": "福岡市早良区",
        "latitude": "33.581941",
        "longitude": "130.348436"
      },
      {
        "name": "大牟田市",
        "latitude": "33.030262",
        "longitude": "130.445985"
      },
      {
        "name": "久留米市",
        "latitude": "33.319287",
        "longitude": "130.508374"
      },
      {
        "name": "直方市",
        "latitude": "33.744184",
        "longitude": "130.729633"
      },
      {
        "name": "飯塚市",
        "latitude": "33.645908",
        "longitude": "130.691511"
      },
      {
        "name": "田川市",
        "latitude": "33.638982",
        "longitude": "130.806298"
      },
      {
        "name": "柳川市",
        "latitude": "33.163089",
        "longitude": "130.405753"
      },
      {
        "name": "八女市",
        "latitude": "33.211967",
        "longitude": "130.557908"
      },
      {
        "name": "筑後市",
        "latitude": "33.212447",
        "longitude": "130.502093"
      },
      {
        "name": "大川市",
        "latitude": "33.206594",
        "longitude": "130.383958"
      },
      {
        "name": "行橋市",
        "latitude": "33.728773",
        "longitude": "130.982967"
      },
      {
        "name": "豊前市",
        "latitude": "33.611511",
        "longitude": "131.130051"
      },
      {
        "name": "中間市",
        "latitude": "33.81665",
        "longitude": "130.709077"
      },
      {
        "name": "小郡市",
        "latitude": "33.396416",
        "longitude": "130.555438"
      },
      {
        "name": "筑紫野市",
        "latitude": "33.496342",
        "longitude": "130.515657"
      },
      {
        "name": "春日市",
        "latitude": "33.532571",
        "longitude": "130.470281"
      },
      {
        "name": "大野城市",
        "latitude": "33.536307",
        "longitude": "130.478641"
      },
      {
        "name": "宗像市",
        "latitude": "33.805431",
        "longitude": "130.540718"
      },
      {
        "name": "太宰府市",
        "latitude": "33.512799",
        "longitude": "130.523906"
      },
      {
        "name": "古賀市",
        "latitude": "33.728787",
        "longitude": "130.469987"
      },
      {
        "name": "福津市",
        "latitude": "33.786866",
        "longitude": "130.469886"
      },
      {
        "name": "うきは市",
        "latitude": "33.347297",
        "longitude": "130.754934"
      },
      {
        "name": "宮若市",
        "latitude": "33.723568",
        "longitude": "130.666694"
      },
      {
        "name": "嘉麻市",
        "latitude": "33.560662",
        "longitude": "130.766817"
      },
      {
        "name": "朝倉市",
        "latitude": "33.423413",
        "longitude": "130.665573"
      },
      {
        "name": "みやま市",
        "latitude": "33.152425",
        "longitude": "130.47472"
      },
      {
        "name": "糸島市",
        "latitude": "33.55732",
        "longitude": "130.195555"
      },
      {
        "name": "筑紫郡那珂川町",
        "latitude": "33.4996",
        "longitude": "130.422174"
      },
      {
        "name": "糟屋郡宇美町",
        "latitude": "33.567779",
        "longitude": "130.511112"
      },
      {
        "name": "糟屋郡篠栗町",
        "latitude": "33.623948",
        "longitude": "130.526256"
      },
      {
        "name": "糟屋郡志免町",
        "latitude": "33.591473",
        "longitude": "130.479743"
      },
      {
        "name": "糟屋郡須恵町",
        "latitude": "33.587268",
        "longitude": "130.507254"
      },
      {
        "name": "糟屋郡新宮町",
        "latitude": "33.715295",
        "longitude": "130.446566"
      },
      {
        "name": "糟屋郡久山町",
        "latitude": "33.646809",
        "longitude": "130.499834"
      },
      {
        "name": "糟屋郡粕屋町",
        "latitude": "33.610743",
        "longitude": "130.480523"
      },
      {
        "name": "遠賀郡芦屋町",
        "latitude": "33.893801",
        "longitude": "130.663896"
      },
      {
        "name": "遠賀郡水巻町",
        "latitude": "33.854748",
        "longitude": "130.694847"
      },
      {
        "name": "遠賀郡岡垣町",
        "latitude": "33.853599",
        "longitude": "130.611104"
      },
      {
        "name": "遠賀郡遠賀町",
        "latitude": "33.848151",
        "longitude": "130.668438"
      },
      {
        "name": "鞍手郡小竹町",
        "latitude": "33.6925",
        "longitude": "130.712771"
      },
      {
        "name": "鞍手郡鞍手町",
        "latitude": "33.792218",
        "longitude": "130.673975"
      },
      {
        "name": "嘉穂郡桂川町",
        "latitude": "33.578933",
        "longitude": "130.677904"
      },
      {
        "name": "朝倉郡筑前町",
        "latitude": "33.456977",
        "longitude": "130.5953"
      },
      {
        "name": "東峰村",
        "latitude": "33.464292",
        "longitude": "130.825892"
      },
      {
        "name": "三井郡大刀洗町",
        "latitude": "33.372372",
        "longitude": "130.62248"
      },
      {
        "name": "三潴郡大木町",
        "latitude": "33.210469",
        "longitude": "130.439733"
      },
      {
        "name": "八女郡広川町",
        "latitude": "33.241553",
        "longitude": "130.551505"
      },
      {
        "name": "田川郡香春町",
        "latitude": "33.667933",
        "longitude": "130.847335"
      },
      {
        "name": "田川郡添田町",
        "latitude": "33.571855",
        "longitude": "130.853955"
      },
      {
        "name": "田川郡糸田町",
        "latitude": "33.652789",
        "longitude": "130.779091"
      },
      {
        "name": "田川郡川崎町",
        "latitude": "33.59994",
        "longitude": "130.814937"
      },
      {
        "name": "田川郡大任町",
        "latitude": "33.612478",
        "longitude": "130.853714"
      },
      {
        "name": "田川郡赤村",
        "latitude": "33.616592",
        "longitude": "130.871035"
      },
      {
        "name": "田川郡福智町",
        "latitude": "33.683261",
        "longitude": "130.780121"
      },
      {
        "name": "京都郡苅田町",
        "latitude": "33.775921",
        "longitude": "130.98051"
      },
      {
        "name": "京都郡みやこ町",
        "latitude": "33.699262",
        "longitude": "130.920401"
      },
      {
        "name": "築上郡吉富町",
        "latitude": "33.602643",
        "longitude": "131.175948"
      },
      {
        "name": "築上郡上毛町",
        "latitude": "33.578408",
        "longitude": "131.164113"
      },
      {
        "name": "築上郡築上町",
        "latitude": "33.656112",
        "longitude": "131.056063"
      }
    ]
  },
  {
    "name": "佐賀県",
    "cities": [{
        "name": "佐賀市",
        "latitude": "33.263482",
        "longitude": "130.300858"
      },
      {
        "name": "唐津市",
        "latitude": "33.450174",
        "longitude": "129.968134"
      },
      {
        "name": "鳥栖市",
        "latitude": "33.377755",
        "longitude": "130.506258"
      },
      {
        "name": "多久市",
        "latitude": "33.28851",
        "longitude": "130.110061"
      },
      {
        "name": "伊万里市",
        "latitude": "33.264586",
        "longitude": "129.880269"
      },
      {
        "name": "武雄市",
        "latitude": "33.193749",
        "longitude": "130.019151"
      },
      {
        "name": "鹿島市",
        "latitude": "33.103724",
        "longitude": "130.098769"
      },
      {
        "name": "小城市",
        "latitude": "33.273665",
        "longitude": "130.216864"
      },
      {
        "name": "嬉野市",
        "latitude": "33.128084",
        "longitude": "130.060114"
      },
      {
        "name": "神埼市",
        "latitude": "33.310763",
        "longitude": "130.37306"
      },
      {
        "name": "神埼郡吉野ヶ里町",
        "latitude": "33.351679",
        "longitude": "130.398357"
      },
      {
        "name": "三養基郡基山町",
        "latitude": "33.426852",
        "longitude": "130.522813"
      },
      {
        "name": "三養基郡上峰町",
        "latitude": "33.319626",
        "longitude": "130.426134"
      },
      {
        "name": "三養基郡みやき町",
        "latitude": "33.324982",
        "longitude": "130.454698"
      },
      {
        "name": "東松浦郡玄海町",
        "latitude": "33.472183",
        "longitude": "129.874698"
      },
      {
        "name": "西松浦郡有田町",
        "latitude": "33.210563",
        "longitude": "129.849217"
      },
      {
        "name": "杵島郡大町町",
        "latitude": "33.213754",
        "longitude": "130.116084"
      },
      {
        "name": "杵島郡江北町",
        "latitude": "33.220592",
        "longitude": "130.157528"
      },
      {
        "name": "杵島郡白石町",
        "latitude": "33.181035",
        "longitude": "130.143496"
      },
      {
        "name": "藤津郡太良町",
        "latitude": "33.019407",
        "longitude": "130.178974"
      }
    ]
  },
  {
    "name": "長崎県",
    "cities": [{
        "name": "長崎市",
        "latitude": "32.750286",
        "longitude": "129.877667"
      },
      {
        "name": "佐世保市",
        "latitude": "33.179915",
        "longitude": "129.71511"
      },
      {
        "name": "島原市",
        "latitude": "32.788103",
        "longitude": "130.370286"
      },
      {
        "name": "諫早市",
        "latitude": "32.843438",
        "longitude": "130.053095"
      },
      {
        "name": "大村市",
        "latitude": "32.90009",
        "longitude": "129.958142"
      },
      {
        "name": "平戸市",
        "latitude": "33.368062",
        "longitude": "129.553671"
      },
      {
        "name": "松浦市",
        "latitude": "33.341021",
        "longitude": "129.709045"
      },
      {
        "name": "対馬市",
        "latitude": "34.202662",
        "longitude": "129.28749"
      },
      {
        "name": "壱岐市",
        "latitude": "33.74995",
        "longitude": "129.691352"
      },
      {
        "name": "五島市",
        "latitude": "32.695524",
        "longitude": "128.840829"
      },
      {
        "name": "西海市",
        "latitude": "32.933067",
        "longitude": "129.642977"
      },
      {
        "name": "雲仙市",
        "latitude": "32.835197",
        "longitude": "130.1875"
      },
      {
        "name": "南島原市",
        "latitude": "32.659777",
        "longitude": "130.297864"
      },
      {
        "name": "西彼杵郡長与町",
        "latitude": "32.825189",
        "longitude": "129.875045"
      },
      {
        "name": "西彼杵郡時津町",
        "latitude": "32.828888",
        "longitude": "129.848536"
      },
      {
        "name": "東彼杵郡東彼杵町",
        "latitude": "33.037026",
        "longitude": "129.917149"
      },
      {
        "name": "東彼杵郡川棚町",
        "latitude": "33.072776",
        "longitude": "129.86119"
      },
      {
        "name": "東彼杵郡波佐見町",
        "latitude": "33.13789",
        "longitude": "129.895546"
      },
      {
        "name": "北松浦郡小値賀町",
        "latitude": "33.191081",
        "longitude": "129.058749"
      },
      {
        "name": "北松浦郡佐々町",
        "latitude": "33.238342",
        "longitude": "129.650345"
      },
      {
        "name": "南松浦郡新上五島町",
        "latitude": "32.984645",
        "longitude": "129.073391"
      }
    ]
  },
  {
    "name": "熊本県",
    "cities": [{
        "name": "熊本市中央区",
        "latitude": "32.803225",
        "longitude": "130.708191"
      },
      {
        "name": "熊本市東区",
        "latitude": "32.780522",
        "longitude": "130.768069"
      },
      {
        "name": "熊本市西区",
        "latitude": "32.776358",
        "longitude": "130.647542"
      },
      {
        "name": "熊本市南区",
        "latitude": "32.715333",
        "longitude": "130.678877"
      },
      {
        "name": "熊本市北区",
        "latitude": "32.903544",
        "longitude": "130.694411"
      },
      {
        "name": "八代市",
        "latitude": "32.507437",
        "longitude": "130.601743"
      },
      {
        "name": "人吉市",
        "latitude": "32.210041",
        "longitude": "130.762554"
      },
      {
        "name": "荒尾市",
        "latitude": "32.986836",
        "longitude": "130.432959"
      },
      {
        "name": "水俣市",
        "latitude": "32.21188",
        "longitude": "130.408723"
      },
      {
        "name": "玉名市",
        "latitude": "32.93522",
        "longitude": "130.56284"
      },
      {
        "name": "山鹿市",
        "latitude": "33.017578",
        "longitude": "130.691365"
      },
      {
        "name": "菊池市",
        "latitude": "32.979483",
        "longitude": "130.813333"
      },
      {
        "name": "宇土市",
        "latitude": "32.687385",
        "longitude": "130.658521"
      },
      {
        "name": "上天草市",
        "latitude": "32.587419",
        "longitude": "130.430527"
      },
      {
        "name": "宇城市",
        "latitude": "32.647847",
        "longitude": "130.684365"
      },
      {
        "name": "阿蘇市",
        "latitude": "32.951954",
        "longitude": "131.120955"
      },
      {
        "name": "天草市",
        "latitude": "32.458635",
        "longitude": "130.192982"
      },
      {
        "name": "合志市",
        "latitude": "32.885988",
        "longitude": "130.789707"
      },
      {
        "name": "下益城郡美里町",
        "latitude": "32.639589",
        "longitude": "130.789026"
      },
      {
        "name": "玉名郡玉東町",
        "latitude": "32.9189",
        "longitude": "130.628543"
      },
      {
        "name": "玉名郡南関町",
        "latitude": "33.06161",
        "longitude": "130.541162"
      },
      {
        "name": "玉名郡長洲町",
        "latitude": "32.929678",
        "longitude": "130.452808"
      },
      {
        "name": "玉名郡和水町",
        "latitude": "32.978106",
        "longitude": "130.605835"
      },
      {
        "name": "菊池郡大津町",
        "latitude": "32.87899",
        "longitude": "130.868223"
      },
      {
        "name": "菊池郡菊陽町",
        "latitude": "32.862515",
        "longitude": "130.828623"
      },
      {
        "name": "阿蘇郡南小国町",
        "latitude": "33.09866",
        "longitude": "131.07037"
      },
      {
        "name": "阿蘇郡小国町",
        "latitude": "33.121568",
        "longitude": "131.068144"
      },
      {
        "name": "阿蘇郡産山村",
        "latitude": "32.99576",
        "longitude": "131.216781"
      },
      {
        "name": "阿蘇郡高森町",
        "latitude": "32.827279",
        "longitude": "131.122011"
      },
      {
        "name": "阿蘇郡西原村",
        "latitude": "32.834825",
        "longitude": "130.90301"
      },
      {
        "name": "阿蘇郡南阿蘇村",
        "latitude": "32.821982",
        "longitude": "131.031438"
      },
      {
        "name": "上益城郡御船町",
        "latitude": "32.714616",
        "longitude": "130.801894"
      },
      {
        "name": "上益城郡嘉島町",
        "latitude": "32.740076",
        "longitude": "130.757259"
      },
      {
        "name": "上益城郡益城町",
        "latitude": "32.79143",
        "longitude": "130.816382"
      },
      {
        "name": "上益城郡甲佐町",
        "latitude": "32.651599",
        "longitude": "130.811465"
      },
      {
        "name": "上益城郡山都町",
        "latitude": "32.685453",
        "longitude": "130.990455"
      },
      {
        "name": "八代郡氷川町",
        "latitude": "32.5825",
        "longitude": "130.673786"
      },
      {
        "name": "葦北郡芦北町",
        "latitude": "32.298941",
        "longitude": "130.492978"
      },
      {
        "name": "葦北郡津奈木町",
        "latitude": "32.233842",
        "longitude": "130.439583"
      },
      {
        "name": "球磨郡錦町",
        "latitude": "32.201197",
        "longitude": "130.841115"
      },
      {
        "name": "球磨郡多良木町",
        "latitude": "32.263997",
        "longitude": "130.935784"
      },
      {
        "name": "球磨郡湯前町",
        "latitude": "32.276284",
        "longitude": "130.980902"
      },
      {
        "name": "球磨郡水上村",
        "latitude": "32.314342",
        "longitude": "131.009546"
      },
      {
        "name": "球磨郡相良村",
        "latitude": "32.235269",
        "longitude": "130.798095"
      },
      {
        "name": "球磨郡五木村",
        "latitude": "32.397277",
        "longitude": "130.827816"
      },
      {
        "name": "球磨郡山江村",
        "latitude": "32.246806",
        "longitude": "130.766675"
      },
      {
        "name": "球磨郡球磨村",
        "latitude": "32.252431",
        "longitude": "130.651163"
      },
      {
        "name": "球磨郡あさぎり町",
        "latitude": "32.240316",
        "longitude": "130.897793"
      },
      {
        "name": "天草郡苓北町",
        "latitude": "32.513193",
        "longitude": "130.054804"
      }
    ]
  },
  {
    "name": "大分県",
    "cities": [{
        "name": "大分市",
        "latitude": "33.239558",
        "longitude": "131.609272"
      },
      {
        "name": "別府市",
        "latitude": "33.28462",
        "longitude": "131.491214"
      },
      {
        "name": "中津市",
        "latitude": "33.598221",
        "longitude": "131.188325"
      },
      {
        "name": "日田市",
        "latitude": "33.321333",
        "longitude": "130.940966"
      },
      {
        "name": "佐伯市",
        "latitude": "32.959806",
        "longitude": "131.900058"
      },
      {
        "name": "臼杵市",
        "latitude": "33.126012",
        "longitude": "131.805124"
      },
      {
        "name": "津久見市",
        "latitude": "33.072183",
        "longitude": "131.861277"
      },
      {
        "name": "竹田市",
        "latitude": "32.97372",
        "longitude": "131.398002"
      },
      {
        "name": "豊後高田市",
        "latitude": "33.557229",
        "longitude": "131.444624"
      },
      {
        "name": "杵築市",
        "latitude": "33.417026",
        "longitude": "131.616089"
      },
      {
        "name": "宇佐市",
        "latitude": "33.531972",
        "longitude": "131.349545"
      },
      {
        "name": "豊後大野市",
        "latitude": "32.977536",
        "longitude": "131.584133"
      },
      {
        "name": "由布市",
        "latitude": "33.180013",
        "longitude": "131.426856"
      },
      {
        "name": "国東市",
        "latitude": "33.565313",
        "longitude": "131.731674"
      },
      {
        "name": "東国東郡姫島村",
        "latitude": "33.724537",
        "longitude": "131.645139"
      },
      {
        "name": "速見郡日出町",
        "latitude": "33.369443",
        "longitude": "131.532387"
      },
      {
        "name": "玖珠郡九重町",
        "latitude": "33.228519",
        "longitude": "131.188794"
      },
      {
        "name": "玖珠郡玖珠町",
        "latitude": "33.283132",
        "longitude": "131.15155"
      }
    ]
  },
  {
    "name": "宮崎県",
    "cities": [{
        "name": "宮崎市",
        "latitude": "31.907674",
        "longitude": "131.420241"
      },
      {
        "name": "都城市",
        "latitude": "31.71959",
        "longitude": "131.061621"
      },
      {
        "name": "延岡市",
        "latitude": "32.582272",
        "longitude": "131.665009"
      },
      {
        "name": "日南市",
        "latitude": "31.601932",
        "longitude": "131.378728"
      },
      {
        "name": "小林市",
        "latitude": "31.996731",
        "longitude": "130.972588"
      },
      {
        "name": "日向市",
        "latitude": "32.422944",
        "longitude": "131.623965"
      },
      {
        "name": "串間市",
        "latitude": "31.464539",
        "longitude": "131.228303"
      },
      {
        "name": "西都市",
        "latitude": "32.108554",
        "longitude": "131.401202"
      },
      {
        "name": "えびの市",
        "latitude": "32.045344",
        "longitude": "130.810796"
      },
      {
        "name": "北諸県郡三股町",
        "latitude": "31.730686",
        "longitude": "131.124809"
      },
      {
        "name": "西諸県郡高原町",
        "latitude": "31.928463",
        "longitude": "131.007751"
      },
      {
        "name": "東諸県郡国富町",
        "latitude": "31.990644",
        "longitude": "131.323535"
      },
      {
        "name": "東諸県郡綾町",
        "latitude": "31.999133",
        "longitude": "131.253187"
      },
      {
        "name": "児湯郡高鍋町",
        "latitude": "32.127999",
        "longitude": "131.503355"
      },
      {
        "name": "児湯郡新富町",
        "latitude": "32.068868",
        "longitude": "131.488017"
      },
      {
        "name": "児湯郡西米良村",
        "latitude": "32.226232",
        "longitude": "131.154476"
      },
      {
        "name": "児湯郡木城町",
        "latitude": "32.163969",
        "longitude": "131.473537"
      },
      {
        "name": "児湯郡川南町",
        "latitude": "32.192071",
        "longitude": "131.525868"
      },
      {
        "name": "児湯郡都農町",
        "latitude": "32.256293",
        "longitude": "131.559568"
      },
      {
        "name": "東臼杵郡門川町",
        "latitude": "32.469793",
        "longitude": "131.648589"
      },
      {
        "name": "東臼杵郡諸塚村",
        "latitude": "32.512387",
        "longitude": "131.330405"
      },
      {
        "name": "東臼杵郡椎葉村",
        "latitude": "32.467325",
        "longitude": "131.158118"
      },
      {
        "name": "東臼杵郡美郷町",
        "latitude": "32.437404",
        "longitude": "131.422493"
      },
      {
        "name": "西臼杵郡高千穂町",
        "latitude": "32.71169",
        "longitude": "131.307787"
      },
      {
        "name": "西臼杵郡日之影町",
        "latitude": "32.653816",
        "longitude": "131.388146"
      },
      {
        "name": "西臼杵郡五ヶ瀬町",
        "latitude": "32.68302",
        "longitude": "131.196379"
      }
    ]
  },
  {
    "name": "鹿児島県",
    "cities": [{
        "name": "鹿児島市",
        "latitude": "31.596554",
        "longitude": "130.557116"
      },
      {
        "name": "鹿屋市",
        "latitude": "31.378292",
        "longitude": "130.852077"
      },
      {
        "name": "枕崎市",
        "latitude": "31.2729",
        "longitude": "130.296904"
      },
      {
        "name": "阿久根市",
        "latitude": "32.01447",
        "longitude": "130.192773"
      },
      {
        "name": "出水市",
        "latitude": "32.090572",
        "longitude": "130.352753"
      },
      {
        "name": "指宿市",
        "latitude": "31.25277",
        "longitude": "130.633031"
      },
      {
        "name": "西之表市",
        "latitude": "30.732412",
        "longitude": "130.996835"
      },
      {
        "name": "垂水市",
        "latitude": "31.492734",
        "longitude": "130.700914"
      },
      {
        "name": "薩摩川内市",
        "latitude": "31.813517",
        "longitude": "130.304244"
      },
      {
        "name": "日置市",
        "latitude": "31.633709",
        "longitude": "130.402425"
      },
      {
        "name": "曽於市",
        "latitude": "31.653621",
        "longitude": "131.019391"
      },
      {
        "name": "霧島市",
        "latitude": "31.740899",
        "longitude": "130.762997"
      },
      {
        "name": "いちき串木野市",
        "latitude": "31.714553",
        "longitude": "130.271929"
      },
      {
        "name": "南さつま市",
        "latitude": "31.416612",
        "longitude": "130.323408"
      },
      {
        "name": "志布志市",
        "latitude": "31.495442",
        "longitude": "131.045336"
      },
      {
        "name": "奄美市",
        "latitude": "28.377248",
        "longitude": "129.493741"
      },
      {
        "name": "南九州市",
        "latitude": "31.378262",
        "longitude": "130.441645"
      },
      {
        "name": "伊佐市",
        "latitude": "32.057225",
        "longitude": "130.613105"
      },
      {
        "name": "姶良市",
        "latitude": "31.728228",
        "longitude": "130.627816"
      },
      {
        "name": "鹿児島郡三島村",
        "latitude": "30.79217",
        "longitude": "130.303757"
      },
      {
        "name": "鹿児島郡十島村",
        "latitude": "29.837598",
        "longitude": "129.868198"
      },
      {
        "name": "薩摩郡さつま町",
        "latitude": "31.905701",
        "longitude": "130.45566"
      },
      {
        "name": "出水郡長島町",
        "latitude": "32.199375",
        "longitude": "130.176833"
      },
      {
        "name": "姶良郡湧水町",
        "latitude": "31.95158",
        "longitude": "130.720924"
      },
      {
        "name": "曽於郡大崎町",
        "latitude": "31.429201",
        "longitude": "131.005865"
      },
      {
        "name": "肝属郡東串良町",
        "latitude": "31.385517",
        "longitude": "130.973495"
      },
      {
        "name": "肝属郡錦江町",
        "latitude": "31.243566",
        "longitude": "130.787595"
      },
      {
        "name": "肝属郡南大隅町",
        "latitude": "31.217263",
        "longitude": "130.768098"
      },
      {
        "name": "肝属郡肝付町",
        "latitude": "31.344256",
        "longitude": "130.945079"
      },
      {
        "name": "熊毛郡中種子町",
        "latitude": "30.532822",
        "longitude": "130.95885"
      },
      {
        "name": "熊毛郡南種子町",
        "latitude": "30.414071",
        "longitude": "130.90086"
      },
      {
        "name": "熊毛郡屋久島町",
        "latitude": "30.371299",
        "longitude": "130.666468"
      },
      {
        "name": "大島郡大和村",
        "latitude": "28.358089",
        "longitude": "129.395263"
      },
      {
        "name": "大島郡宇検村",
        "latitude": "28.280731",
        "longitude": "129.297092"
      },
      {
        "name": "大島郡瀬戸内町",
        "latitude": "28.146402",
        "longitude": "129.314902"
      },
      {
        "name": "大島郡龍郷町",
        "latitude": "28.413297",
        "longitude": "129.589351"
      },
      {
        "name": "大島郡喜界町",
        "latitude": "28.316836",
        "longitude": "129.940032"
      },
      {
        "name": "大島郡徳之島町",
        "latitude": "27.726592",
        "longitude": "129.018669"
      },
      {
        "name": "大島郡天城町",
        "latitude": "27.811782",
        "longitude": "128.897702"
      },
      {
        "name": "大島郡伊仙町",
        "latitude": "27.673547",
        "longitude": "128.937795"
      },
      {
        "name": "大島郡和泊町",
        "latitude": "27.392287",
        "longitude": "128.655475"
      },
      {
        "name": "大島郡知名町",
        "latitude": "27.333676",
        "longitude": "128.57373"
      },
      {
        "name": "大島郡与論町",
        "latitude": "27.048527",
        "longitude": "128.414832"
      }
    ]
  },
  {
    "name": "沖縄県",
    "cities": [{
        "name": "那覇市",
        "latitude": "26.212312",
        "longitude": "127.679157"
      },
      {
        "name": "宜野湾市",
        "latitude": "26.281575",
        "longitude": "127.778634"
      },
      {
        "name": "石垣市",
        "latitude": "24.340661",
        "longitude": "124.15558"
      },
      {
        "name": "浦添市",
        "latitude": "26.245816",
        "longitude": "127.721799"
      },
      {
        "name": "名護市",
        "latitude": "26.591547",
        "longitude": "127.977316"
      },
      {
        "name": "糸満市",
        "latitude": "26.123584",
        "longitude": "127.665815"
      },
      {
        "name": "沖縄市",
        "latitude": "26.334427",
        "longitude": "127.805583"
      },
      {
        "name": "豊見城市",
        "latitude": "26.161006",
        "longitude": "127.668944"
      },
      {
        "name": "うるま市",
        "latitude": "26.427062",
        "longitude": "127.829081"
      },
      {
        "name": "宮古島市",
        "latitude": "24.80549",
        "longitude": "125.281149"
      },
      {
        "name": "南城市",
        "latitude": "26.144452",
        "longitude": "127.766967"
      },
      {
        "name": "国頭郡国頭村",
        "latitude": "26.745635",
        "longitude": "128.177907"
      },
      {
        "name": "国頭郡大宜味村",
        "latitude": "26.701708",
        "longitude": "128.120164"
      },
      {
        "name": "国頭郡東村",
        "latitude": "26.633309",
        "longitude": "128.156911"
      },
      {
        "name": "国頭郡今帰仁村",
        "latitude": "26.682577",
        "longitude": "127.972628"
      },
      {
        "name": "国頭郡本部町",
        "latitude": "26.657577",
        "longitude": "127.897831"
      },
      {
        "name": "国頭郡恩納村",
        "latitude": "26.4975",
        "longitude": "127.853557"
      },
      {
        "name": "国頭郡宜野座村",
        "latitude": "26.481659",
        "longitude": "127.975589"
      },
      {
        "name": "国頭郡金武町",
        "latitude": "26.456126",
        "longitude": "127.925936"
      },
      {
        "name": "国頭郡伊江村",
        "latitude": "26.713482",
        "longitude": "127.80707"
      },
      {
        "name": "中頭郡読谷村",
        "latitude": "26.396055",
        "longitude": "127.744277"
      },
      {
        "name": "中頭郡嘉手納町",
        "latitude": "26.361662",
        "longitude": "127.755322"
      },
      {
        "name": "中頭郡北谷町",
        "latitude": "26.320119",
        "longitude": "127.763776"
      },
      {
        "name": "中頭郡北中城村",
        "latitude": "26.30111",
        "longitude": "127.793149"
      },
      {
        "name": "中頭郡中城村",
        "latitude": "26.267379",
        "longitude": "127.791126"
      },
      {
        "name": "中頭郡西原町",
        "latitude": "26.222845",
        "longitude": "127.758811"
      },
      {
        "name": "島尻郡与那原町",
        "latitude": "26.199577",
        "longitude": "127.754768"
      },
      {
        "name": "島尻郡南風原町",
        "latitude": "26.191119",
        "longitude": "127.728551"
      },
      {
        "name": "島尻郡渡嘉敷村",
        "latitude": "26.197343",
        "longitude": "127.364286"
      },
      {
        "name": "島尻郡座間味村",
        "latitude": "26.228892",
        "longitude": "127.303157"
      },
      {
        "name": "島尻郡粟国村",
        "latitude": "26.58237",
        "longitude": "127.227187"
      },
      {
        "name": "島尻郡渡名喜村",
        "latitude": "26.372061",
        "longitude": "127.141021"
      },
      {
        "name": "島尻郡南大東村",
        "latitude": "25.828684",
        "longitude": "131.232015"
      },
      {
        "name": "島尻郡北大東村",
        "latitude": "25.945951",
        "longitude": "131.298994"
      },
      {
        "name": "島尻郡伊平屋村",
        "latitude": "27.039054",
        "longitude": "127.968666"
      },
      {
        "name": "島尻郡伊是名村",
        "latitude": "26.928363",
        "longitude": "127.941209"
      },
      {
        "name": "島尻郡久米島町",
        "latitude": "26.349033",
        "longitude": "126.747248"
      },
      {
        "name": "島尻郡八重瀬町",
        "latitude": "26.121745",
        "longitude": "127.74253"
      },
      {
        "name": "宮古郡多良間村",
        "latitude": "24.669397",
        "longitude": "124.701668"
      },
      {
        "name": "八重山郡竹富町",
        "latitude": "24.286667",
        "longitude": "123.881667"
      },
      {
        "name": "八重山郡与那国町",
        "latitude": "24.467969",
        "longitude": "123.004453"
      }
    ]
  }
];

/*
export default [
{id: "1", text: "北海道"},
{id: "2", text: "青森県"},
{id: "3", text: "岩手県"},
{id: "4", text: "宮城県"},
{id: "5", text: "秋田県"},
{id: "6", text: "山形県"},
{id: "7", text: "福島県"},
{id: "8", text: "茨城県"},
{id: "9", text: "栃木県"},
{id: "10", text: "群馬県"},
{id: "11", text: "埼玉県"},
{id: "12", text: "千葉県"},
{id: "13", text: "東京都"},
{id: "14", text: "神奈川県"},
{id: "15", text: "新潟県"},
{id: "16", text: "富山県"},
{id: "17", text: "石川県"},
{id: "18", text: "福井県"},
{id: "19", text: "山梨県"},
{id: "20", text: "長野県"},
{id: "21", text: "岐阜県"},
{id: "22", text: "静岡県"},
{id: "23", text: "愛知県"},
{id: "24", text: "三重県"},
{id: "25", text: "滋賀県"},
{id: "26", text: "京都府"},
{id: "27", text: "大阪府"},
{id: "28", text: "兵庫県"},
{id: "29", text: "奈良県"},
{id: "30", text: "和歌山県"},
{id: "31", text: "鳥取県"},
{id: "32", text: "島根県"},
{id: "33", text: "岡山県"},
{id: "34", text: "広島県"},
{id: "35", text: "山口県"},
{id: "36", text: "徳島県"},
{id: "37", text: "香川県"},
{id: "38", text: "愛媛県"},
{id: "39", text: "高知県"},
{id: "40", text: "福岡県"},
{id: "41", text: "佐賀県"},
{id: "42", text: "長崎県"},
{id: "43", text: "熊本県"},
{id: "44", text: "大分県"},
{id: "45", text: "宮崎県"},
{id: "46", text: "鹿児島県"},
{id: "47", text: "沖縄県"}
];
*/

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import layout from './modules/layout';
import map from './modules/map';

const store = new Vuex.Store({
  modules: {
    layout,
    map,
  },
})

export default store

// if (module.hot) {
//   module.hot.accept([
//     './modules/tab1',
//     './modules/tab2',
//     './modules/tab3'
//   ], () => {
//     try {
//       store.hotUpdate({
//         modules: {
//           tab1: require('./modules/tab1').default,
//           tab2: require('./modules/tab2').default,
//           tab3: require('./modules/tab3').default
//         }
//       })
//     } catch (e) {
//       console.log(e.stack)
//     }
//   })
// }

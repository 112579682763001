// Reference: https://github.com/daniellsu/leaflet-betterscale

L.Control.ShirubeScale = L.Control.extend({
  options: {
    // shirube params
    delay: 0,
    minutes: 3,
    distance: 129,
    colorNum: 8,
    colorList: [],
    simpleMode: false,
    colorOnlyMode: false,
    // original params
    maxWidth: 1000,
    position: "bottomleft",
    metric: true,
    imperial: false,
    updateWhenIdle: false,
    strings: {
      distanceUnit: "m",
      minutesUnit: "分",
    }
  },
  // 初期追加
  onAdd: function (t) {
    this._map = t;
    var e = "leaflet-control-shirube-scale";
    var scaleDiv = L.DomUtil.create("div", e);
    this._iScale = scaleDiv;
    var n = this.options;
    var s = L.DomUtil.create("div", e + "-ruler", scaleDiv);
    this._iScaleBlocks = [];
    var a = this._iScaleBlocks;
    for (var i = 0; i < 8; i++) {
      a.push(L.DomUtil.create("div", e + "-ruler-block " + e + "-" + i, s));
    }
    this._addScales(n, e, scaleDiv);
    this.ScaleContainer = scaleDiv;
    t.on(n.updateWhenIdle ? "moveend" : "move", this._update, this);
    t.whenReady(this._update, this);
    return scaleDiv;
  },
  // 削除
  onRemove: function (t) {
    t.off(this.options.updateWhenIdle ? "moveend" : "move", this._update, this);
  },
  // 逃げ地図パラメータを反映
  updateParams: function (params) {
    var { delay, minutes, distance, colorNum, colorList } = params;
    if (minutes != null) this.minutes = minutes;
    if (delay != null && 0 < this.minutes) this.delay = delay / this.minutes;
    if (distance != null) this.distance = distance;
    if (colorNum != null) this.colorNum = colorNum;
    if (colorList != null) this.colorList = colorList;
    this._update();
  },
  // 目盛りを作成
  _addScales: function (t, e, i) {
    this._iDScale = L.DomUtil.create("div", e + "-label-div", i);
    this._iDScaleLabel = L.DomUtil.create("div", e + "-label", this._iDScale);
    this._iDScaleUnit = L.DomUtil.create("div", e + "-unit", this._iDScale);

    this._iDScaleNumbers = [];
    this._iMScale = L.DomUtil.create("div", e + "-label-div2", i);
    this._iMScaleLabel = L.DomUtil.create("div", e + "-label", this._iMScale);
    this._iMScaleUnit = L.DomUtil.create("div", e + "-unit", this._iMScale);
    this._iMScaleNumbers = [];
    for (var i = 0; i < 8; i++) {
      this._iDScaleNumbers.push(L.DomUtil.create("div", e + "-label " + e + "-number-" + i, this._iDScale));
      this._iMScaleNumbers.push(L.DomUtil.create("div", e + "-label " + e + "-number-" + i, this._iMScale));
    }
  },
  // 状態を更新
  _update: function () {
    var t = this._map.getBounds();
    var e = t.getCenter().lat;
    var h = 6378137 * Math.PI * Math.cos(e * Math.PI / 180);
    var n = h * (t.getNorthEast().lng - t.getSouthWest().lng) / 180;
    var o = this._map.getSize();
    var s = this.options;
    var a = 0;
    if (0 < o.x) {
      a = n * (s.maxWidth / o.x);
    }
    this._updateScales(s, a);
  },
  // 目盛りを更新
  _updateScales: function (t, e) {
    if (t.metric && e) this._updateMetric(e);
  },
  _updateMetric: function (t) {
    // 初期設定
    this.simpleMode = false;
    this.colorOnlyMode = false;
    this._iScale.style.visibility = "visible";
    // 避難開始時刻が大きい時は非表示
    if (8 <= this.delay) {
      this._iScale.style.visibility = "hidden";
      return;
    }
    // 目盛りの横の長さを計測
    var mapWidth = this._map.getSize().x;
    var scaleWidth = this._getScaleWidth(this.distance * (this.colorNum - this.delay) / t);
    if (mapWidth - 16 <= scaleWidth) {
      // 目盛りの横サイズが地図の横サイズを超える場合、表示を2色に留める
      this.simpleMode = true;
      scaleWidth = this._getScaleWidth(this.distance * (2 - this.delay) / t);
      if (mapWidth - 16 <= scaleWidth) {
        // 2色でも横サイズを超える場合は非表示
        this._iScale.style.visibility = "hidden";
        return;
      }
    }
    var colorNum = this.simpleMode ? 2 : this.colorNum;
    var colorWidth = scaleWidth / (colorNum - this.delay);
    if (colorWidth < 30) {
      if (colorWidth < 1) {
        // 非常に狭い時は非表示
        this._iScale.style.visibility = "hidden";
        return;
      }
      // 狭い時はラベルなし
      this.colorOnlyMode = true;
    }
    // 単位と横サイズを設定
    this._iDScaleUnit.innerHTML = this.colorOnlyMode ? "" : this.options.strings.distanceUnit;
    this._iMScaleUnit.innerHTML = this.colorOnlyMode ? "" : this.options.strings.minutesUnit;
    this._iDScale.style.width = scaleWidth + "px";
    this._iMScale.style.width = scaleWidth + "px";
    var colorPerSize = 100 / (colorNum - this.delay);
    var colorListSize = this.colorList ? this.colorList.length : null;
    for (var i = 0; i < 8; i++) {
      // 各目盛りを設定
      if (this.colorList) {
        var style = this._iScaleBlocks[i].style;
        if (i < colorListSize && (!this.simpleMode || i < 2)) {
          var colorIndex = i == this.colorNum - 1 ? colorListSize - 1 : i;
          var size = Math.max(0, colorPerSize * (i - this.delay));
          style.backgroundColor = this.colorList[colorIndex].code;
          style.left = size + "%";
          style.width = colorPerSize + "%";
        } else {
          style.width = 0;
        }
      }
      // 各ラベルを設定
      var iWidth = Math.max(0, colorPerSize * (i - this.delay));
      var dNumber = this._iDScaleNumbers[i];
      var mNumber = this._iMScaleNumbers[i];
      dNumber.style.left = iWidth + "%";
      mNumber.style.left = iWidth + "%";
      var judge1 = !this.simpleMode || i < 2;
      if (judge1 && !this.colorOnlyMode && (0 < iWidth || i == 0) && i < this.colorNum && i <= 8) {
        dNumber.innerHTML = this._getDistance(i);
        mNumber.innerHTML = this._getMinutes(i);
      } else {
        if (!this.colorOnlyMode && this.simpleMode && i == 2) {
          this._iDScaleUnit.innerHTML = this._getDistance(i) + " " + this.options.strings.distanceUnit;
          this._iMScaleUnit.innerHTML = this._getMinutes(i) + " " + this.options.strings.minutesUnit;
        }
        dNumber.innerHTML = "";
        mNumber.innerHTML = "";
      }
    }
  },
  _getDistance: function (i) {
    return Math.max(0, Math.round(this.distance * (i - this.delay)));
  },
  _getMinutes: function (i) {
    return this.minutes * (i + (i == 0 ? this.delay : 0));
  },
  _getScaleWidth: function (t) {
    return Math.round(this.options.maxWidth * t);
  },
});

L.control.shirubeScale = function (origOptions) {
  return new L.Control.ShirubeScale(origOptions);
};

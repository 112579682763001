L.drawLocal = {
  draw: {
    toolbar: {
      actions: {
        title: '描画を終了する',
        text: 'キャンセル'
      },
      finish: {
        title: '描画を保存する',
        text: '保存'
      },
      undo: {
        title: '最後のポイントからやり直す',
        text: '1つ戻る'
      },
      buttons: {
        routing: 'ルート',
        polyline: '直線',
        polygon: '多角形',
        rectangle: '四角形',
        circle: '円形',
        marker: 'アイコン',
        circlemarker: '円アイコン'
      }
    },
    handlers: {
      circle: {
        tooltip: {
          start: 'ドラッグで円を描く'
        },
        radius: '大きさ'
      },
      circlemarker: {
        tooltip: {
          start: 'クリックで円アイコンを置く'
        }
      },
      marker: {
        tooltip: {
          start: 'クリックでアイコンを置く'
        }
      },
      routing: {
        error: '<strong>エラー:</strong> 描画できません！',
        tooltip: {
          start: '道路のスタートを決める',
          cont: '道路のゴールを決める',
          end: 'メニューで終了'
        }
      },
      flag: {
        error: '<strong>エラー:</strong> 描画できません！',
        tooltip: {
          start: '逃げ道のゴールを決める',
          cont: '逃げ道のスタートを決める',
          end: 'メニューで終了'
        }
      },
      polygon: {
        tooltip: {
          start: 'クリックでスタート',
          cont: 'クリックを続けて多角形を描く',
          end: '最初か最後の点をクリックして終了'
        }
      },
      polyline: {
        error: '<strong>エラー:</strong> 直線は交差できません！',
        tooltip: {
          start: 'クリックでスタートする',
          cont: 'クリックを続けて直線を引く',
          end: '最後の点をクリックして確定'
        }
      },
      rectangle: {
        tooltip: {
          start: 'ドラッグで四角形を描く'
        }
      },
      simpleshape: {
        tooltip: {
          end: 'マウスを離して終了する'
        }
      }
    }
  },
  edit: {
    toolbar: {
      actions: {
        save: {
          title: '変更を保存します',
          text: '保存'
        },
        cancel: {
          title: '変更をやめます',
          text: 'キャンセル'
        },
        clearAll: {
          title: '全て削除します',
          text: '全削除'
        }
      },
      buttons: {
        edit: 'オブジェを編集する',
        editDisabled: 'レイヤがありません',
        remove: 'オブジェを削除する',
        removeDisabled: 'レイヤがありません'
      }
    },
    handlers: {
      edit: {
        tooltip: {
          text: 'オブジェをドラッグで修正する',
          subtext: 'やり直しはXボタンで！'
        }
      },
      remove: {
        tooltip: {
          text: 'オブジェをクリックで削除する',
          subtext: 'やり直しはXボタンで！'
        }
      }
    }
  }
};

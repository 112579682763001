<template lang="pug">
v-toolbar-items.theme--dark.darken-3.green
  //- ツールバーメニュー
  v-btn.white--text(
    text,
    :icon="iconOnlyMode",
    @click.native.stop="openTileDialog = true"
  )
    v-icon.left.mr-1(dark) map
    span(v-show="!iconOnlyMode") 地図
  v-btn.white--text(
    text,
    :icon="iconOnlyMode",
    @click.native.stop="openLayerDialog = true"
  )
    v-icon.left.mr-1(dark) category
    span(v-show="!iconOnlyMode") 情報
  v-btn.white--text(
    text,
    :icon="iconOnlyMode",
    @click.native.stop="openRouteDialog = true"
  )
    v-icon.left.mr-1(dark) palette
    span(v-show="!iconOnlyMode") 逃げ道
  v-btn.white--text(
    dark,
    color="pink",
    :icon="iconOnlyMode",
    @click.native.stop="openExportDialog = true"
  )
    v-icon.left.mr-1(dark) build
    span(v-show="!iconOnlyMode") ツール
  //- 地図ダイアログ
  v-dialog.theme--white(
    v-show="openTileDialog",
    v-model="openTileDialog",
    content-class="dialogFullContent",
    scrollable
  )
    v-card.white
      v-card-title.headline どの地図を見る？
      v-divider
      v-card-text.cardText
        v-subheader
          v-icon forward
          span 場所を選択する
        v-container.ml-4
          v-row.align-center
            v-col(cols="6", md="6")
              v-select.px-1.input-group--focused(
                v-model="pref",
                label="都道府県",
                single-line,
                :items="prefList",
                item-text="name",
                return-object
              )
            v-col(cols="6", md="6")
              v-select.px-1.input-group--focused(
                v-show="!!pref.name",
                v-model="area",
                label="市町村区",
                single-line,
                :items="areaList",
                prepend-icon="keyboard_double_arrow_right",
                item-text="name",
                item-value="id",
                :loading="loadingArea",
                return-object
              )
        v-subheader
          v-icon forward
          span 地図タイルを選択する
        v-container.ml-4
          v-list
            v-list-item.lighten-4(
              ripple,
              v-for="(item, index) in tileLayerList",
              :key="index",
              :class="{ grey: index % 2 == 0 }",
              href="javascript:;"
            )
              v-list-item-avatar(@click="selectTile(index)")
                v-icon.cyan--text(v-if="index == tile") check_circle
                v-icon(v-else) radio_button_unchecked
              v-list-item-content(@click="selectTile(index)")
                v-list-item-title(v-text="item.text")
              v-list-item-action(@click="openTileLayerInfo(index)")
                v-icon.green--text info
      v-divider
      v-card-actions
        v-btn.blue--text.darken-1(
          text,
          block,
          @click.native="openTileDialog = false"
        ) OK
  //- 逃げ道ダイアログ
  v-dialog.theme--white(
    v-show="openRouteDialog",
    v-model="openRouteDialog",
    content-class="paramsDialog dialogFullContent",
    scrollable
  )
    v-card.white
      v-card-title.headline どんな逃げ道を引く？
      v-divider
      v-card-text.cardText
        //- 1. 避難開始時刻
        v-subheader
          v-icon forward
          span
            b 避難開始時刻は？
            v-btn(
              text,
              icon,
              color="green",
              @click="openParamsInfo('delayMinutes')"
            )
              v-icon info
        v-container.ml-4
          v-row.align-end
            v-col(cols="6", md="4")
              v-text-field.mx-3.text-xs-right(
                suffix="分後",
                :value="delayMinutes",
                @input="inputDelayMinutes($event)",
                type="number",
                filled,
                background-color="teal lighten-5"
              )
            v-col(cols="6", md="8")
              v-slider.mx-3(
                :value="delayMinutes",
                @input="inputDelayMinutes($event)",
                min="0",
                max="30",
                thumb-label="always",
                ticks="always",
                tick-size="2",
                always-dirty
              )
        //- 2. 避難速度
        v-subheader
          v-icon forward
          span
            b 避難速度は？
            v-btn(text, icon, color="green", @click="openParamsInfo('speed')")
              v-icon info
        v-container.ml-4
          v-row.align-end
            v-col(cols="6", md="4")
              v-text-field.mx-3.text-xs-right(
                :suffix="paramMinutesText",
                :value="distance",
                @input="inputDistance($event)",
                type="number",
                filled,
                background-color="teal lighten-5"
              )
            v-col(cols="6", md="8")
              v-slider.mx-3(
                :value="distance",
                @input="inputDistance($event)",
                min="10",
                max="2000",
                thumb-label="always",
                always-dirty
              )
        //- 3. 色数
        v-subheader
          v-icon forward
          span
            b 色数は？
            v-btn(
              text,
              icon,
              color="green",
              @click="openParamsInfo('colorNum')"
            )
              v-icon info
        v-container.ml-4
          v-row.align-end
            v-col(cols="6", md="4")
              v-text-field.mx-3.text-xs-right(
                suffix="色",
                :value="colorNum",
                @input="inputColorNum($event)",
                type="number",
                filled,
                background-color="teal lighten-5",
              )
            v-col(cols="6", md="8")
              v-slider.mx-3(
                :value="colorNum",
                @input="inputColorNum($event)",
                min="2",
                max="8",
                thumb-label="always",
                ticks="always",
                tick-size="2",
                always-dirty
              )
        //- リセットボタン
        v-container.ml-4
          v-btn(block, large, @click.native="resetRouteParams")
            v-icon.mr-2 format_color_reset
            span 初期状態に戻す
      v-divider
      v-card-actions
        v-btn.blue--text.darken-1(
          text,
          block,
          @click.native="openRouteDialog = false"
        ) OK
  //- 情報ダイアログ
  v-dialog.theme--white(
    v-show="openLayerDialog",
    v-model="openLayerDialog",
    content-class="dialogFullContent",
    scrollable
  )
    v-card.white
      v-card-title.headline どの情報を重ねる？
      v-divider
      v-card-text.cardText
        v-subheader.blue--text
          v-icon.blue--text.mr-2 warning
          span 地図を拡大すると表示されます（ズームレベル: 15以上）
        v-subheader
          v-icon forward
          span 全国情報
        v-container.ml-4
          v-list
            //- 道路中心線
            v-list-item.lighten-4(ripple, href="javascript:;")
              v-list-item-action
                v-switch(v-model="visibleRdclLayer")
              v-list-item-content(@click="visibleRdclLayer = !visibleRdclLayer")
                v-list-item-title {{ layersInfo.rdcl.title }}
              v-list-item-action(@click="openLayerInfo('rdcl')")
                v-icon.green--text info
            //- 避難場所
            v-list-item.lighten-4.grey(ripple, href="javascript:;")
              v-list-item-action
                v-switch(v-model="visibleShelterLayer")
              v-list-item-content(
                @click="visibleShelterLayer = !visibleShelterLayer"
              )
                v-list-item-title {{ layersInfo.shelters.title }}
              v-list-item-action(@click="openLayerInfo('shelters')")
                v-icon.green--text info
        v-subheader
          v-icon forward
          span 津波浸水想定図
        //- 津波浸水想定図
        v-container.ml-4
          v-system-bar(height="30", lights-out, style="margin-bottom:-16px;")
            v-row(no-gutters)
              v-col.text-caption.blue--text(style="max-width:70px;text-align:center;") 通常
              v-col.text-caption.blue--text(style="max-width:70px;text-align:center;") マスク
              v-col
          v-list
            v-list-item.lighten-4(
              v-for="(flood, index) in floods",
              :key="index",
              :class="{ grey: index % 2 == 0 }"
            )
              v-list-item-action
                v-switch(v-model="floods[index].main.visible")
              v-list-item-action(style="margin-right: 32px;")
                v-switch(v-model="floods[index].mask.visible")
              v-list-item-content
                v-list-item-title {{ floods[index].title }}
              v-list-item-action(@click="openLayerInfo('floods', index)")
                v-icon.green--text info
      v-divider
      v-card-actions
        v-btn.blue--text.darken-1(
          text,
          block,
          @click.native="openLayerDialog = false"
        ) OK
  //- ツールダイアログ
  v-dialog.theme--white(
    v-show="openExportDialog",
    v-model="openExportDialog",
    content-class="dialogFullContent",
    scrollable
  )
    v-card.white
      v-card-title.headline 逃げ地図を完成させよう！
      v-divider
      v-card-text.cardText
        //- 1. データ入出力ツール
        v-subheader
          v-icon forward
          span データ入出力ツール
        v-container.ml-4
          v-row.align-end
            v-col(cols="12", sm="6")
              v-card(flat)
                v-card-text
                  v-btn(
                    block,
                    large,
                    style="height: 48px",
                    @click.native="uploadFile",
                    :disabled="uploading",
                    :loading="uploading"
                  )
                    v-icon.mr-2 upload
                    span データをアップロード
                    input.d-none(
                      ref="dataUploader",
                      type="file",
                      accept="application/JSON",
                      @change="changeUploadFile"
                    )
            v-col(cols="12", sm="6")
              v-card(flat)
                v-card-text
                  v-btn.success(
                    block,
                    large,
                    style="height: 48px",
                    @click.native="downloadFile",
                    :disabled="downloading",
                    :loading="downloading"
                  )
                    v-icon.mr-2 download
                    span データをダウンロード
        //- 2. 一括設置・描画ツール
        v-subheader
          v-icon forward
          span 一括設置・描画ツール

        v-container.ml-4
          v-row
            v-col(cols="12", sm="6")
              v-card(flat)
                v-card-title.pb-0
                  v-icon navigate_next
                  span
                    b 避難目標地点の一括設置
                    v-btn(
                      text,
                      icon,
                      color="green",
                      @click="openParamsInfo('safe')"
                    )
                      v-icon info
                v-card-text
                  span 周辺の浸水区域と道路の交点を自動的に判定し、「避難目標地点」を一括して設置できます。ただし、<u>実行に負担と時間がかかります</u>。
                  v-flex(xs12)
                    v-text-field.mt-2.mx-3.text-xs-right(
                      label="判定制限値",
                      hint="数を大きくするほど設置数を限定します",
                      v-model="safePointsThreshold",
                      type="number",
                      min="0",
                      @change="changeSafePointsThreshold",
                      filled,
                      background-color="teal lighten-5"
                    )
                  v-btn.primary(
                    block,
                    large,
                    style="height: 48px",
                    @click.native="startAllAutoMarkers",
                    :disabled="!floodArea.geometries"
                  )
                    v-icon.mr-2 opacity
                    span 一括設置
                  v-btn(block, large, @click.native="removeAllAutoMarkers")
                    v-icon.mr-2 format_color_reset
                    span 一括リセット
            v-col(cols="12", sm="6")
              v-card(flat)
                v-card-title.pb-0
                  v-icon navigate_next
                  span
                    b 逃げ道の一括描画
                    v-btn(
                      text,
                      icon,
                      color="green",
                      @click="openParamsInfo('escape')"
                    )
                      v-icon info
                v-card-text
                  span 設置した画面内の「避難場所」と「避難目標地点」から、「逃げ道」を一括して描画できます。ただし、<u>実行に負担と時間がかかります</u>。
                  v-flex(xs12)
                    v-text-field.display-marker-field.mx-3.text-xs-center(
                      disabled,
                      prefix="現在:",
                      suffix="ヶ所",
                      :value="exportMarkers.length"
                    )
                  v-btn.primary(
                    block,
                    large,
                    style="height: 48px",
                    @click.native="startAllAuto",
                    :disabled="!exportMarkers.length"
                  )
                    v-icon.mr-2 opacity
                    span 一括描画
                  v-btn(block, large, @click.native="resetAllAuto")
                    v-icon.mr-2 format_color_reset
                    span 一括リセット
        //- 3. 印刷ツール
        v-subheader
          v-icon forward
          span 印刷ツール
        v-container.ml-4
          v-row.align-end
            v-col(cols="12", sm="6")
              v-card(flat)
                v-card-text
                  //- 地図プレビュー
                  #preview.previewArea(
                    @click="(e) => { updatePreview(10); }"
                  )
                    .previewMask 印刷プレビュー（クリックで更新）
                    img#previewImage
            v-col(cols="12", sm="6")
              v-card(flat)
                v-card-text
                  //- 地図の出力タイプ
                  v-select.px-1.input-group--focused(
                    v-model="printType",
                    label="出力タイプ",
                    :items="printList.printTypeList",
                    item-value="type",
                    item-text="name",
                    return-object
                  )
                  //- 縮尺
                  v-select.px-1.input-group--focused(
                    v-model="zoomType",
                    label="縮尺",
                    :items="printList.zoomList",
                    item-value="level",
                    item-text="name",
                    return-object
                  )
                  //- 印刷サイズ
                  v-select.px-1.input-group--focused(
                    v-model="sizeType",
                    label="印刷サイズ",
                    :items="printList.sizeList",
                    item-value="type",
                    item-text="name",
                    return-object
                  )
                  //- 凡例の位置
                  v-select.px-1.input-group--focused(
                    v-model="legend",
                    label="凡例の位置",
                    :items="printList.legendList",
                    item-value="position",
                    item-text="name",
                    return-object
                  )
                  v-btn.mx-1(
                    block,
                    large,
                    dark,
                    color="pink",
                    style="height: 48px",
                    @click.native="() => { print(); }"
                  )
                    v-icon.mr-2 print
                    span 印刷
      v-divider
      v-card-actions
        v-btn.blue--text.darken-1(
          text,
          block,
          @click.native="openExportDialog = false"
        ) 閉じる
  //- 注釈ダイアログ
  v-dialog(v-model="openNotesDialog", content-class="dialogMiniContent")
    v-card.white
      v-card-title
        v-icon.green--text info
        span.headline {{ notes.title }}
      v-divider
      v-card-text.pa-2
        ul
          li(
            v-for="content in notes.contents",
            v-html="content.text",
            :class="[content.class]"
          )
      v-divider
      v-card-actions
        v-btn.blue--text.darken-1(
          text,
          block,
          @click.native="openNotesDialog = false"
        ) 閉じる
  //- 成功・失敗ダイアログ
  v-dialog(
    v-model="openResultDialog",
    persistent,
    content-class="dialogMiniContent"
  )
    v-card.white
      v-card-title
        v-icon(:class="resultInfo.classes") {{ resultInfo.icon }}
        span.pl-2 {{ resultInfo.title }}
      v-card-text.pa-2 {{ resultInfo.text }}
      v-divider
      v-card-actions
        v-btn.blue--text.darken-1(
          text,
          block,
          @click.native="openResultDialog = false"
        ) OK
</template>

<script>
import L from "leaflet";
import { mapActions, mapGetters } from "vuex";
import GsiLoader from "../tools/gsiLoader";
import dayjs from "dayjs";
import { point, destination, getCoords } from "@turf/turf";

export default {
  props: {
    iconOnlyMode: Boolean,
  },
  computed: {
    ...mapGetters("map", [
      "map",
      "scaleControl",
      "tile",
      "baseLayer",
      "delayMinutes",
      "distance",
      "minutes",
      "colorNum",
      "markerList",
      "tileLayerList",
      "drawLayer",
      "prefList",
      "printList",
      "layersInfo",
      "paramsInfo",
      "gsiLayer",
      "dSheltersLayer",
      "editMode",
      "colorsLayer",
      "colorCanvas",
    ]),
    tileLayerTexts() {
      return this.tileLayerList.map((tile) => tile.text);
    },
    paramMinutesText() {
      return "m/" + this.minutes + "分";
    },
    floodOption() {
      return {
        vectorTileLayerStyles: {
          rendererFactory: L.mask,
          sliced: {
            color: "#063a80",
            fill: true,
            fillColor: "#063a80",
            "stroke-width": 2,
            fillOpacity: 0.1,
            dashArray: "2, 6",
          },
        },
        maxZoom: 18,
        minZoom: 14,
      };
    }
  },
  watch: {
    openLayerDialog(value) {
      if (value) {
        // 開いた
      } else {
        // 閉じた
        this.saveLayers();
      }
    },
    openTileDialog(value) {
      if (value) {
        // 開いた
        this.selectArea = {};
      }
    },
    openRouteDialog(value) {
      if (value) {
        // 開いた
        document
          .querySelectorAll(".leaflet-top, .leaflet-bottom")
          .forEach((controls) => {
            if (
              !!controls.querySelectorAll(".leaflet-control-shirube-scale")
                .length
            ) {
              controls.classList.add("shirube-preview");
            }
          });
      } else {
        // 閉じた
        document
          .querySelectorAll(".leaflet-top, .leaflet-bottom")
          .forEach((controls) => {
            controls.classList.remove("shirube-preview");
          });
      }
    },
    openExportDialog(value) {
      if (value) {
        // 開いた
        this.initExport();
      } else if (!this.printMode) {
        // 閉じた
        this.resetSizeType();
      }
    },
    pref(obj) {
      // 都道府県から市町村区を取得
      if (obj && obj.cities) {
        const { cities } = obj;
        this.areaList = cities;
        if (0 < cities.length) {
          this.area = cities[0];
        }
      }
    },
    area(obj) {
      // 市町村データから移動
      if (obj && obj.latitude)
        this.map.flyTo([obj.latitude, obj.longitude], 13);
    },
    printType(obj) {
      // 印刷タイプを取得
      if (obj) {
        if (obj.type == "white") {
          this.tileLayerList.map((tileLayer, i) => {
            if (tileLayer.text == "GSI: 標準地図(白黒)") this.setTile(i);
          });
        } else {
          this.setTile(this.tempTileLayer);
        }
        this.map.invalidateSize(false);
        this.updatePreview();
      }
    },
    zoomType(obj) {
      // 縮尺を取得
      if (obj) {
        let zoom = this.tempZoom;
        if (obj.level) zoom = obj.level;
        this.map.setZoom(zoom);
        this.map.invalidateSize(false);
        this.updatePreview();
      }
    },
    sizeType(obj) {
      if (obj) {
        document.getElementById("map-area").className =
          this.mapClass + " " + this.sizeType.type;
        this.map.invalidateSize(false);
        this.updatePreview();
      }
    },
    legend(obj) {
      // 凡例の位置を取得
      if (obj) {
        // this.setLegendPosition(obj.position);
        if (!!this.scaleControl._map) this.scaleControl.remove();
        if (obj.position) this.setScaleControl({ position: obj.position });
        this.map.invalidateSize(false);
        this.updatePreview();
      }
    },
  },
  methods: {
    ...mapActions("map", [
      "setTile",
      "setScaleControl",
      "setDelayMinutes",
      "setDistance",
      "setColorNum",
      "setViewsColorLayer",
      "startAutoSafePoints",
      "startAutoShirube",
      "addRouteData",
      "selectMarker",
      "refreshScaleControl",
      "refreshColorsLayer",
      "addMarker",
      "addExRoute",
      "clearCanvas",
    ]),
    ...mapActions("layout", ["setMultiProgress"]),
    // 地図タイルを選択する
    selectTile(index) {
      this.setTile(index);
    },
    // 遅れを入力する
    inputDelayMinutes(value) {
      if (value == "" || !parseInt(value)) {
        value = adminData.delayMinutes ?? this.defaults.delayMinutes;
      }
      this.setDelayMinutes(parseInt(value));
      this.refreshScaleControl({
        delay: this.delayMinutes,
      });
    },
    // 距離を入力する
    inputDistance(value) {
      if (value == "" || !parseInt(value)) {
        value = adminData.distance ?? this.defaults.distance;
      }
      this.setDistance(parseInt(value));
      this.refreshScaleControl({
        distance: this.distance,
      });
    },
    // 色数を入力する
    inputColorNum(value) {
      if (value == "" || !parseInt(value)) {
        value = adminData.colorNum ?? this.defaults.colorNum;
      }
      this.setColorNum(parseInt(value));
      this.refreshScaleControl({
        colorNum: this.colorNum,
      });
    },
    // 逃げ道パラメータを初期化する
    resetRouteParams() {
      const { delayMinutes, distance, colorNum } = adminData;
      this.inputDelayMinutes(delayMinutes ?? this.defaults.delayMinutes);
      this.inputDistance(distance ?? this.defaults.distance);
      this.inputColorNum(colorNum ?? this.defaults.colorNum);
    },
    // 閾値を入力変更する
    changeSafePointsThreshold(value) {
      if (value === "" || value < 0)
        this.safePointsThreshold = this.defaults.safePointsThreshold;
    },
    // タイルの注釈モーダルを開く
    openTileLayerInfo(index) {
      this.openNotesDialog = true;
      const tileItem = this.tileLayerList[index];
      this.notes.title = tileItem.text;
      this.notes.contents = [
        { class: null, text: "<b>タイル名</b>: " + tileItem.name },
        { class: null, text: "<b>出典</b>: " + tileItem.attribution },
      ];
    },
    // 情報レイヤの注釈モーダルを開く
    openLayerInfo(layerType, index) {
      this.openNotesDialog = true;
      if (layerType === "floods" && this.floods.length) {
        if (!index) index = 0;
        this.notes.title = this.floods[index].title;
        this.notes.contents = this.floods[index].contents;
      } else {
        this.notes = this.layersInfo[layerType];
      }
    },
    // パラメータレイヤの注釈モーダルを開く
    openParamsInfo(paramType) {
      this.openNotesDialog = true;
      this.notes = this.paramsInfo[paramType];
    },
    // 浸水域表示状態を切り替える
    selectFloodItem(type, index) {
      console.log(type);
      for (var i = 0, length = this.floods.length; i < length; i++) {
        const visible = this.floods[index][type].visible;
        if (i === index) {
          this.floods[i][type].visible = !visible;
        } else if (visible) {
          this.floods[i][type].visible = false;
        }
      }
    },
    // レイヤ表示状態を更新する
    saveLayers() {
      const { baseLayer } = this;
      if (baseLayer) {
        if (baseLayer.listens("tileload")) baseLayer.off("tileload");
        baseLayer.redraw();
      }
      // 各レイヤの表示
      this.toggleVisibleLayer(this.gsiLayer, this.visibleRdclLayer, this.gsiRoadLoader);
      this.toggleVisibleLayer(this.dSheltersLayer, this.visibleShelterLayer, this.gsiShelterLoader);
      this.floods.map((flood) => {
        this.toggleVisibleFloodLayer(flood.main.layer, flood.main.visible);
        this.toggleVisibleFloodLayer(flood.mask.layer, flood.mask.visible);
      });
    },
    // 全国情報を切り替える
    toggleVisibleLayer(layer, isVisible, loader) {
      if (!layer) return;
      const {map, baseLayer} = this;
      if (map.hasLayer(layer)) {
        layer.clearLayers();
        map.removeLayer(layer);
      } else if (isVisible){
        if (baseLayer) {
            baseLayer.on(
              "tileload",
              (ev) => {
                if (loader) loader.start(ev.coords);
              },
              this
            );
          }
          if (!map.hasLayer(layer)) map.addLayer(layer);
      }
    },
    // 浸水域情報を追加する
    toggleVisibleFloodLayer(layer, isVisible) {
      if (!layer) return;
      const { map } = this;
      if (map.hasLayer(layer)) {
        map.removeLayer(layer);
      }
      if (isVisible && !map.hasLayer(layer))
        map.addLayer(layer);
    },
    // PBFファイルを読み込む
    loadGeobuf(file, index, callback) {
      const oReq = new XMLHttpRequest();
      oReq.open("GET", file, true);
      oReq.responseType = "arraybuffer";
      oReq.onload = function (oEvent) {
        const Pbf = require("pbf");
        const geobuf = require("geobuf");
        const pd = new Pbf(new Uint8Array(oReq.response));
        callback(index, geobuf.decode(pd));
      };
      oReq.send();
    },
    // レイヤーを追加する
    initViewLayers() {
      const _this = this;
      // 値の初期化
      this.legend =
        adminData && adminData.legend
          ? adminData.legend
          : this.printList.legendList[3];
      // レイヤの準備
      if (!this.gsiLayer) return;
      // 道路中心線レイヤを準備する
      var color = null;
      if (adminData && adminData.colors) {
        color = adminData.colors[adminData.colors.length - 1];
      }
      this.gsiRoadLoader = new GsiLoader(
        color,
        this.gsiLayer,
        (data) => {
          if (!data.error) {
            // geojson = require('simplify-geojson')(geojson, 0.0001);
            // const geojsonLayer = L.geoJson.multiStyle(
            const geojsonLayer = L.geoJson(
              JSON.parse(data.result),
              data.options
            );
            if (
              !_this.gsiLayer
                .getLayers()
                .some((layer) => geojsonLayer.options.key === layer.options.key)
            ) {
              if (!_this.gsiLayer.hasLayer(geojsonLayer)) {
                _this.gsiLayer.addLayer(geojsonLayer);
              }
              _this.addRouteData({
                geojson: geojsonLayer.toGeoJSON(),
                position: data.position,
              });
            }
          }
        },
        { pane: "baseRoute" }
      );
      // 指定緊急避難場所レイヤを準備する
      const assets =
        process.env.NODE_ENV === "production"
          ? "../dist/assets/"
          : "../assets/";
      const myIcon = L.icon({
        iconUrl: assets + "img/shelter-emergency-marker_deep.svg",
        iconSize: [35, 45],
        iconAnchor: [17, 42],
      });
      this.dShelterCanvasLayer = L.canvas({ pane: "pointMarker" });
      this.gsiShelterLoader = new GsiLoader(
        null,
        this.dSheltersLayer,
        (data) => {
          if (!data.error) {
            data.options.renderer = _this.dShelterCanvasLayer;
            const geojsonLayer = L.geoJson(
              JSON.parse(data.result),
              data.options
            );
            const isRep = _this.dSheltersLayer.getLayers().some((layer) => {
              return layer.options.key === data.options.key;
            });
            if (!isRep) _this.dSheltersLayer.addLayer(geojsonLayer);
          }
        },
        {
          ts: "skhb04",
          pane: "pointMarker",
          pointToLayer: (feature, latlng) => {
            // マーカを設置する
            const marker = L.marker(latlng, {
              className: "icon-shelter",
              icon: myIcon,
              markerId: 0,
              colorCanvas: _this.colorCanvas,
            });
            marker.on("click", (e) => {
              if (_this.editMode) return false;
              e.originalEvent.preventDefault();
              _this.selectMarker({
                icon: marker,
                info: feature.properties,
                type: 0,
              });
              e.originalEvent.stopPropagation();
              return false;
            });
            return marker;
          },
          minZoom: 15,
          maxNativeZoom: 10,
        }
      );
      // 浸水域レイヤを準備する
      this.floods = [...this.layersInfo.floods];
      // カスタム浸水域レイヤがある場合は追加
      if (adminData && adminData.isCustomFlood) {
        this.isCustomFlood = true;
        const customFlood = {
          title: adminData.floodTitle || this.defaults.floodTitle,
          name: process.env.NODE_ENV === "production"
              ? "custom.prod.pbf"
              : "custom.dev.pbf",
          contents: [
            { class: null, text: adminData.floodNote || this.defaults.floodNote },
          ]
        };
        this.floods.push(customFlood);
      }
      for (var i = 0, length = this.floods.length; i < length; i++) {
        let flood = this.floods[i];
        this.floods[i].main = {
          layer: null,
          visible: false
        };
        this.floods[i].mask = {
          layer: L.layerGroup({ pane: "flood" }),
          visible: false
        };
        let callback = function (index, geojson) {
          if (!geojson) return;
          _this.floodArea.geometries.push(...geojson.geometries);
          // マスク用を準備
          let latLngs = [];
          const replaceLatlng = (coords) =>
            coords.map((array) => [array[1], array[0]]);
          geojson.geometries[0].coordinates.map((coords) => {
            coords.map((cods) => {
              latLngs.push(new L.Polygon(replaceLatlng(cods)).getLatLngs());
            });
          });
          if (0 < latLngs.length) {
            L.mask(latLngs).addTo(_this.floods[index].mask.layer);
          }
          // 通常用を準備
          _this.floods[index].main.layer = L.vectorGrid.slicer(geojson, _this.floodOption);
        };
        this.loadGeobuf(assets + "data/" + flood.name, i, callback);
      }
    },
    // ファイルデータをアップロードする
    uploadFile() {
      this.$refs.dataUploader.click();
    },
    changeUploadFile(e) {
      const _this = this;
      const reader = new FileReader();
      reader.readAsText(e.target.files[0]);
      reader.addEventListener("load", function () {
        _this.commitUploadFile(JSON.parse(reader.result));
      });
      this.$refs.dataUploader.value = "";
    },
    // ファイルデータを適用する
    commitUploadFile(result) {
      this.uploading = true;
      const _this = this;
      if (!result || typeof result !== "object") return;
      try {
        // 地図情報を反映
        if ("center" in result || "zoom" in result) {
          const center = result.center ?? this.map.getCenter();
          const zoom = result.zoom ?? this.map.getZoom();
          this.map.setView(center, zoom, { animate: false });
        }
        if ("legend" in result) this.legend = result.legend;
        // 逃げ道の設定情報を反映
        if ("delayMinutes" in result)
          this.inputDelayMinutes(result.delayMinutes);
        if ("distance" in result) this.inputDistance(result.distance);
        if ("colorNum" in result) this.inputColorNum(result.colorNum);
        // 地図タイル・自動ジオデータの選択状態を反映
        if ("tile" in result) this.setTile(result.tile);
        if ("layer" in result) {
          const { layer } = result;
          if ("rdc" in layer) this.visibleRdclLayer = !!layer.rdc;
          if ("shelters" in layer) this.visibleShelterLayer = !!layer.shelters;
          if ("flood" in layer) this.visibleFloodLayer = !!layer.flood;
          if ("floodMask" in layer)
            this.visibleFloodMaskLayer = !!layer.floodMask;
          this.saveLayers();
        }
        // 手動ジオデータ（アイコン）の設置状態を反映
        if ("exIcons" in result && 0 < result.exIcons.length) {
          this.refreshColorsLayer();
          const currentLayers = this.drawLayer.getLayers();
          this.drawLayer.eachLayer((layer) => {
            if (layer instanceof L.Marker) {
              _this.clearCanvas(layer._leaflet_id);
              _this.drawLayer.removeLayer(layer._leaflet_id);
            }
          });
          try {
            result.exIcons.forEach((markerInfo) => {
              if (!"id" in markerInfo) return;
              if (!"coords" in markerInfo) return;
              const { markerId, coords } = markerInfo;
              const layer = L.marker([coords[1], coords[0]], {
                icon: new L.icon(_this.markerList[markerId].marker),
                markerId: markerId,
                _markerId: markerId,
                colorCanvas: _this.colorCanvas,
              });
              _this.drawLayer.addLayer(layer);
              _this.addMarker({
                layerId: layer._leaflet_id,
                markerId: markerId,
              });
            });
          } catch (e) {
            this.drawLayer.eachLayer((layer) => {
              if (layer instanceof L.Marker) {
                _this.clearCanvas(layer._leaflet_id);
                _this.drawLayer.removeLayer(layer._leaflet_id);
              }
            });
            currentLayers.forEach((layer) => {
              _this.drawLayer.addLayer(layer);
              _this.addMarker({
                layerId: layer._leaflet_id,
                markerId: layer.options.icon.options.markerId,
              });
            });
            throw Error(e.message);
          }
        }
        // 手動ジオデータ（抜け道）の設置状態を反映
        if ("exCoords" in result && 0 < result.exCoords.length) {
          const currentLayers = this.drawLayer.getLayers();
          this.drawLayer.eachLayer((layer) => {
            if (layer instanceof L.Polyline) {
              _this.drawLayer.removeLayer(layer._leaflet_id);
            }
          });
          try {
            result.exCoords.forEach((exCoord) => {
              _this.addExRoute(exCoord);
            });
          } catch (e) {
            this.drawLayer.eachLayer((layer) => {
              if (layer instanceof L.Polyline) {
                _this.drawLayer.removeLayer(layer._leaflet_id);
              }
            });
            currentLayers.forEach((currentLayer) => {
              _this.drawLayer.addLayer(currentLayer);
            });
            throw Error(e.message);
          }
        }
        // 成功ダイアログを表示
        this.resultInfo.icon = "check-circle";
        this.resultInfo.classes = ["green--text"];
        this.resultInfo.title = "データの適用に成功しました。";
        this.resultInfo.text = "";
        this.openExportDialog = false;
      } catch (e) {
        console.log(e);
        // 失敗ダイアログを表示
        this.resultInfo.icon = "error";
        this.resultInfo.classes = ["red--text"];
        this.resultInfo.title = "データの適用に失敗しました。";
        this.resultInfo.text = e.message;
      } finally {
        this.openResultDialog = true;
        this.uploading = false;
      }
    },
    // ファイルデータをダウンロードする
    downloadFile() {
      this.downloading = true;
      try {
        let result = {};
        // 地図情報を取得
        result.center = this.map.getCenter();
        result.zoom = this.map.getZoom();
        result.legend = this.legend;
        // 逃げ道の設定情報を取得
        result.delayMinutes = this.delayMinutes;
        result.distance = this.distance;
        result.colorNum = this.colorNum;
        // 地図タイル・自動ジオデータの選択状態を取得
        result.tile = this.tile;
        result.layer = {
          rdc: this.visibleRdclLayer,
          shelters: this.visibleShelterLayer,
          flood: this.visibleFloodLayer,
          floodMask: this.visibleFloodMaskLayer,
        };
        // 手動ジオデータ（アイコン・抜け道）の設置状態を取得
        result.exIcons = [];
        result.exCoords = [];
        this.drawLayer.eachLayer((feature) => {
          const { geometry } = feature.toGeoJSON();
          const { type } = geometry;
          if (type === "Point") {
            result.exIcons.push({
              markerId: feature.options.markerId,
              coords: geometry.coordinates,
            });
          } else if (type === "LineString") {
            result.exCoords.push(geometry.coordinates);
          }
        });
        // データ出力を実行
        const json = JSON.stringify(result, null, 2);
        const blob = new Blob([json], { type: "application/json" });
        let element = document.createElement("a");
        element.href = window.URL.createObjectURL(blob);
        element.download = dayjs().format(this.downloadFileName) + ".json";
        element.target = "_blank";
        element.click();
      } catch (e) {
        console.log(e);
        // 失敗ダイアログを表示
        this.resultInfo.icon = "error";
        this.resultInfo.classes = ["red--text"];
        this.resultInfo.title = "データの適用に失敗しました。";
        this.resultInfo.text = e.message;
        this.openResultDialog = true;
      } finally {
        this.downloading = false;
      }
    },
    // ツールダイアログを開いた時の初期処理
    initExport() {
      const { map } = this;
      // 印刷設定を初期化する
      this.printType = this.printList.printTypeList[0];
      this.zoomType = this.printList.zoomList[0];
      this.sizeType = this.printList.sizeList[0];
      // 現在の設定値を記憶する
      this.tempZoom = map.getZoom();
      this.tempTileLayer = this.tile;
      this.exportMarkers = [];
      const bounds = map.getBounds();
      const topLeft = point(L.GeoJSON.latLngToCoords(bounds.getNorthWest()));
      // const exTopLeft = destination(topLeft, 724, -45, { units: "meters" });
      const bottomRight = point(
        L.GeoJSON.latLngToCoords(bounds.getSouthEast())
      );
      // const exBottomRight = destination(bottomRight, 724, 135, {
      //   units: "meters",
      // });
      const exBounds = L.latLngBounds(
        L.GeoJSON.coordsToLatLng(getCoords(topLeft)),
        L.GeoJSON.coordsToLatLng(getCoords(bottomRight))
      );
      // L.rectangle([getCoords(exTopLeft).reverse(), getCoords(exBottomRight).reverse()]).addTo(this.map); // 確認用
      // 浸水レイヤーを初期化する
      const pushTargetLayer = (layer) => {
        if (
          layer instanceof L.Marker &&
          exBounds.contains(layer.getLatLng()) &&
          (layer.options.markerId < 2 || layer.options._markerId < 2)
        ) {
          this.exportMarkers.push(layer);
        }
      };
      if (this.dSheltersLayer) {
        const layers = this.dSheltersLayer.getLayers();
        if (0 < layers.length) layers[0].eachLayer(pushTargetLayer);
      }
      this.drawLayer.eachLayer(pushTargetLayer);
      // this.crossPointsLayer.eachLayer(pushTargetLayer);
      // 地図プレビューを表示
      if (this.simpleMapScreenshoter) {
        this.updatePreview();
      }
    },
    // 周辺の浸水域に一括で避難場所を設置
    startAllAutoMarkers() {
      if (!this.floodArea.geometries) return;
      const _this = this;
      if (this.safePointsThreshold <= 0) this.safePointsThreshold = 0;
      const params = {
        floodData: this.floodArea,
        threshold: this.safePointsThreshold,
      };
      this.startAutoSafePoints(params).then((points) => {
        // 避難目標地点マーカを設置する
        const assets =
          process.env.NODE_ENV === "production"
            ? "../dist/assets/"
            : "../assets/";
        const myIcon = L.icon({
          iconUrl: assets + "img/flag-emergency-marker.svg",
          iconSize: [35, 45],
          iconAnchor: [17, 42],
        });
        points.map((latlng) => {
          let judge = true;
          _this.drawLayer.eachLayer((feature) => {
            if (
              feature.getLatLng &&
              _this.map.distance(feature.getLatLng(), latlng) < 5
            )
              judge = false;
          });
          if (!judge) return;
          const marker = L.marker(latlng, {
            className: "icon-shelter",
            icon: myIcon,
            markerId: 1,
            _markerId: 1,
            colorCanvas: _this.colorCanvas,
          });
          marker.on("click", (e) => {
            if (_this.editMode) return false;
            e.originalEvent.preventDefault();
            _this.selectMarker({
              icon: marker,
              info: points,
              type: 1,
            });
            e.originalEvent.stopPropagation();
            return false;
          });
          _this.drawLayer.addLayer(marker);
        });
      });
      this.openExportDialog = false;
    },
    // ツールにて交差点アイコンを一括削除する
    removeAllAutoMarkers() {
      const _this = this;
      this.drawLayer.eachLayer((layer) => {
        if (layer instanceof L.Marker && layer.options._markerId === 1) {
          _this.drawLayer.removeLayer(layer._leaflet_id);
        }
      });
      this.openExportDialog = false;
    },
    // ツールにて逃げ道を一括追加する
    startAllAuto() {
      this.refreshColorsLayer();
      this.setViewsColorLayer(true);
      const size = this.exportMarkers.length;
      let index = 0;
      Promise.resolve()
        .then(() => {
          return new Promise((resolve) => {
            this.openExportDialog = false;
            resolve(this.setMultiProgress(1));
          });
        })
        .then(() => {
          return Promise.all(
            this.exportMarkers.map((icon) => {
              return this.startAutoShirube({
                icon: icon,
                multiMode: true,
              }).then(() => {
                this.setMultiProgress((100 * ++index) / size);
              });
            })
          ).then((info) => {
            this.setMultiProgress(0);
            // console.log(info)
          });
        });
    },
    // ツールにて逃げ道を一括削除する
    resetAllAuto() {
      this.refreshColorsLayer();
      this.openExportDialog = false;
    },
    // 印刷する
    print() {
      this.printMode = true;
      setTimeout(() => {
        window.print();
      }, 1000);
      this.openExportDialog = false;
    },
    // 印刷サイズを初期化する
    resetSizeType() {
      this.printType = this.printList.printTypeList[0];
      this.sizeType = this.printList.sizeList[0];
      this.map.invalidateSize(false);
      document.getElementById("map-area").className = this.mapClass;
    },
    // 印刷プレビューを更新する
    updatePreview(time = 1000) {
      const _this = this;
      setTimeout(() => {
        _this.simpleMapScreenshoter
          .takeScreen("image", {})
          .then((image) => {
            const previewImage = document.getElementById("previewImage");
            const preview = document.getElementById("preview");
            if (previewImage && preview) {
              previewImage.src = image;
              previewImage.width = preview.width;
            }
          })
          .catch((e) => {
            console.log(e.toString());
          });
      }, time);
    },
    _openTileDialog() {
      this.openTileDialog = true;
    },
    _openLayerDialog() {
      this.openLayerDialog = true;
    },
    _openRouteDialog() {
      this.openRouteDialog = true;
    },
    _openExportDialog() {
      this.openExportDialog = true;
    },
  },
  data() {
    return {
      openTileDialog: false,
      openLayerDialog: false,
      openRouteDialog: false,
      openExportDialog: false,
      openNotesDialog: false,
      openResultDialog: false,
      uploading: false,
      downloading: false,
      dShelterCanvasLayer: null,
      visibleRdclLayer: false,
      visibleShelterLayer: false,
      visibleFloodLayer: false,
      visibleFloodMaskLayer: false,
      gsiRoadLoader: null,
      gsiShelterLoader: null,
      floods: [],
      floodArea: {
        type: "GeometryCollection",
        geometries: []
      },
      pref: {},
      area: {},
      areaList: [],
      loadingArea: false,
      notes: { title: "", contents: [] },
      resultInfo: { icon: "check-circle", classes: ["green--text"], text: "" },
      printType: { name: "現在の地図" },
      legend: { name: "左下", position: "bottomleft" },
      zoomType: { name: "現在のサイズ" },
      sizeType: { name: "表示画面", type: "full" },
      tempZoom: 0,
      printMode: false,
      downloadFileName: "[SHIRUBE_DATA]-YYYYMMDD_HHmmss",
      tempTileLayer: null,
      exportMarkers: [],
      simpleMapScreenshoter: null,
      mapClass: "",
      safePointsThreshold: 2,
      defaults: {
        delayMinutes: 0,
        distance: 129,
        colorNum: 8,
        safePointsThreshold: 2,
        floodTitle: "津波浸水想定図（加工済み）",
        floodNote:
          "「津波浸水想定」は、実際の津波による災害や被害の発生範囲を示すものでないことに注意してください。",
      },
    };
  },
  mounted() {
    const _this = this;
    this.initViewLayers();
    this.selectTile(this.tile);
    this.simpleMapScreenshoter = L.simpleMapScreenshoter({
      hidden: true,
    }).addTo(this.map);
    this.mapClass = document.getElementById("map-area").className;
    window.onafterprint = function (ev) {
      _this.printMode = false;
      _this.resetSizeType();
    };
    this.map.on("zoomend", (ev) => {
      const hasLayer = this.map.hasLayer(this.dSheltersLayer);
      const z = ev.target.getZoom();
      if (15 <= z) {
        if (!hasLayer) {
          this.map.addLayer(this.dSheltersLayer);
        }
      } else if (hasLayer) {
        this.map.removeLayer(this.dSheltersLayer);
      }
    });
  },
};
</script>

export default {
  delayMinutes: {
    title: '避難開始時刻',
    contents: [
      { class: null, text: '地震に伴う揺れや身支度などですぐに避難を開始できない場合があります。『逃げシルべ』では、地震発生から何分後に避難を開始するかを自在に設定できます。' },
      // { class: null, text: '例えば、和歌山県では避難困難区域抽出の際の避難開始時間を5分に設定しています。' },
      { class: null, text: '<b>出典</b> <a href="http://www.loto-lab.com/ngczweb/" target="new">逃げ地図ウェブ</a>' }
    ]
  },
  speed: {
    title: '避難速度',
    contents: [
      { class: null, text: '避難者が3分間に何m移動できるかを設定できます。' },
      { class: null, text: '「逃げ地図」では、足の悪い後期高齢者が傾斜路を移動する歩行速度を標準とし、その平均避難速度を43ｍ/分（<b>129m/3分</b>）と設定しています。夜間や雨天・積雪時などを想定して、歩行速度をその80％（103m/3分）にする、あるいは健常成人の平均歩行速度79m/分（237m/3分）にするなど、この『逃げシルべ』では自在に調整が可能です。' },
      { class: null, text: '<b>出典</b> <a href="http://www.loto-lab.com/ngczweb/" target="new">逃げ地図ウェブ</a>' }
    ]
  },
  colorNum: {
    title: '色数',
    contents: [
      { class: null, text: '「逃げ地図」では、指定緊急避難場所など「避難目標地点」までの避難時間を3分間刻みで色分けしています。『逃げシルべ』では、それを踏まえて、緑色3分以内、黄緑色6分以内、黄色9分以内、橙色12分以内、赤色15分以内、紫色18分以内、茶色21分以内、それを超える場合は黒色の8色を標準として設定しています。' },
      { class: null, text: '『逃げシルべ』では、仮に津波到達時刻を地震発生から10分後とした場合、色数を3色（黄色まで）とすると、地震発生から1分後に避難を開始すれば助かる場所を色分けして表示することができます。' },
      { class: null, text: 'なお、本システムでは【情報】画面から「道路中心線」情報の表示をONにすることで、8色目を表示できるようになっています。' },
      { class: null, text: '<b>出典</b> <a href="http://www.loto-lab.com/ngczweb/" target="new">逃げ地図ウェブ</a>' }
    ]
  },
  safe: {
    title: '避難目標地点',
    contents: [
      { class: null, text: '指定緊急避難場所以外の避難候補先のひとつ。「逃げ地図」づくりでは、津波浸水想定区域の境界線と道路の交点を「避難目標地点」として設定します。' },
      { class: null, text: '本システムでは、津波浸水想定図と道路中心線のデータを利用し、その交点を自動的に判定して「避難目標地点」アイコンを一括して設置できます。' },
      { class: null, text: '<b>出典</b> <a href="http://www.loto-lab.com/ngczweb/" target="new">逃げ地図ウェブ</a>' }
    ]
  },
  escape: {
    title: '逃げ道',
    contents: [
      { class: null, text: '「逃げ地図」では、指定緊急避難場所など「避難目標地点」までの避難時間を3分刻みで色分けしています。そのため、緑は3分以内、黄緑は6分以内で到達できる場所、紫色や茶色はより時間がかかる場所であることが一目でわかります。' },
      { class: null, text: '標準は8色（緑色3分以内、黄緑色6分以内、黄色9分以内、橙色12分以内、赤色15分以内、紫色18分以内、茶色21分以内、それを超える場合は黒色）として設定しています。' },
      { class: null, text: 'なお、本システムでは【情報】画面から「道路中心線」情報の表示をONにすることで、8色目を表示できるようになっています。' },
      { class: null, text: '<b>出典</b> <a href="http://www.loto-lab.com/ngczweb/" target="new">逃げ地図ウェブ</a>' }
    ]
  },
}

import colorList from './map/colors';
import layersInfo from './map/layers';
import paramsInfo from './map/params';
import markerList from './map/markers';
import prefList from './map/pref';
import printList from './map/prints';
import tileLayerList from './map/tiles';

export default {
  // マップオプション
  colorList: colorList,
  layersInfo: layersInfo,
  paramsInfo: paramsInfo,
  markerList: markerList,
  prefList: prefList,
  printList: printList,
  tileLayerList: tileLayerList,
  // 一般
  modeList: [
    { name: 'view', text: '見た目', icon: 'fa-eye', color: 'green' },
    { name: 'paint', text: '編集', icon: 'fa-paint-brush', color: 'blue' },
    // {name:'user', text: 'ユーザ', icon: 'fa-user', color: 'red'},
  ],
  drawOptions: {
    default: {
      position: 'topright',
      draw: {
        polyline: true,
        rectangle: false,
        polygon: false,
        circle: false,
        marker: {}
      },
      edit: {
        remove: true
      },
    },
    icon: {
      icon: 'fa-plus',
      markerColor: 'cyan',
      prefix: 'fa',
    }
  }
}
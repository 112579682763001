import mapConfig from './../../../../../config/map';
import {
  distance as getDistance
} from '@turf/turf';

const state = {
  map: null,
  scaleControl: null,
  drawControl: null,
  mode: 0,
  maxGuidePage: 11,
  guidePage: -1, //-1:disable 0~:enable
  marker: -1,
  editMode: 0, //0:normal, 1:edit, 2:remove
  tile: 0,
  delayMinutes: 0,
  distance: 129,
  minutes: 3,
  colors: null,
  colorNum: 8,
  selectedMarker: null,
  selectedMarkerInfo: {},
  selectedMarkerType: -1,
  drawLayer: null,
  drawLayerNum: 0,
  // viewsDrawLayer: false,
  baseLayer: null,
  gsiLayer: L.layerGroup(),
  dSheltersLayer: L.layerGroup(),
  colorsLayer: L.layerGroup(),
  viewsColorsLayer: false,
  routeData: {},
  exRouteData: [],
  blackData: [],
  colorCanvas: [],
  colorSvg: [],
  legendPosition: 'topleft',
  ...mapConfig
};

// 初期化
if (adminData) {
  const { delayMinutes, distance, colorNum, legend, tile, colors } = adminData;
  if (delayMinutes !== undefined) state.delayMinutes = delayMinutes;
  if (distance !== undefined) state.distance = distance;
  if (colorNum !== undefined) state.colorNum = colorNum;
  if (legend && legend.position) state.legendPosition = legend.position;
  if (tile !== undefined) {
    state.tileLayerList.forEach((tileLayer, index) => {
      if (tileLayer.name == tile) {
        state.tile = index;
        return;
      }
    });
  }
  if (colors && state.colorList.length <= colors.length) {
    state.colorList = state.colorList.map((c, i) => {
      c.code = colors[i];
      return c;
    });
  }
}

for (let c = 0; c < state.colorList.length; c++) {
  state.colorCanvas[c] = L.canvas({ pane: 'colorRoute' + c });
  state.colorSvg[c] = L.svg({ pane: 'colorRoute' + c });
}

const mutations = {
  ['SET_MAP_OBJECT'](state, map) {
    state.map = map;
  },
  ['SET_SCALE_CONTROL'](state, control) {
    state.scaleControl = control;
  },
  ['SET_DRAW_CONTROL'](state, control) {
    state.drawControl = control;
  },
  ['SET_MODE'](state, index) {
    state.mode = index;
  },
  ['SET_GUIDE_PAGE'](state, index) {
    if (index < -1) index = -1;
    if (state.maxGuidePage < index) index = state.maxGuidePage;
    state.guidePage = index;
  },
  ['SET_MARKER_MODE'](state, index) {
    state.marker = index;
  },
  ['SET_EDIT_MODE'](state, index) {
    state.editMode = index;
  },
  ['SET_TILE'](state, data) {
    state.tile = data.index;
    state.baseLayer = data.layer;
  },
  ['SET_DELAY_MINUTES'](state, index) {
    state.delayMinutes = index;
  },
  ['SET_DISTANCE'](state, index) {
    state.distance = index;
  },
  ['SET_COLOR_NUM'](state, num) {
    state.colorNum = num;
  },
  ['SELECT_MARKER'](state, info) {
    if (info.icon !== undefined) state.selectedMarker = info.icon;
    if (info.type !== undefined) state.selectedMarkerType = info.type;
    if (info.info !== undefined) {
      state.selectedMarkerInfo = info.info;
      console.log(info);
    } else {
      state.selectedMarkerInfo = {};
    }
  },
  ['SET_DRAW_LAYER'](state, object) {
    state.drawLayer = object;
  },
  ['REFRESH_DRAW_LAYER'](state) {
    state.drawLayerNum = state.drawLayer ? state.drawLayer.getLayers().length : 0;
  },
  ['SET_GSI_LAYER'](state, object) {
    state.gsiLayer = object;
  },
  ['SET_D_SHELTERS_LAYER'](state, object) {
    state.dSheltersLayer = object;
  },
  ['SET_VIEWS_COLORS_LAYER'](state, flag) {
    state.viewsColorsLayer = flag;
  },
  ['ADD_ROUTE_DATA'](state, data) {
    let { features } = data;
    const { geojson, position, error } = data;
    if (!features && geojson) features = geojson.features;
    if (position) {
      let key = createPosKey(position);
      if (error) {
        state.routeData[key] = { error: 1 };
      } else if (features) {
        state.routeData[key] = features;
      }
    }
  },
  ['CLEAR_ROUTE_DATA'](state) {
    if (30 < Object.keys(state.routeData).length) state.routeData = {};
  },
  ['ADD_EX_ROUTE_DATA'](state, latlngs) {
    if (state.exRouteData.length <= 0) {
      state.exRouteData.push(latlngs);
    } else {
      const latlngsGeojson = L.marker([latlngs[1], latlngs[0]]).toGeoJSON();
      // 1つでもfalse要素が見つかったらfalse
      if (state.exRouteData.every(exRoute => {
          if (exRoute.length !== latlngs.length) return
          return getDistance(latlngsGeojson, L.marker([pos[1], pos[0]]).toGeoJSON(), 'meters') <= 0;
        })) {
        // 新しく追加
        state.exRouteData.push(feature);
      }
    }
  },
  ['REMOVE_EX_ROUTE_DATA'](state, feature) {
    const ex = feature.geometry.coordinates;

    const key = state.exRouteData.findIndex(exRoute => {
      const ex = feature.geometry.coordinates;
      return pos[0] === ex[0] && pos[1] === ex[1];
    })
    if (!state.exRouteData.length) {
      state.exRouteData.push(feature);
    } else {
      const pos = feature.geometry.coordinates;
      if (!state.exRouteData.some(exRoute => {
          const ex = feature.geometry.coordinates;
          return pos[0] === ex[0] && pos[1] === ex[1];
        })) {
        state.exRouteData.push(feature);
      }
    }
  },
  ['ADD_BLACK_DATA'](state, pos) {
    if (!state.blackData.some(bp => bp && bp[0] === pos[0] && bp[1] === pos[1])) {
      state.blackData.push(pos);
    }
  },
  ['REMOVE_BLACK_DATA'](state, pos) {
    const key = state.blackData.findIndex(bp => bp && bp[0] === pos[0] && bp[1] === pos[1]);
    if (0 <= key) {
      // key番目を削除してlengthを減らす
      state.blackData.splice(key, 1);
    }
  },
  ['SET_LEGEND_POSITION'](state, pos) {
    // state.legendPosition = pos;
    if (!state.scaleControl) return;
    state.scaleControl.options.position = pos;
  },
  ['REFRESH_SCALE_CONTROL'](state, params) {
    if (!state.scaleControl) return;
    state.scaleControl.updateParams(params);
  },
  ['REFRESH_COLORS_LAYER'](state, params) {
    state.viewsColorsLayer = false;
    const { map } = state;
    const clearColorsLayer = () => {
      let clearCanvas = (canvas, obj) => {
        canvas.remove();
        canvas = null;
        canvas = obj;
      };
      state.colorCanvas.map((canvas, c) =>
        clearCanvas(canvas, L.canvas({ pane: "colorRoute" + c }))
      );
      state.colorSvg.map((canvas, c) =>
        clearCanvas(canvas, L.svg({ pane: "colorRoute" + c }))
      );
      state.colorsLayer = null;
      state.colorsLayer = L.layerGroup();
    };

    // 削除
    if (map.hasLayer(state.colorsLayer)) map.removeLayer(state.colorsLayer);
    clearColorsLayer();

    map.addLayer(state.colorsLayer);
    map.on('zoomend', ev => {
      const hasLayer = map.hasLayer(state.colorsLayer);
      if (state.viewsColorsLayer) {
        const z = ev.target.getZoom();
        if (16 <= z) {
          if (hasLayer) {
            if (17 <= z) {
              state.colorsLayer.eachLayer(layer => layer.setStyle({ weight: 7 }));
            } else {
              state.colorsLayer.eachLayer(layer => layer.setStyle({ weight: 5 }));
              // state.colorsLayer.setStyle({ weight: 5 });
            }
          } else {
            map.addLayer(state.colorsLayer);
          }
        } else if (hasLayer) {
          map.removeLayer(state.colorsLayer);
        }
      } else if (hasLayer) {
        clearColorsLayer();
      }
    });
  },
}

function createPosKey(position) {
  let key = position.z || "16";
  key += "-";
  key += position.x;
  key += "-";
  key += position.y;
  return key;
}


import * as getters from "./getters";
import * as actions from "./actions";

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default [{
    type: 'provider',
    text: 'Esri: 道路地図',
    name: 'Esri.WorldStreetMap',
    title: 'WorldStreetMap',
    attribution: 'Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012'
  },
  {
    type: 'provider',
    text: 'Esri: 衛星画像',
    name: 'Esri.WorldImagery',
    title: 'WorldImagery',
    attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
  },
  {
    type: 'provider',
    text: 'Esri: 地形図',
    name: 'Esri.WorldTopoMap',
    title: 'WorldTopoMap',
    attribution: 'Tiles © Esri — Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community',
  },
  {
    type: 'base',
    text: 'GSI: 淡色地図',
    name: '淡色地図',
    title: 'pale',
    minZoom: 2,
    maxZoom: 18,
    uri: location.protocol + '//cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
    attribution: '&copy <a href="http://osm.org/copyright" target="new">OpenStreetMap</a> contributors, &copy; <a href="http://maps.gsi.go.jp/development/ichiran.html" target="new">国土地理院</a>, Shoreline data is derived from: United States. National Imagery and Mapping Agency. "Vector Map Level 0 (VMAP0)." Bethesda, MD: Denver, CO: The Agency; USGS Information Services, 1997.',
  },
  {
    type: 'base',
    grayscale: true,
    text: 'GSI: 淡色地図(白黒)',
    name: '淡色地図(白黒)',
    title: 'pale_gray',
    minZoom: 2,
    maxZoom: 18,
    uri: location.protocol + '//cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
    attribution: '&copy <a href="http://osm.org/copyright" target="new">OpenStreetMap</a> contributors, &copy; <a href="http://maps.gsi.go.jp/development/ichiran.html" target="new">国土地理院</a>, Shoreline data is derived from: United States. National Imagery and Mapping Agency. "Vector Map Level 0 (VMAP0)." Bethesda, MD: Denver, CO: The Agency; USGS Information Services, 1997.',
  },
  {
    type: 'base',
    text: 'GSI: 標準地図',
    name: '標準地図',
    title: 'std',
    minZoom: 2,
    maxZoom: 18,
    uri: 'https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png',
    attribution: '&copy <a href="http://osm.org/copyright" target="new">OpenStreetMap</a> contributors, &copy; <a href="https://maps.gsi.go.jp/development/ichiran.html" target="new">国土地理院</a>, The bathymetric contours are derived from those contained within the GEBCO Digital Atlas, published by the BODC on behalf of IOC and IHO (2003) (<a href="http://www.gebco.net" target="new">http://www.gebco.net</a>) 海上保安庁許可第292502号（水路業務法第25条に基づく類似刊行物），Shoreline data is derived from: United States. National Imagery and Mapping Agency. "Vector Map Level 0 (VMAP0)." Bethesda, MD: Denver, CO: The Agency; USGS Information Services, 1997.',
  },
  {
    type: 'base',
    grayscale: true,
    text: 'GSI: 標準地図(白黒)',
    name: '標準地図(白黒)',
    title: 'std_gray',
    minZoom: 2,
    maxZoom: 18,
    uri: 'https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png',
    attribution: '&copy <a href="http://osm.org/copyright" target="new">OpenStreetMap</a> contributors, &copy; <a href="https://maps.gsi.go.jp/development/ichiran.html" target="new">国土地理院</a>, The bathymetric contours are derived from those contained within the GEBCO Digital Atlas, published by the BODC on behalf of IOC and IHO (2003) (<a href="http://www.gebco.net" target="new">http://www.gebco.net</a>) 海上保安庁許可第292502号（水路業務法第25条に基づく類似刊行物），Shoreline data is derived from: United States. National Imagery and Mapping Agency. "Vector Map Level 0 (VMAP0)." Bethesda, MD: Denver, CO: The Agency; USGS Information Services, 1997.',
  },
  {
    type: 'base',
    text: 'GSI: 航空写真',
    name: '写真',
    title: 'ort',
    minZoom: 2,
    maxZoom: 18,
    uri: location.protocol + '//cyberjapandata.gsi.go.jp/xyz/ort/{z}/{x}/{y}.jpg',
    attribution: '&copy <a href="http://osm.org/copyright" target="new">OpenStreetMap</a> contributors, &copy; <a href="http://maps.gsi.go.jp/development/ichiran.html" target="new">国土地理院</a> Map data: Landsat8画像（GSI,TSIC,GEO Grid/AIST）, Landsat8画像（courtesy of the U.S. Geological Survey）, 海底地形（GEBCO）, Images on 世界衛星モザイク画像 obtained from site <a href="https://lpdaac.usgs.gov/data_access" target="new">https://lpdaac.usgs.gov/data_access</a> maintained by the NASA Land Processes Distributed Active Archive Center (LP DAAC), USGS/Earth Resources Observation and Science (EROS) Center, Sioux Falls, South Dakota, (Year). Source of image data product.',
  },
  {
    type: 'base',
    text: 'GSI: 色別標高図',
    name: '色別標高図',
    title: 'relief',
    minZoom: 5,
    maxZoom: 15,
    uri: 'https://cyberjapandata.gsi.go.jp/xyz/relief/{z}/{x}/{y}.png',
    attribution: '<a href="https://cyberjapandata.gsi.go.jp/legend/attension_relief.html" target="new">解説ページ</a> &copy <a href="http://osm.org/copyright" target="new">OpenStreetMap</a> contributors, &copy; <a href="http://maps.gsi.go.jp/development/ichiran.html" target="new">国土地理院</a>, 海域部は海上保安庁海洋情報部の資料を使用して作成',
  },
  {
    type: 'base',
    text: 'GSI: 傾斜量図',
    name: '傾斜量図',
    title: 'slopemap',
    minZoom: 3,
    maxZoom: 15,
    uri: 'https://cyberjapandata.gsi.go.jp/xyz/slopemap/{z}/{x}/{y}.png',
    attribution: '<a href="http://www.gsi.go.jp/bousaichiri/slopemap.html" target="new">解説ページ</a> &copy <a href="http://osm.org/copyright" target="new">OpenStreetMap</a> contributors, &copy; <a href="http://maps.gsi.go.jp/development/ichiran.html" target="new">国土地理院</a>',
  },
  {
    type: 'base',
    text: 'GSI: アナグリフ',
    name: 'アナグリフ（カラー）',
    title: 'anaglyphmap_color',
    minZoom: 2,
    maxZoom: 16,
    uri: 'https://cyberjapandata.gsi.go.jp/xyz/anaglyphmap_color/{z}/{x}/{y}.png',
    attribution: '<a href="http://www.gsi.go.jp/bousaichiri/anaglyphmap.html" target="new">解説ページ</a> &copy <a href="http://osm.org/copyright" target="new">OpenStreetMap</a> contributors, &copy; <a href="http://maps.gsi.go.jp/development/ichiran.html" target="new">国土地理院</a>',
  },
  {
    type: 'provider',
    text: 'OSM: Mapnik',
    name: 'OpenStreetMap.Mapnik',
    title: 'Mapnik',
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright" target="new">OpenStreetMap</a>'
  },
  {
    type: 'provider',
    text: 'OSM: HOT',
    name: 'OpenStreetMap.HOT',
    title: 'Hot',
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright" target="new">OpenStreetMap</a>, Tiles courtesy of <a href="http://hot.openstreetmap.org/" target="new">Humanitarian OpenStreetMap Team</a>'
  },
  {
    type: 'provider',
    text: 'OpenTopoMap',
    name: 'OpenTopoMap',
    title: 'OpenTopoMap',
    attribution: 'Map data: &copy; <a href="http://www.openstreetmap.org/copyright" target="new">OpenStreetMap</a>, <a href="http://viewfinderpanoramas.org" target="new">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org" target="new">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/" target="new">CC-BY-SA</a>)'
  },
  {
    type: 'provider',
    text: 'Watercolor',
    name: 'Stamen.Watercolor',
    title: 'Watercolor',
    attribution: 'Map tiles by <a href="http://stamen.com" target="new">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0" target="new">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright" target="new">OpenStreetMap</a>'
  },
  {
    type: 'provider',
    text: 'Terrain',
    name: 'Stamen.Terrain',
    title: 'Terrain',
    attribution: 'Map tiles by <a href="http://stamen.com" target="new">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0" target="new">CC BY 3.0</a> &mdash; Map data &copy; <a href="http://www.openstreetmap.org/copyright" target="new">OpenStreetMap</a>'
  },
  {
    type: 'provider',
    text: 'CartoDB: Positron',
    name: 'CartoDB.PositronNoLabels',
    title: 'Positron',
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright" target="new">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions" target="new">CartoDB</a>'
  },
  {
    type: 'provider',
    text: 'CartoDB: DarkMatter',
    name: 'CartoDB.DarkMatterNoLabels',
    title: 'DarkMatter',
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright" target="new">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions" target="new">CartoDB</a>'
  },
];

<template lang="pug">
div
  .content.mapWrapper
    //- guideBar
    //- 地図
    v-map#map-area.mapArea.full(
      ref="map",
      :zoom="zoom",
      :center="center",
      :min-zoom="minZoom",
      :max-zoom="maxZoom",
      :options="mapOptions",
      :class="{ topOff: 0 <= guidePage, whiteBack: guidePage === maxGuidePage }"
    )
  //- ツールバー
  div(v-if="map")
    toolbar
  alerts
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Toolbar from "./toolbar/index.vue";
// import GuideBar from './toolbar/guide.vue';
import Alerts from "./alerts.vue";
import MiniMap from "leaflet-minimap";
import Vue2Leaflet from "vue2-leaflet";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import "leaflet-ajax";
// import "leaflet-easybutton";
import "../../../lib/leaflet-contextmenu.extend";
import "../../../lib/TileLayer.Grayscale";
// import '../../../lib/leaflet.draw';
import "leaflet-draw";
import "../../../lib/leaflet.draw.ex";
import "../../../config/leaflet.draw-local";
// import '../../../lib/TileLayer.GeoJSON.js';
import "../../../lib/L.Mask";
import "../../../lib/L.Control.ShirubeScale";
import "leaflet-multi-style";
// import 'geobuf';
import "leaflet.vectorgrid";
// import 'leaflet-polylinedecorator';
import "leaflet-providers";
// import isMobile from "ismobilejs";
import "leaflet-simple-map-screenshoter";
// import '../../../lib/VectorTileLayer.js';
// import '../../../lib/multi-style-layer';
// import '../../../lib/SVGMarker';

export default {
  name: "Main",
  components: {
    toolbar: Toolbar,
    // 'guideBar': GuideBar,
    alerts: Alerts,
    "v-map": Vue2Leaflet.Map,
    "v-tilelayer": Vue2Leaflet.TileLayer,
  },
  computed: {
    ...mapGetters("map", [
      "map",
      "maxGuidePage",
      "guidePage",
      "minutes",
      "distance",
      "legendPosition",
    ]),
    miniMapUri: () =>
      location.protocol + "//cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png",
    miniMapOption: () => ({
      position: "topleft",
      toggleDisplay: true,
      minimized: true,
      collapsedWidth: 30,
      collapsedHeight: 30,
      width: 400,
      height: 300,
      // zoomLevelOffset: -5,
      zoomLevelFixed: 10,
      strings: {
        showText: "ミニマップを開く",
        hideText: "ミニマップを閉じる",
      },
    }),
  },
  methods: {
    ...mapActions("map", ["setMapObject", "setScaleControl"]),
    init() {
      // 初期化
      if (adminData) {
        const {center, zoom, prefix} = adminData;
        if (center && 2 == center.length) this.center = center;
        if (zoom) this.zoom = zoom;
        if (prefix) this.prefix = prefix;
      }
      // Mapオブジェクトの変数化
      this.setMapObject(this.$refs.map.mapObject);
      const { map } = this;
      const _this = this;
      new MiniMap(new L.TileLayer(this.miniMapUri), this.miniMapOption).addTo(
        map
      );
      L.control.attribution({ prefix: this.getPrefix() }).addTo(map);
      this.setScaleControl({});
      this.zoomControl = L.control.zoom(this.zoomOptions);
      this.zoomControl.addTo(map);
      // this.fixControl = L.easyButton(this.fixOptions);
      // this.fixControl.addTo(map);

      this.changeColorTexts(this.isViewMinutes);

      // レイヤ順を設定
      map.createPane("flood").style.zIndex = 200;
      map.createPane("baseRoute").style.zIndex = 310;
      map.createPane("colorRoute0").style.zIndex = 420;
      map.createPane("colorRoute1").style.zIndex = 419;
      map.createPane("colorRoute2").style.zIndex = 418;
      map.createPane("colorRoute3").style.zIndex = 417;
      map.createPane("colorRoute4").style.zIndex = 416;
      map.createPane("colorRoute5").style.zIndex = 415;
      map.createPane("colorRoute6").style.zIndex = 414;
      map.createPane("colorRoute7").style.zIndex = 413;
      map.createPane("colorRoute8").style.zIndex = 412;
      map.createPane("exRoute").style.zIndex = 410;
      map.createPane("pointMarker").style.zIndex = 515;
    },
    getPrefix() {
      return dayjs().format("(YYYY年M月D日作成) " + this.prefix)
    },
    fixMap() {
      const { map } = this;
      const z = map.getZoom();
      this.tempMinZoom = this.minZoom;
      this.tempMaxZoom = this.maxZoom;
      this.minZoom = z;
      this.maxZoom = z;
      map.dragging.disable();
      map.touchZoom.disable();
      map.keyboard.disable();
      map.removeControl(this.zoomControl);
      // this.fixControl.state("fix");
    },
    freeMap() {
      const { map } = this;
      this.minZoom = this.tempMinZoom;
      this.maxZoom = this.tempMaxZoom;
      map.dragging.enable();
      map.touchZoom.enable();
      map.keyboard.enable();
      map.addControl(this.zoomControl);
      // this.fixControl.state("free");
    },
    startFullscreen(element) {
      const list = [
        "webkitRequestFullScreen",
        "mozRequestFullScreen",
        "msRequestFullscreen",
        "requestFullscreen",
      ];
      const num = list.length;
      for (let i = 0; i < num; i++) {
        if (element[list[i]]) {
          element[list[i]]();
        }
      }
      return false;
    },
    stopFullscreen(element) {
      const list = [
        "exitFullscreen",
        "cancelFullScreen ",
        "webkitExitFullscreen ",
        "mozCancelFullScreen",
        "msExitFullscreen",
      ];
      const num = list.length;
      for (let i = 0; i < num; i++) {
        if (element[list[i]]) {
          element[list[i]]();
        }
      }
      return false;
    },
    changeColorTexts(flag) {
      const base = [...Array(7)];
      const exValue = ["それ以上"];
      this.isViewMinutes = flag || !this.isViewMinutes;
      this.colorTexts = this.isViewMinutes
        ? base
            .map((n, i) => "<b>" + this.minutes * (i + 1) + "</b>分以内")
            .concat(exValue)
        : base
            .map((n, i) => "<b>" + this.distance * (i + 1) + "</b>m以内")
            .concat(exValue);
    },
  },
  data() {
    const _this = this;
    return {
      zoom: 16, //13,
      minZoom: 8,
      maxZoom: 18,
      tempMinZoom: 0,
      tempMaxZoom: 0,
      center: [33.476106, 135.775375], // kushimoto
      // center: [34.252171, 135.131718], // wakayama
      // center: [34.6651696, 138.926138], // shimoda
      // center: [33.594031, 135.943962], // taiji
      // center: [33.628129, 135.941440], // nachi
      // center: [33.611528, 135.922517], // interaction
      prefix: "逃げシルベ",
      isViewMinutes: true,
      colorTexts: Array(8),
      mapOptions: {
        zoomSnap: 0.25,
        zoomDelta: 0.25,
        doubleClickZoom: false,
        zoomControl: false,
        attributionControl: false,
        renderer: L.svg(),
        contextmenu: true,
        contextmenuWidth: 180,
      },
      zoomOptions: {
        position: "topright",
        zoomInTitle: "ズームイン",
        zoomOutTitle: "ズームアウト",
      },
      fixOptions: {
        position: "topright",
        states: [
          {
            stateName: "free",
            title: "移動モード",
            icon: "fa-arrows",
            onClick: function () {
              // _this.fixMap();
            },
          },
          {
            stateName: "fix",
            title: "固定モード",
            icon: "fa-thumb-tack",
            onClick: function () {
              // _this.freeMap();
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.init();
  },
};
</script>

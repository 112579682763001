import config from './../../../../../config';

export const setError = ({commit}, textType) => {
  const text = config.errorTexts[textType];
  if (text) commit('OPEN_ERROR_ALERT', text)
}
export const clearError = ({commit}) => {
  commit('CLOSE_ERROR_ALERT')
}

export const setWarning = ({commit}, textType) => {
  const text = config.warningTexts[textType];
  if (text) commit('OPEN_WARNING_ALERT', text)
}
export const clearWarning = ({commit}) => {
  commit('CLOSE_WARNING_ALERT')
}

export const setProgress = ({ commit }, value) => {
  commit('SET_PROGRESS', value)
}

export const setMultiProgress = ({ commit }, value) => {
  commit('SET_MULTI_PROGRESS', value)
}

import axios from 'axios';
import L from 'leaflet';
import RouteMap from './tools/routeMap';
import GsiLoader from './tools/gsiLoader';
import nearestLinePoint from './tools/nearestLinePoint';
import '../../../../../../node_modules/leaflet-minimap/dist/Control.MiniMap.min.css';

// 地理空間分析ツールTurf.js http://turfjs.org/
import {
  // 汎用
  lineString,
  getCoord,
  getCoords,
  featureEach,
  length,
  // 中心を求める
  center as getCenter,
  // 2点間の距離を求める
  distance as getDistance,
  // ライン上で最も近い点を求める
  nearestPointOnLine,
  // 交差点を取得する
  lineIntersect,
  // ラインを点で分割する
  lineSplit,
  // 点と線間の距離を取得する
  pointToLineDistance,
} from '@turf/turf';

// 状態を設定する
export const setMapObject = ({ commit }, object) => commit('SET_MAP_OBJECT', object);
export const setDrawControl = ({ commit }, object) => commit('SET_DRAW_CONTROL', object);
export const setMode = ({ commit }, index) => commit('SET_MODE', index);
export const setGuidePage = ({ commit }, index) => commit('SET_GUIDE_PAGE', index);
export const setEditMode = ({ commit }, index) => commit('SET_EDIT_MODE', index);
export const setMarkerMode = ({ commit }, index) => commit('SET_MARKER_MODE', index);
export const selectMarker = ({ commit }, info) => commit('SELECT_MARKER', info);
export const setDelayMinutes = ({ commit }, num) => commit('SET_DELAY_MINUTES', num);
export const setDistance = ({ commit }, distance) => commit('SET_DISTANCE', distance);
export const setColorNum = ({ commit }, num) => commit('SET_COLOR_NUM', num);
export const setDrawLayer = ({ commit }, object) => commit('SET_DRAW_LAYER', object);
export const refreshScaleControl = ({ commit }, object) => commit('REFRESH_SCALE_CONTROL', object);
export const refreshDrawLayer = ({ commit }) => commit('REFRESH_DRAW_LAYER');
export const addRouteData = ({ commit }, tileRouteData) => commit('ADD_ROUTE_DATA', tileRouteData);
export const setViewsColorLayer = ({ commit }, flag) => commit('SET_VIEWS_COLORS_LAYER', flag);
export const refreshColorsLayer = ({ commit }, object) => commit('REFRESH_COLORS_LAYER', object);

// 関数を実行する
export const addExRoute = (store, coords) => {
  if (!coords) return null;
  const { drawLayer } = store.state;
  return _addExRoute(store, coords, 0).then((layer) => {
    if (layer) drawLayer.addLayer(layer);
  });
}
export const clearRouteData = store => {
  store.commit("CLEAR_ROUTE_DATA");
}
export const setScaleControl = (store, options) => {
  const { map, delayMinutes, minutes, distance, colorNum, colorList } = store.state;
  store.commit('SET_SCALE_CONTROL', L.control.shirubeScale(options).addTo(map));
  store.commit('REFRESH_SCALE_CONTROL', {
    delay: delayMinutes,
    minutes: minutes,
    distance: distance,
    colorNum: colorNum,
    colorList: colorList,
  });
}

// 地図タイルを設定する
export const setTile = (store, index) => {
  const { map, tileLayerList } = store.state;
  let tileLayer;
  if (index < 0) {
    if (0 < store.state.tile && store.state.baseLayer) map.removeLayer(store.state.baseLayer);
    store.commit('SET_TILE', { layer: null, index: -1 })
  } else if (map && tileLayerList && tileLayerList[index]) {
    let tileInfo = tileLayerList[index];
    let tileOptions = {
      attribution: tileInfo.attribution,
    };
    if (tileInfo.type === 'provider') {
      tileLayer = L.tileLayer.provider(tileInfo.name, tileOptions);
    } else {
      if (tileInfo.grayscale) {
        tileLayer = L.tileLayer.grayscale(tileInfo.uri, tileOptions);
      } else {
        tileLayer = L.tileLayer(tileInfo.uri, tileOptions);
      }
    }
    if (store.state.baseLayer !== tileLayer) {
      if (store.state.baseLayer) map.removeLayer(store.state.baseLayer);
      if (tileLayer) tileLayer.addTo(map);
    }
    store.commit('SET_TILE', { layer: tileLayer, index: index })
  }
};

// 避難障害地点を削除する
function removeBlackShirube(store, point) {
  return new Promise(resolve => {
    return resolve(store.commit('REMOVE_BLACK_DATA', point));
  });
}

// アイコン位置から逃げ道の描画を開始する
export const startAutoShirube = (store, iconInfo) => {
  const { icon, multiMode } = iconInfo;
  const latlng = icon.getLatLng();
  const coord = L.GeoJSON.latLngToCoords(latlng);
  return new Promise(resolve => {
    _startAutoShirube(store, coord, 0, multiMode).then(info => {
      if (!info) {
        console.log('response not found.');
        return resolve(false);
      }
      const { map, colorList, colorsLayer, colorSvg } = store.state;
      // console.log(JSON.stringify(info));
      // 再帰的に時差をかけながら逃げ道を追加
      let drawColorRoute = function (colorIndex) {
        if (info.length <= colorIndex) {
          store.dispatch('layout/setProgress', 0, { root: true });
          return resolve(info);
        }
        const pathStyle = () => {
          const z = map.getZoom();
          let obj = { color: colorList[colorIndex].code, opacity: 1, };
          if (17 <= z) {
            obj.weight = 7;
          } else if (15 <= z) {
            obj.weight = 5;
          } else if (13 <= z) {
            obj.weight = 2;
          }
          return obj;
        };
        L.geoJson(info[colorIndex], {
          className: "shirube" + (multiMode ? '' : '-anim'),
          iconIndex: icon._leaflet_id,
          colorIndex: colorIndex,
          style: pathStyle,
          onEachFeature: (feature, layer) => {
            layer.on('click', ev => {
              // map.flyTo(latlng);
            });
          },
          renderer: colorSvg[colorIndex],
          //render: icon.options.colorCanvas[colorIndex],
        }).addTo(colorsLayer);
        setTimeout(drawColorRoute.bind(this, colorIndex + 1), 20);
      };
      drawColorRoute.bind(this, 0)();
    });
  });
}

function _startAutoShirube(store, coord, startCount, multiMode = false) {
  return new Promise(resolve => {
    // 蓄積したルート生データと障害データを取得
    let baseCoordsData = [];
    let eachFunc = feature => {
      baseCoordsData.push(getCoords(feature));
    };
    let enoughList = collect(store, eachFunc, coord, 2);
    if (0 < enoughList.length) {
      return resolve(reCollect(store, enoughList, coord, _startAutoShirube, startCount, multiMode));
    }

    let exCoordsData = [];
    if (0 < baseCoordsData.length) {
      const { routeData, blackData, drawLayer, distance, colorNum, delayMinutes, minutes } = store.state;
      // 拡張道路データを，exCoordsDataに入れる
      if (drawLayer) {
        drawLayer.eachLayer(dLayer => {
          const { geometry } = dLayer.toGeoJSON();
          if (geometry.type === 'LineString') {
            // 拡張道路として追加
            exCoordsData.push(geometry.coordinates);
          }
        });
      }
      // ルートマップの作成
      const routeMap = new RouteMap(baseCoordsData, exCoordsData, blackData, coord);
      return new Promise(_resolve => {
        // データを送信
        return axios.post('api/dijkstra', {
          start: routeMap.getStartLine(),
          map: routeMap.getMap(),
          distance: distance,
          colorNum: colorNum,
          delay: delayMinutes / minutes,
        }).then(res => {
          if (res.status === 200 && res.data) {
            if (300 < Object.keys(routeData).length) store.commit("CLEAR_ROUTE_DATA");
            // レスポンスをクライアント側へ返す
            const infoMap = res.data.result;
            // console.log(infoMap);
            return _resolve(routeMap.getFeatures(infoMap));
          }
        }).catch(err => console.log(err));
      }).then(info => {
        return resolve(info);
      });
    } else {
      store.dispatch('layout/setError', "NotFoundRouteData", { root: true });
      return resolve(null);
    }
  });
}

// アイコンを追加する
export const addMarker = (store, layerOption) => {
  const { map, drawLayer, colorCanvas } = store.state;

  const layerId = layerOption.layerId;
  const markerId = layerOption.markerId;
  let layer = null;
  if (layerId) {
    drawLayer.eachLayer((_layer) => {
      if (_layer._leaflet_id === layerId) {
        layer = _layer;
      }
    });
  }
  if (!layer) return;
  layer.options.markerId = markerId;
  layer.on("click", (e) => {
    if (0 < store.state.editMode) return false;
    e.originalEvent.preventDefault();
    store.commit('SELECT_MARKER', { icon: layer, type: markerId });
    e.originalEvent.stopPropagation();
    return false;
  });
  if (markerId === 0 || markerId === 1) {
    // 避難所
    layer.options.colorCanvas = colorCanvas;
  } else if (markerId === 2 || markerId === 3) {
    layer.options.pointCanvas = L.canvas({ pane: "pointMarker" });
    let createGeoJsonMethod;
    let markerOption = {
      renderer: layer.options.pointCanvas
    };
    if (markerId === 2) {
      // 通行禁止にバツ
      createGeoJsonMethod = createCloseGeojson;
      markerOption.style = {
        color: "#211612",
        weight: 4,
        interactive: false,
      };
    } else {
      // 通行禁止にマル
      createGeoJsonMethod = createCircle;
      markerOption = Object.assign(markerOption, {
        color: "#DD3E27",
        weight: 3,
        interactive: false,
      });
    }
    // ブラックリストのルート追加
    const latlng = layer.getLatLng();
    new Promise((resolve) => {
      return addBlackShirube(store, L.GeoJSON.latLngToCoords(latlng)).then(
        (coord) => {
          if (!coord) return null;
          const closeLine = createGeoJsonMethod(coord, markerOption).addTo(map);
          return resolve(coord);
        }
      );
    }).then(closePoint => {
      layer.options.closePoint = closePoint;
    });
  }
}

// ばつマークをつける
function createCloseGeojson(coord, options) {
  const geojson = { type: "FeatureCollection", features: [] };
  geojson.features.push(
    L.polyline([
      [coord[1] - 0.00005, coord[0] - 0.00005],
      [coord[1] + 0.00005, coord[0] + 0.00005],
    ]).toGeoJSON()
  );
  geojson.features.push(
    L.polyline([
      [coord[1] - 0.00005, coord[0] + 0.00005],
      [coord[1] + 0.00005, coord[0] - 0.00005],
    ]).toGeoJSON()
  );
  return L.geoJson(geojson, options);
};

// 円マークをつける
function createCircle(coord, options, size = 6) {
  return L.circle([coord[1], coord[0]], size, options);
};

// 色道などが引かれたキャンバスを削除する
export const clearCanvas = (store, layerId) => {
  if (layerId == null) return;
  const { drawLayer, colorCanvas } = store.state;
  let layer = null;
  drawLayer.eachLayer(_layer => {
    if (_layer._leaflet_id === layerId) {
      layer = _layer;
    }
  });
  if (layer == null) return;
  // マーカ印と通行止めデータを削除
  const { pointCanvas } = layer.options;
  if (pointCanvas) {
    pointCanvas.remove();
    layer.options.pointCanvas = null;
  }
  const { closePoint } = layer.options;
  if (closePoint) {
    removeBlackShirube(store, closePoint);
    layer.options.closePoint = null;
  }
  // 色道を削除
  if (colorCanvas) {
    const { colorsLayer } = store.state;
    colorCanvas.map((canvas) => canvas.remove());
    colorsLayer.eachLayer((_layer) => {
      if (_layer.options.iconIndex === layer._leaflet_id) {
        colorsLayer.removeLayer(layer);
        layer.options.colorCanvas = null;
      }
    });
  }
};

// 避難障害地点を追加する
function addBlackShirube(store, coord) {
  return _addBlackShirube(store, coord, 0);
}

function _addBlackShirube(store, coord, startCount) {
  return new Promise(resolve => {
    let linesData = [];
    let eachFunc = feature => {
      linesData.push(feature);
    };
    let enoughList = collect(store, eachFunc, coord);

    // // 不足分があったので，実際に移動させて情報収集
    if (0 < enoughList.length) {
      return resolve(reCollect(store, enoughList, coord, _addBlackShirube, startCount));
    }
    store.dispatch('layout/setProgress', 0, { root: true });
    // linesData中で最も近いラインの最近線を引く
    const blackPoint = nearestLinePoint(linesData, coord);
    if (blackPoint) {
      const blackCoord = blackPoint.coord;
      if (30 < Object.keys(store.state.routeData).length) store.commit("CLEAR_ROUTE_DATA");
      // L.polyline([[latlng.lat, latlng.lng], blackPoint]).addTo(store.state.map);
      store.commit('ADD_BLACK_DATA', blackCoord);
      return resolve(blackCoord);
    }
    return resolve(null);
  });
}

// 避難目標地点を追加する
function addSafeShirube(store, iconInfo) {
  return _addSafeShirube(store, iconInfo, 0);
}

function _addSafeShirube(store, { icon, close }, startCount) {
  return new Promise(resolve => {
    let linesData = [];
    let eachFunc = feature => {
      let coords = toFixedCoords(feature.geometry.coordinates);
      let cods = coords;
      if (coords.some((pos, index) => {
          const found = close && close[0] === pos[1] && close[1] === pos[0];
          if (found && 0 < index) cods = cods.reverse();
          return found;
        })) {
        linesData.push(L.polyline(cods).toGeoJSON())
      }
    };
    let enoughList = collect(store, eachFunc, coord);

    // // 不足分があったので，実際に移動させて情報収集
    if (0 < enoughList.length) {
      return resolve(reCollect(store, enoughList, { icon: icon, close: close }, _addSafeShirube, startCount));
    }
    store.dispatch('layout/setProgress', 0, { root: true });

    // Xに接続する全リンクとの距離を算出
    const nearLines = linesData.map(line => {
      return nearestPointOnLine(line, L.marker([latlng.lng, latlng.lat]).toGeoJSON());
    });
    let num = 0;
    let key;
    // 最も近いリンクを取得
    nearLines.map((line, index) => {
      const distance = line.properties.dist;
      if (num < distance) {
        num = distance;
        key = index;
      }
    });
    const nearestLine = linesData[key];
    if (nearestLine) {
      if (30 < Object.keys(store.state.routeData).length) store.commit("CLEAR_ROUTE_DATA");
      // 最も近いリンクの始点終点のうち，Xから近いほうを2つめのX（＝O)とする
      const nearestCoords = toFixedCoords(nearestLine.geometry.coordinates);
      const closeGeojson = L.marker(close).toGeoJSON();
      const disA = getDistance(closeGeojson, L.marker(nearestCoords[0]).toGeoJSON(), 'meters');
      const disB = getDistance(closeGeojson, L.marker(nearestCoords[nearestCoords.length - 1]).toGeoJSON(), 'meters');
      const safePoint = disA < disB ? nearestCoords[0] : nearestCoords[nearestCoords.length - 1];
      // const circleGeojson = L.marker(safePoint).toGeoJSON();
      // store.commit('ADD_BLACK_DATA', bp);
      // return resolve({geojson: circleGeojson, position: safePoint});
      return resolve(safePoint);
    }
    return resolve(null);
  })
}

// 拡張ルートを追加する
function _addExRoute(store, coords, startCount) {
  return new Promise(resolve => {
    let linesData = [];
    let eachFunc = feature => {
      linesData.push(feature);
    };
    const center = getCenter(lineString(coords));
    const coord = getCoord(center);
    let enoughList = collect(store, eachFunc, coord);
    // 不足分があったので，実際に移動させて情報収集
    if (0 < enoughList.length) {
      return resolve(reCollect(store, enoughList, coords, _addExRoute, startCount));
    }
    store.dispatch('layout/setProgress', 0, { root: true });

    // const latlngs = layer.getLatLngs();
    const start = coords[0];
    const end = coords[coords.length - 1];

    // linesData中で最も近いラインの最近線を引く
    const sPoint = nearestLinePoint(linesData, start);
    if (sPoint) {
      const sCoord = sPoint.coord;
      const ePoint = nearestLinePoint(linesData, end);
      if (ePoint) {
        const eCoord = ePoint.coord;
        let newLatlngs = [];
        if (sCoord[0] !== start[0] && sCoord[1] !== start[1]) newLatlngs.push(sCoord);
        newLatlngs.push(...toFixedCoords(coords));
        if (eCoord[0] !== end[0] && eCoord[1] !== end[1]) newLatlngs.push(eCoord);
        newLatlngs = uniqueArray(newLatlngs);
        const exLatlngs = L.GeoJSON.coordsToLatLngs(newLatlngs);
        const exLayer = L.geoJson(L.polyline(exLatlngs).toGeoJSON(), {
          // pane: 'exRoute',
          className: "ex_shirube",
          opacity: 0.6,
          weight: 10,
          renderer: L.svg({ pane: "exRoute" }),
        }).getLayers()[0];
        if (30 < Object.keys(store.state.routeData).length) store.commit("CLEAR_ROUTE_DATA");
        return resolve(exLayer);
      }
    }
    store.dispatch('layout/setError', "distantRouteData", { root: true });
    return resolve(null);
  });
}


// 浸水域と道路の境界に避難目標地点を設置する
export const startAutoSafePoints = (store, params) => {
  const { floodData } = params;
  let threshold = parseInt(params.threshold) || 2;
  const { map } = store.state;
  const coord = L.GeoJSON.latLngToCoords(map.getCenter());
  const floodLines = [];
  const replaceLatlng = coords => {
    return coords.map(array => [array[1], array[0]]);
  }
  return new Promise(resolve => {
    // 近距離の浸水域データを取得
    const floodFeature = L.geoJSON(floodData, {
      onEachFeature: (feature, layer) => {
        if (feature.geometries) {
          feature.geometries.map(geometry => {
            geometry.coordinates.map(coordinateSet => {
              coordinateSet.map(lines => {
                const lineGeoJSON = (new L.Polyline(replaceLatlng(lines))).toGeoJSON();
                if (pointToLineDistance(coord, lineGeoJSON) < 1) {
                  floodLines.push(lineGeoJSON);
                }
              });
            });
          });
        }
      }
    });
    // 道路情報を取得
    _startAutoSafePoints(store, coord, 0, false).then(baseCoordsData => {
      store.dispatch('layout/setProgress', 50, { root: true });
      try {
        const safePoints = [];
        if (baseCoordsData) {
          baseCoordsData.map((baseFeature, index) => {
            if (baseFeature.properties.state == "橋・高架") return;
            floodLines.map(line => {
              // 浸水域ラインと道路の交差点を取得
              const intersect = lineIntersect(line, baseFeature);
              const intersectCount = intersect.features.length;
              if (0 < intersectCount && intersectCount <= 1) {
                const feature = intersect.features[0];
                if (feature.geometry.coordinates) {
                  const split = lineSplit(line, feature);
                  const lines = [];
                  featureEach(split, feature => lines.push(feature));
                  if (lines.every(feature => {
                      return threshold < length(feature);
                    })) {
                    const coord = getCoord(feature);
                    safePoints.push([coord[1], coord[0]]);
                  }
                }
              }
            });
          });
        } else {
          store.dispatch('layout/setError', "distantRouteData", { root: true });
        }
        return resolve(safePoints);
      } catch (e) {
        console.log(e.toString());
        store.dispatch('layout/setError', "distantRouteData", { root: true });
      } finally {
        store.dispatch('layout/setProgress', 0, { root: true });
      }
    });
  });
}
export const _startAutoSafePoints = (store, coord, startCount) => {
  return new Promise(resolve => {
    // 蓄積したルート生データと障害データを取得
    let baseCoordsData = [];
    let eachFunc = feature => {
      baseCoordsData.push(feature);
      // baseCoordsData.push(getCoords(feature));
    };
    let enoughList = collect(store, eachFunc, coord, 2);
    if (0 < enoughList.length) {
      return resolve(reCollect(store, enoughList, coord, _startAutoSafePoints, startCount));
    }
    return resolve(baseCoordsData);
  });
}

// 道路データを収集する
function collect(store, eachFunc, coord, range = 1) {
  // 蓄積したルート生データと障害データを取得
  const { routeData } = store.state;
  // 送信用データ
  let enoughList = [];
  const tilePoint = getTilePoint(coord);
  // console.log(tilePoint);
  // 周辺タイル上の道路生データを，baseCoordsDataに入れる
  for (let dy = -range; dy <= range; dy++) {
    for (let dx = -range; dx <= range; dx++) {
      const p = { x: tilePoint.x + dx, y: tilePoint.y + dy, z: tilePoint.z };
      const key = p.z + "-" + p.x + "-" + p.y;
      if (!Object.keys(routeData).length || !routeData[key]) {
        // 周辺の生データが不足している場合は中断して，ユーザに勧告
        enoughList.push(p);
      } else if (!routeData[key].error && !enoughList.length) {
        routeData[key].map(eachFunc);
      }
    }
  }
  return enoughList;
}

// 道路データを再収集する
function reCollect(store, enoughList, params, callback, count, multiMode = false) {
  // 再度同じことをしないように，1度でダメだったらエラー報告
  if (0 < count) {
    store.dispatch('layout/setError', "enoughRouteData", { root: true });
    store.dispatch('layout/setProgress', 0, { root: true });
    return 0;
  }
  let index = 0;
  const size = enoughList.length;
  // let cacheData = {};
  const { gsiLayer } = store.state;
  // 収集開始
  // store.dispatch('layout/setWarning', "collectRouteData", {root:true});
  const gsiLoader = new GsiLoader(null, gsiLayer, data => {
    if (!data.error) {
      const geojsonLayer = L.geoJson(JSON.parse(data.result), data.options);
      store.dispatch("addRouteData", { geojson: geojsonLayer.toGeoJSON(), position: data.position });
      index++;
    } else {
      store.dispatch("addRouteData", { error: data.error, position: data.position });
    }
    if (!multiMode) {
      const progress = 100 * index / size;
      store.dispatch('layout/setProgress', progress, { root: true });
    }
  }, true);

  return Promise.resolve().then(() => {
    return new Promise(_resolve => {
      setTimeout(() => {
        enoughList.map(data => {
          gsiLoader.start(data);
        });
        _resolve();
      }, 10);
    })
  }).then(() => {
    return new Promise(_resolve => {
      setTimeout(() => {
        if (!multiMode) store.dispatch('layout/setProgress', 100, { root: true });
        // コールバック関数をもう1度実行し直す
        return _resolve(callback(store, params, 1));
      }, 1200);
    });
  });

}

// 汎用・Latlngとzoom情報からタイルxy情報を計算する
const getTilePoint = (coord, zoom) => {
  let lng = coord[0];
  let lat = coord[1];
  if (!lng) lng = coord.lng;
  if (!lat) lat = coord.lat;
  if (!zoom) zoom = 16;
  if (lng && lat) {
    const xtile = parseInt(Math.floor((lng + 180) / 360 * (1 << 16)));
    const ytile = parseInt(Math.floor((1 - Math.log(Math.tan(lat * Math.PI / 180) + 1 / Math.cos(lat * Math.PI / 180)) / Math.PI) / 2 * (1 << 16)));
    return { x: xtile, y: ytile, z: zoom };
  }
  return null;
}

// 汎用・値を，小数点以下任意桁数にする
const toFixedCoords = (coords, n = 6) => {
  if (coords instanceof Array) {
    return coords.map(c => {
      return toFixedCoords(c, n);
    });
  } else {
    return toFixedNumber(coords, n);
  }
}
const toFixedNumber = (f, n = 6) => {
  return Math.round(f * Math.pow(10, n)) / Math.pow(10, n);
}

// 汎用・配列の重複値を削除（先頭と末尾の重複を除く）
const uniqueArray = array => {
  let endCoord = array.pop();
  array = [...new Set(array.map(JSON.stringify))].map(JSON.parse);
  array.push(endCoord);
  return array;
}

// 汎用・GeoJSONクラス
class GeoJSON {
  constructor(type, coords) {
    this.properties = {};
    this.type = type;
    this.coordinates = coords;
  }
  get() {
    return {
      geometry: {
        coordinates: this.coordinates,
        type: this.type,
      },
      properties: {},
      type: "Feature",
    };
  }
}

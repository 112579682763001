export default {
  rdcl: {
    title: '道路中心線',
    contents: [
      // { class: 'blue--text', text: '地図を拡大すると表示されます．（ズームレベル：15以上）' },
      { class: null, text: '国土地理院がベクトルタイル提供実験事業の一環として提供している都道府県別の道路中心線データです。逃げ地図の最後の色の線で表示されます。' },
      { class: null, text: '<b>出典</b> 地図情報（道路中心線） <a href="https://github.com/gsi-cyberjapan/vector-tile-experiment" target="new">ベクトルタイル提供実験</a>' },
    ]
  },
  shelters: {
    title: '指定緊急避難場所',
    contents: [
      { class: null, text: '指定緊急避難場所データ（以下、「本データ」といいます）を利用される場合は、<a href="http://www.gsi.go.jp/kikakuchousei/kikakuchousei40182.html" target="new">国土地理院コンテンツ利用規約</a>のほか、以下のご利用上の注意をご確認いただき、内容に同意された場合のみご利用ください。' },
      { class: null, text: '本データは、災害対策基本法第49条の4に基づき市町村長が指定した指定緊急避難場所の情報を各市町村に提供いただき、当該市町村に確認の上、地図上に表示したものです。最新の状況などは当該市町村にご確認ください。' },
      { class: null, text: '本データを、ダウンロードや印刷等を行い国土地理院サーバ外で利用される場合は、本データの更新にあわせて最新の情報をご利用ください（参照：<a href="https://disaportal.gsi.go.jp/hinanbasho/koukaidate.html" target="new">市町村別公開日・更新日一覧</a>）。' },
      { class: null, text: '指定緊急避難場所は、災害種別ごとに指定されています。本データを、ダウンロードや印刷等を行い国土地理院サーバ外で利用される場合、指定された災害種別を利用者が正確に理解できるよう、十分にご留意ください。' },
    ]
  },
  floods: [
    {
      title: '津波浸水想定図（和歌山県・加工済み）',
      name: 'Wakayama-Pref-Nankai-Trough.min.pbf',
      contents: [
        { class: 'red--text', text: '本サイトでは、浸水想定区域0.01m以上のデータを<u>加工し</u>、簡易に表示しています．正確な津波浸水想定図とその詳細は、<a href="http://www.pref.wakayama.lg.jp/prefg/011400/bousai/130328/sinsui.html" target="new">和歌山県総合防災課のページ</a>をご覧ください。' },
        { class: null, text: '「津波浸水想定」は、実際の津波による災害や被害の発生範囲を示すものではないことに注意してください。' },
        { class: null, text: '<b>出典</b> <a href="http://www.pref.wakayama.lg.jp/prefg/011400/" target="new">和歌山県総合防災課</a> 津波浸水想定図（南海トラフ、東海・東南海・南海３連動地震）（平成25年3月作成）' }
      ]
    },
    {
      title: '津波浸水想定図（静岡県・加工済み）',
      name: 'shizuokakenhigaisoutei1802.pbf',
      contents: [
        { class: 'red--text', text: '本サイトでは、静岡県第４次地震被害想定の津波浸水（南海トラフ）データを<u>加工し</u>、簡易に表示しています．正確な津波浸水想定図とその詳細は、<a href="https://www.pref.shizuoka.jp/bousai/4higaisoutei/" target="new">静岡県危機情報課のページ</a>をご覧ください。' },
        { class: null, text: '本データは、平成25年6月27日に静岡県が公開した 「静岡県第４次地震被害想定(第一次報告)」におけるレベル２の地震・ 津波及び平成27年1月30日に公開した「相模トラフ沿いで発生した地震の地震動・津波浸水想定」における相模トラフ沿いで発生する最大クラスの津波の計算結果を示したものです。' },
        { class: null, text: '「津波浸水想定」は、実際の津波による災害や被害の発生範囲を示すものではないことに注意してください。' },
        { class: null, text: '<b>出典</b> <a href="https://www.pref.shizuoka.jp/bousai/4higaisoutei/" target="new">静岡県危機情報課</a> 静岡県第４次地震被害想定津波浸水（南海トラフ）（2016年11月11日, 午後1時43分更新）' }
      ]
    },
  ]
}

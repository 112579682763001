export default {
  warningTexts: {
    collectRouteData: '周辺データを収集します…',
  },
  errorTexts: {
    NotFoundRouteData: '逃げ道が見つかりませんでした．設置場所を変更してから，もう一度実行してください．',
    enoughRouteData: '描画用データが足りませんでした．再発する場合，周辺をもう少しお散歩してから実行してください．',
    distantRouteData: '周辺に道路が見つかりませんでした．道路の近くに設置してください．',
    download: 'ダウンロードに失敗しました。',
    upload: 'アップロードに失敗しました。',
    otherError: '何らかのエラーが発生しました．',
  }
};

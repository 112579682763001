<template lang="pug">
.help-wrapper
  v-btn.white--text(text, icon, @click.native.stop="_openDialog")
    v-icon.left.mr-1(dark) help
  //- ヘルプダイアログ
  v-dialog.theme--white(v-show="openDialog", v-model="openDialog", scrollable)
    v-card.white.helpDialogContent
      v-card-title.headline
        v-icon(color="primary") help
        span.ml-2 {{ pageTitle }}
      v-divider
      //- 1ページ目
      v-card-text.teal.lighten-5.text-xs-center(v-if="page === 1")
        .pa-2
          h3 『<a href="http://www.loto-lab.com/ngczweb/" target="_new">逃げ地図</a>』は、津波からの<b class="pink--text">避難時間が一目で</b>わかる地図。
          p 安全な高台までの避難時間を色分けする「逃げ地図」は、ワークショップ（体験型講座）などを通じ、様々な世代が安全に避難するための「話し合いの道具」として役立っています。
          p そうした「逃げ地図」を作成できるデジタルツール、『<b>逃げシルベ</b>』。
          p 下地になる地図を選び、避難場所（および避難目標地点）をチェックし、ワンクリックすれば自動的に逃げ道（避難経路）を塗り分けできます。既存の地図にはない新たな逃げ道（避難階段や避難通路）を追加することや、できあがった「逃げ地図」を印刷することもできます。
          v-img.elevation-4(:src="getImg('help-screen.jpg')")
          p 『逃げシルベ』を使って、<b>自前の「逃げ地図」</b>を作成してみましょう！
          v-card.elevation-4
            v-card-text.lime.lighten-5.text-xs-center
              p.teal--text.text--darken-2 「<b>逃げ地図</b>」は、日建設計ボランティア部が考案し、明治大学山本俊哉研究室と千葉大学木下勇研究室と一般社団法人子ども安全まちづくりパートナーズと共同して全国各地で検証研究を行い、そのマニュアルを開発しました。『<b>逃げシルベ</b>』は、和歌山大学吉野孝研究室がその研究成果を活用してデジタルシステムを開発しました。
      //- 2ページ目
      v-card-text.pa-2.teal.lighten-5(v-if="page === 2")
        v-timeline(dense, clipped)
          //- (1)
          v-timeline-item(icon="looks_one", color="primary", fill-dot)
            v-card.elevation-4
              v-card-text まずはあらかじめセットされている<b>地図を自由に動かして</b>、あなたの地域を探してみましょう。
          //- (2)
          v-timeline-item(icon="looks_two", color="primary", fill-dot)
            v-card.elevation-4
              v-card-text
                v-chip.mx-1.darken--2(label, color="green", text-color="white")
                  v-icon(left) map
                  span 地図
                span ボタンを押して、<b>下地にする地図を選んで</b>みましょう。
                v-img(:src="getImg('help-view-01.jpg')")
          //- (3)
          v-timeline-item(icon="looks_3", color="primary", fill-dot)
            v-card.elevation-4
              v-card-text
                v-chip.mx-1.darken--2(label, color="green", text-color="white")
                  v-icon(left) category
                  span 情報
                span ボタンを押して、既存の津波防災マップに表示された<b>防災情報（道路、避難場所、浸水区域）を重ねて</b>みましょう。
                v-img(:src="getImg('help-view-02.jpg')")
          //- (4)
          v-timeline-item(icon="looks_4", color="primary", fill-dot)
            v-card.elevation-4
              v-card-text
                span 試しに「逃げ道（避難経路）」を引いてみましょう。あなたが目標とする<b>避難地点</b>のアイコンをタップして
                v-chip.mx-1.darken--2(label, color="primary", text-color="white")
                  v-icon(left) opacity
                  span ここから逃げ道をひく！
                span ボタンを押すと、そこまでに至る<b>道路を自動的に塗り分け</b>できます。
                v-img(:src="getImg('help-view-03.jpg')")
                span 道路の色を見てみましょう。この色は、その目標となる避難地点までの距離（避難にかかる時間）を3分間刻みで示しています。
                span 緑は3分以内、黄緑は6分以内で到達できる場所、紫色や茶色はより時間がかかる場所であることが一目でわかります。
                v-img(:src="getImg('help-view-colors.jpg')")
                span 「逃げ道」の細かい設定（避難開始時刻、避難速度、色数）は
                v-chip.mx-1.darken--2(label, color="green", text-color="white")
                  v-icon(left) palette
                  span 逃げ道
                span ボタンから行うことができます。
          //- (5)
          v-timeline-item(icon="looks_5", color="primary", fill-dot)
            v-card.elevation-4
              v-card-text
                span 既存の防災マップに記された「指定緊急避難場所」以外にも、津波から逃れられる「避難目標地点」が近くにあるはずです。例えば、浸水区域と道路の交点や津波から逃れられる高いビルです。
                v-chip.mx-1.darken--2(label, color="indigo", text-color="white")
                  v-icon(left) add_location
                  span アイコン
                span ボタンを押して、そうした<b>「避難目標地点」をマーク</b>してみましょう。
                span そこに至る道路がなくても、通り路や階段があれば、その目標地点から近くの道路まで「逃げ道」を引くことができますよ。
                v-img(:src="getImg('help-view-04.jpg')")
                span 複数の「避難目標地点」から「逃げ道」を引く時は、
                v-chip.mx-1.darken--2(label, color="pink", text-color="white")
                  v-icon(left) build
                  span ツール
                span ボタンから「逃げ道」の
                v-chip.mx-1.darken--2(label, color="primary", text-color="white")
                  v-icon(left) opacity
                  span 一括描画
                span ボタンを押してください。
          //- (6)
          v-timeline-item(icon="looks_6", color="primary", fill-dot)
            v-card.elevation-4
              v-card-text
                span 逃げ道（避難経路）の中には、地震で老朽ブロック塀が倒壊して避難の障害になる場所があるかもしれません。一方、緊急時には通り抜けられる「抜け道」があるかもしれません。
                span その場合は、
                v-chip.mx-1.darken--2(label, color="indigo", text-color="white")
                  v-icon(left) add_location
                  span アイコン
                span ボタンから<b>「避難障害地点」をマーク</b>したり、
                v-chip.mx-1.darken--2(label, color="indigo", text-color="white")
                  v-icon(left) timeline
                  span 抜け道
                span ボタンから<b>通り路を追加したり</b>することができます。
                v-img(:src="getImg('help-view-05.jpg')")
                span 様々な状況をシミュレーション（想定）して設定条件を変えてみましょう。
      //- v-card-text(v-if="page === 3")
      v-divider
      v-row.align-center.ma-0.pa-0
        v-col(cols="12", md="4", offset-md="2")
          v-stepper.elevation-0(v-model="page")
            v-stepper-header(style="height: auto")
              template(v-for="n in steps")
                v-stepper-step.py-3.mx-3(
                  editable,
                  edit-icon="check",
                  :complete="page > n",
                  :key="`${n}-step`",
                  :step="n"
                ) Step {{ n }}
                v-divider(v-if="n !== steps", :key="n")
        v-col.text-xs-right.px-2(cols="12", md="4")
          v-btn(color="primary", block, @click.native="openDialog = false") 試してみる！
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    pageTitle() {
      return this.pageTitles[this.page - 1];
    },
  },
  methods: {
    moveStep(n, step) {
      if (n === this.steps) {
        this.page = 1;
      } else {
        this.page = n + step;
      }
    },
    getImg(img) {
      return require("./../../assets/img/" + img);
    },
    _openDialog() {
      this.page = 1;
      this.openDialog = true;
    },
  },
  watch: {
    steps(val) {
      if (val < this.page) this.page = val;
    },
  },
  data() {
    return {
      openDialog: false,
      page: 1,
      steps: 2,
      pageTitles: [
        "『逃げシルベ』へようこそ！",
        "自前の「逃げ地図」を作ろう",
        "",
      ],
    };
  },
  mounted() {
    this.openDialog = true;
  },
};
</script>

<style scoped>
</style>

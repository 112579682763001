<template lang="pug">
div
  v-toolbar#main-toolbar.toolbar.dense.darken-3(
    dark,
    fixed,
    width="100%",
    :class="modeColor"
  )
    //- v-spacer
    //- span.guideText(v-if="0<=guidePage" v-html="guideText")
    v-spacer
    paint-group(
      ref="paintTools",
      v-show="guidePage<0 && (modeName === 'paint' || masterMode)",
      :iconOnlyMode="iconOnlyMode"
    )
    view-group(
      ref="viewTools",
      v-show="guidePage<0 && (modeName === 'view' || masterMode)",
      :iconOnlyMode="iconOnlyMode"
    )
    help-group
</template>

<script>
import { mapGetters } from "vuex";
import ViewGroup from "./view.vue";
import PaintGroup from "./paint.vue";
import helpGroup from "./helpDialog.vue";

export default {
  components: {
    ViewGroup,
    PaintGroup,
    helpGroup,
  },
  computed: {
    ...mapGetters("map", [
      "map",
      "drawLayer",
      "mode",
      "guidePage",
      "tile",
      "modeList",
      "editMode",
      "markerMode",
    ]),
    modeColor() {
      if (0 <= this.guidePage) return "black";
      return this.masterMode ? "indigo" : this.modeList[this.mode].color;
    },
    modeName() {
      return this.modeList[this.mode].name;
    },
    defaultMenu() {
      return [
        {
          text: "ズームイン",
          iconCls: "material-icons md-16",
          iconText: "zoom_in",
          callback: this.zoomIn,
        },
        {
          text: "ズームアウト",
          iconCls: "material-icons md-16",
          iconText: "zoom_out",
          callback: this.zoomOut,
        },
        "-",
        {
          text: "地図を変更",
          iconCls: "material-icons md-16",
          iconText: "map",
          callback: this.openTileDialog,
        },
        {
          text: "情報を変更",
          iconCls: "material-icons md-16",
          iconText: "category",
          callback: this.openLayerDialog,
        },
        {
          text: "逃げ道を変更",
          iconCls: "material-icons md-16",
          iconText: "palette",
          callback: this.openRouteDialog,
        },
        {
          text: "ツール",
          iconCls: "material-icons md-16",
          iconText: "build",
          callback: this.openExportDialog,
        },
      ];
    },
  },
  watch: {
    editMode: function (val) {
      // console.log('edit', val);
    },
    markerMode: function (val) {
      // console.log('marker', val);
    },
  },
  methods: {
    onResize() {
      this.width = document.documentElement.clientWidth;
      this.iconOnlyMode = this.width < 500;
      this.masterMode = 850 < this.width;
    },
    setContextMenu(type) {
      const _this = this;
      const { map, defaultMenu, modeMenu } = this;
      map.off("contextmenu").on("contextmenu", function (e) {
        const { contextmenu } = map;
        contextmenu.removeAllItems();
        defaultMenu.map((item) => contextmenu.addItem(item));
        // modeMenu[type].concat().reverse().map(item => contextmenu.insertItem(item, 3));
        contextmenu.showAt(e.latlng);
      });
    },
    zoomIn() {
      this.map.zoomIn();
    },
    zoomOut() {
      this.map.zoomOut();
    },
    openTileDialog() {
      this.$refs.viewTools._openTileDialog();
    },
    openLayerDialog() {
      this.$refs.viewTools._openLayerDialog();
    },
    openRouteDialog() {
      this.$refs.viewTools._openRouteDialog();
    },
    openExportDialog() {
      this.$refs.viewTools._openExportDialog();
    },
    beforeUnload(ev) {
      // 未保存アラートを表示
      if (this.drawLayer != null && 0 < this.drawLayer.getLayers().length) {
        ev.returnValue =
          "編集中のものは保存されませんが、よろしいですか？\n\n※「ツール」ダイアログの「データをダウンロード」ボタンを押すことで、現在の状態を保存することができます。";
      }
    },
  },
  data: () => ({
    width: 0, //Window横サイズ
    iconOnlyMode: false, // ツールバーメニューをアイコンのみにするか
    masterMode: true, // マスターモード（全機能表示）
  }),
  mounted() {
    window.addEventListener("resize", this.onResize);
    window.addEventListener("beforeunload", this.beforeUnload);
    this.onResize();
    this.setContextMenu(0);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("beforeunload", this.beforeUnload);
  },
};
</script>

<style scoped>
</style>

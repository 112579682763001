export default {
  url: "https://cyberjapandata.gsi.go.jp/xyz/experimental_rdcl/{z}/{x}/{y}.geojson",
  canvasOptions: {
    attribution: '<a target=\'_blank\' href=\'https://github.com/gsi-cyberjapan/vector-tile-experiment\'>ベクトルタイル提供実験</a>',
    minZoom: 14,
    maxNativeZoom: 16,
    maxZoom: 15,
    styletype: "canvas"
  },
  geojsonOptions: {
    subdomains: '123',
    clipTiles: true,
    attribution: '<a target=\'_blank\' href=\'https://github.com/gsi-cyberjapan/vector-tile-experiment\'>ベクトルタイル提供実験</a>',
    minZoom: 17,
    maxNativeZoom: 16,
    maxZoom: 18,
  },
  info: {
    "layerType": "geojson_tile",
    "type": "Layer",
    "id": "experimental_rdcl",
    "title": "地図情報（道路中心線）",
    "iconUrl": "",
    "url": "https://cyberjapandata.gsi.go.jp/xyz/experimental_rdcl/{z}/{x}/{y}.geojson",
    "styleurl": "https://cyberjapandata.gsi.go.jp/xyz/experimental_rdcl/style_canvas.js",
    "attribution": "",
    "cocotile": true,
    "minZoom": 15,
    "maxNativeZoom": 16,
    "maxZoom": 18,
    "html": "ズーム15～18で表示します。",
    "subdomains": '123',
  },
  styles: [
    { weight: 10, color: '#000', lineCap: 'butt' },
    { weight: 4, color: '#fff', lineCap: 'round' },
  ],
  style: function (feature, color) {
    var properties = feature.properties;
    var rdCtg = properties['rdCtg'];
    // var motorway = properties['motorway'];
    var style = {
      clickable: false,
      weight: 3,
    };
    if (rdCtg === "高速自動車国道等") {
      style.color = '#ff1493';
      style.dashArray = "8,8";
      return style;
    } else {
      style.color = color || "#211612";
      style.pane = 'baseRoute';
      var state = properties['state'];
      var type = properties['type'];
      if (state === "通常部" && type === "通常部") {
        return style;
      } else {
        style.dashArray = "8,8";
        return style;
      }
    }

    // default
    // if (feature.properties['rdCtg'] == "国道" || feature.properties['rdCtg'] == "都道府県道") {
    //   return {
    //     color: "#777777",
    //     weight: 5,
    //     opacity: 0.5,
    //     lineCap: "butt"
    //   };
    // } else if (feature.properties['rdCtg'] == "高速自動車国道等") {
    //   return {
    //     color: "#ff1493",
    //     weight: 5,
    //     opacity: 0.5,
    //     lineCap: "butt"
    //   };
    // } else {
    //   if (feature.properties['type'] == "通常部") {
    //     return {
    //       color: "#aaaaaa",
    //       weight: 2,
    //       opacity: 0.5,
    //       lineCap: "butt"
    //     };
    //   } else {
    //     return {
    //       color: "#aaaaaa",
    //       weight: 2,
    //       opacity: 0.5,
    //       lineCap: "butt",
    //       dashArray: "5,5"
    //     };
    //   }
    //}
  },
  onEachFeature: function (feature, layer) {
    var lineproperties = {
      "class": "クラス名",
      "rID": "レコード ID",
      "lfSpanFr": "整備データ登録日",
      "lfSpanTo": "整備データ削除日",
      "tmpFlg": "暫定フラグ",
      "orgGILvl": "出典地理情報レベル",
      "ftCode": "地物種別コード",
      "admCode": "行政コード",
      "devDate": "整備完了日",
      "type": "種別",
      "rdCtg": "道路分類",
      "state": "道路状態",
      "lvOrder": "階層順",
      "name": "名称",
      "comName": "通称",
      "admOfcRd": "道路管理主体",
      "rnkWidth": "幅員区分",
      "Width": "実幅員",
      "sectID": "区間ID",
      "tollSect": "有料区分",
      "medSect": "分離帯区分",
      "motorway": "自動車専用道路",
      "repLtdLvl": "表示限界地図情報レベル",
      "rtCode": "路線コード"
    };
    var s = '<div class="popup">';
    for (var k in feature.properties) {
      var v = feature.properties[k];
      if (v !== "") {
        s += lineproperties[k] + ': ' + v + '<br>';
      }
    }
    s += '</div>';
    layer.bindPopup(s);

  }
}

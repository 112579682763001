<template lang="pug">
v-toolbar-items
  draw-tools(v-if="map")
  v-btn.white--text(
    v-show="isShowBtns",
    text,
    :icon="iconOnlyMode",
    @click.native.stop="openDialog"
  )
    v-icon.left.mr-1(dark) add_location
    span(v-show="!iconOnlyMode") アイコン
  v-btn.white--text(
    v-show="isShowBtns",
    text,
    :icon="iconOnlyMode",
    @click.native.stop="startEditMode(3)"
  )
    v-icon.left.mr-1(dark) timeline
    span(v-show="!iconOnlyMode") 抜け道
  v-btn.white--text(
    v-show="isShowBtns",
    text,
    :icon="iconOnlyMode",
    @click.native.stop="startEditMode(1)",
    :disabled="!activeEditMode"
  )
    v-icon.left.mr-1(dark) mode_edit
    span(v-show="!iconOnlyMode") 修正
  v-btn.white--text(
    v-show="isShowBtns",
    text,
    :icon="iconOnlyMode",
    @click.native.stop="startEditMode(2)",
    :disabled="!activeEditMode"
  )
    v-icon.left.mr-1(dark) delete
    span(v-show="!iconOnlyMode") 削除
  v-btn.yellow--text(
    v-show="0<=markerMode",
    text,
    @click.native.stop="cancelDrawMode"
  )
    v-icon.yellow--text.left.mr-1(dark) close
    span(v-show="!iconOnlyMode") 設置をやめる
  v-btn.yellow--text(
    v-show="0<editMode",
    text,
    @click.native.stop="saveEditMode"
  )
    v-icon.yellow--text.left.mr-1(dark) save
    span(v-show="editMode == 1") 確定
    span(v-show="editMode == 2") 確定
    span(v-show="editMode == 3") 確定
  v-btn.yellow--text(
    v-show="editMode == 3",
    text,
    @click.native.stop="backLine"
  )
    v-icon.yellow--text.left.mr-1(dark) undo
    span 1つ戻る
  v-btn.yellow--text(
    v-show="0<editMode",
    text,
    icon,
    @click.native.stop="revertEditMode"
  )
    v-icon.yellow--text.left.mr-1(dark) close
    //- span キャンセル
  v-dialog(
    v-show="openMarkerDialog",
    v-model="openMarkerDialog",
    content-class="dialogFullContent",
    scrollable
  )
    v-card.white
      v-card-title.headline どのアイコンを置く？
      v-divider
      v-card-text.cardText.grey.lighten-3
        v-container.iconCardGrid(fluid, grid-list-md)
          v-row
            v-col(
              cols="12",
              sm="6",
              v-for="(item, index) in markerList",
              :key="item.title",
              v-if="!item.disabled"
            )
              v-card(hover, @click.native="selectMarker(index)")
                v-avatar.green(v-if="item.menu.extraIcon === 'shelter'")
                  i.icon.ic.ic-shelter-emergency.green.white--text
                v-avatar(v-if="item.menu.icon", :class="item.menu.color")
                  v-icon.white--text {{ item.menu.icon }}
                v-card-title(primary-title)
                  div
                    .headline
                      span {{ item.title }}
                      v-btn(
                        text,
                        icon,
                        color="green",
                        @click="openMarkersInfo(index, $event)"
                      )
                        v-icon info
                    .pl-2.blue-grey--text {{ item.description }}
      v-divider
      v-card-actions
        v-btn.blue--text.darken-1(text, block, @click.native="cancelDrawMode") キャンセル
  //- 注釈ダイアログ
  v-dialog(v-model="openNotesDialog", content-class="dialogMiniContent")
    v-card.white
      v-card-title
        v-icon.green--text info
        span.headline {{ notes.title }}
      v-divider
      v-card-text.pa-2
        ul
          li(
            v-for="content in notes.contents",
            v-html="content.text",
            :class="[content.class]"
          )
      v-divider
      v-card-actions
        v-btn.blue--text.darken-1(
          text,
          block,
          @click.native="openNotesDialog = false"
        ) 閉じる
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DrawTools from "./../draw/index.vue";

export default {
  props: {
    iconOnlyMode: Boolean,
  },
  components: {
    "draw-tools": DrawTools,
  },
  computed: {
    ...mapGetters("map", [
      "map",
      "editMode",
      "markerMode",
      "markerList",
      "drawControl",
      "drawLayer",
      "drawLayerNum",
    ]),
    isShowBtns() {
      return this.markerMode < 0 && this.editMode == 0;
    },
    activeEditMode() {
      return 0 < this.drawLayerNum;
    },
  },
  methods: {
    ...mapActions("map", ["setMarkerMode", "setEditMode"]),
    selectMarker(index) {
      this.setMarkerMode(index);
      if (this.drawControl) {
        // マーカモードを有効にする
        this.drawControl._toolbars.draw._modes.marker.handler.disable();
        this.drawControl._toolbars.draw._modes.marker.handler.enable();
      }
      this.openMarkerDialog = false;
    },
    cancelDrawMode() {
      // マーカモードを無効にする
      this.drawControl._toolbars.draw._modes.marker.handler.disable();
      this.setMarkerMode(-1);
      this.setEditMode(0);
      this.openMarkerDialog = false;
    },
    openDialog() {
      this.cancelDrawMode();
      this.openMarkerDialog = true;
      // 地図をズームしておく
      if (this.map.getZoom() < 16) this.map.setZoom(16);
    },
    // アイコン注釈モーダルを開く
    openMarkersInfo(markersIndex, e) {
      this.openNotesDialog = true;
      this.notes = this.markerList[markersIndex];
      e.stopPropagation();
    },
    startEditMode(mode) {
      if (mode < 3) {
        // 編集/削除モードにする
        if (0 < this.drawLayer.getLayers().length) {
          this.drawControl._toolbars.edit._modes[
            this.editModeText[mode]
          ].handler.disable();
          this.drawControl._toolbars.edit._modes[
            this.editModeText[mode]
          ].handler.enable();
        } else {
          return 0;
        }
        this.map.getPane("exRoute").style.zIndex = 510;
      } else if (mode < 4) {
        // 直線追加モードにする
        this.drawControl._toolbars.draw._modes.polyline.handler.disable();
        this.drawControl._toolbars.draw._modes.polyline.handler.enable();
      }
      this.setEditMode(mode);
    },
    saveEditMode() {
      // 編集結果を保存する
      const type = this.editMode;
      if (type < 3) {
        // 編集/削除モード
        const mode = this.editModeText[this.editMode];
        const deletableLayers =
          this.drawControl._toolbars.edit._modes[mode].handler._deletableLayers;
        if (!deletableLayers || 0 <= deletableLayers.getLayers().length) {
          this.drawControl._toolbars.edit._modes[mode].handler.save();
        }
        this.drawControl._toolbars.edit._modes[mode].handler.disable();
        this.map.getPane("exRoute").style.zIndex = 410;
        this.setEditMode(0);
      } else if (type < 4) {
        // 直線追加モード
        this.drawControl._toolbars.draw._modes.polyline.handler.completeShape();
        this.drawControl._toolbars.draw._modes.polyline.handler.disable();
        this.setEditMode(0);
      }
    },
    backLine() {
      // 1つ前に戻す
      const length =
        this.drawControl._toolbars.draw._modes.polyline.handler._markers.length;
      this.drawControl._toolbars.draw._modes.polyline.handler.deleteLastVertex();
    },
    revertEditMode() {
      // 編集結果を元に戻してキャンセルする
      const type = this.editMode;
      if (type < 3) {
        // 編集/削除モード
        const mode = this.editModeText[this.editMode];

        const deletableLayers =
          this.drawControl._toolbars.edit._modes[mode].handler._deletableLayers;
        if (!deletableLayers || 0 <= deletableLayers.getLayers().length) {
          this.drawControl._toolbars.edit._modes[mode].handler.revertLayers();
        }
        this.drawControl._toolbars.edit._modes[mode].handler.disable();
        this.map.getPane("exRoute").style.zIndex = 410;
      } else if (type < 4) {
        // 直線追加モード
        // this.drawControl._toolbars.draw._modes.polyline.handler.enable();
        this.drawControl._toolbars.draw._modes.polyline.handler.disable();
      }
      this.setEditMode(0);
      this.saveEditObject();
    },
    saveEditObject() {
      // this.activeEditMode = 0 < this.drawLayer.getLayers().length;
    },
    onZoomMove() {
      this.saveEditObject();
    },
    _openMarkerDialog() {
      this.openMarkerDialog = true;
    },
  },
  data() {
    return {
      openMarkerDialog: false,
      openNotesDialog: false,
      editModeText: ["", "edit", "remove"],
      notes: { title: "", contents: [] },
    };
  },
  mounted() {
    this.map.on("moveend zoomend", this.onZoomMove);
  },
};
</script>

<style scoped>
</style>
